import React, { useState, useContext, useEffect } from "react";
import "./RapModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../Api/api";
import AuthContext from "../../context/AuthContext";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import { Form } from "react-bootstrap";

function NextLyricModal(props) {
  let { user, userDetails, authTokens, setupTheUser } = useContext(AuthContext);
  const [song, setSong] = useState(false);
  const [rapLoading, setRapLoading] = useState(false);
  const [anyWord, setAnyWord] = useState("");
  const [song_lines, setSongLines] = useState([]);
  const [isLines, setIsLines] = useState(false);
  const [prevSong, setPrevSong] = useState("");

  let emailVerification = async () => {
    let response = await fetch(
      "https://api.rhymes.world/auth/send-verification-email",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authTokens.access,
        },
      }
    );
    if (response.ok) {
      swal("Verification link is sent in your email. Please check your email.");
    }
  };

  const saveAccess = () => {
    if (userDetails?.data?.is_superuser == true) {
      return true;
    }
    let isAccess = false;
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.save_song_uses > 0
    ) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data?.is_email_verified == false) {
      return false;
    }
    return isAccess;
  };

  const writeAccess = () => {
    if (userDetails?.data?.is_superuser == true) {
      return true;
    }
    let isAccess = false;
    if (userDetails?.data?.write_song_uses > 0) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data?.is_email_verified == false) {
      return false;
    }
    return isAccess;
  };

  const premiumAlert = () => {
    swal("Please use premium version to write song");
    return;
  };
  const emailAlert = () => {
    swal({
      title: "Please verify your email!",
      buttons: ["Not Now", "Verify Now"],
      dangerMode: false,
    }).then(function (isConfirm) {
      if (isConfirm) {
        emailVerification();
      } else {
        return;
      }
    });
    return;
  };
  const [rapLines, setRapLines] = useState("");
  //CHATGPT RAP GENERATION--------------------------------------
  const [rapGenerated, setrapGenerated] = useState([]);
  const [songType, setSongType] = useState("");
  const showText = () => {
    document.getElementById("song_div").classList.remove("display_block");
    document.getElementById("song_div").classList.add("display_none");
    document.getElementById("song_text").classList.remove("display_none");
    document.getElementById("song_text").classList.add("display_block");
  };
  const showSongPop = () => {
    document.getElementById("song_div").classList.remove("display_none");
    document.getElementById("song_div").classList.add("display_block");
    document.getElementById("song_text").classList.remove("display_block");
    document.getElementById("song_text").classList.add("display_none");
    setRapLines(rapGenerated.join("\r\n"));
    console.log(rapGenerated, "-------");
  };

  let nextLineOfSong = async (lines) => {
    if (writeAccess() === false) {
      if (userDetails.data.is_email_verified == false) {
        emailAlert();
        return;
      }
      premiumAlert();
      return;
    }
    setRapLoading(true);
    let items = { song: lines.split("\n") };
    let response = await fetch(`${BASE_URL}/generate-next-song-line`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    let data = await response.json();
    if (response.ok) {
      setrapGenerated(data?.rap_lines);
      setSong(true);
      setRapLoading(false);
      setupTheUser(authTokens.access);
      setRapLines(data?.rap_lines.join("\r\n"));
    }
  };

  const writeAgain = () => {
    setPrevSong(rapLines);
    nextLineOfSong(rapLines);
  };

  const closeModal = () => {
    setSongLines([]);
    props.onHide();
    setSong(false);
  };

  let rapSaved = async (username, words_array, type) => {
    if (saveAccess() === false) {
      swal("Please use premium version to save your favourite song");
      return;
    }
    let rap_data = rapLines;
    let word_carray = words_array.join(",");
    let items = { user: username, song: rap_data, words: word_carray };
    let response = await fetch(`${BASE_URL}/rap`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    if (response.status === 201) {
      if (type == "SAVE_ONLY") {
        props.onHide();
        setSong(false);
        props.setClickedArray([]);
        swal("song saved successfully!");
        setupTheUser(authTokens.access);
      } else {
        swal("song saved successfully!");
        setupTheUser(authTokens.access);
        setSong(false);
      }
    }
  };

  const SongTypeHandler = (songType) => {
    setSongType(songType);
  };

  useEffect(() => {}, [song_lines]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {song === false && rapLoading === false ? (
          <div>
            <h4 className="modal_heading">Write Next Rhymes</h4>
            <div className="wordPopDiv">
              <div style={{ clear: "both" }}></div>
              <div className="songDivPop">
                <textarea
                  className="lyric_text"
                  onChange={(e) => setPrevSong(e.target.value)}
                ></textarea>
              </div>
            </div>
            <Button onClick={closeModal} className="btn btn-secondary">
              Close
            </Button>
            <button
              className="submit-button-song-pop"
              onClick={(e) => nextLineOfSong(prevSong)}
            >
              Write Next Line{" "}
              {writeAccess() === false ? (
                <>
                  <i className="fa fa-lock"></i>
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        ) : (
          <>
            {rapLoading ? (
              <div>
                <h4 className="modal_heading">Writing Your Next Line...</h4>
                <div className="wordPopDiv generated_song_div_loader">
                  <Loader />
                </div>
              </div>
            ) : (
              <div>
                <h4 className="modal_heading">Here is your song</h4>
                <div
                  className="wordPopDiv generated_song_div display_block"
                  id="song_div"
                >
                  <div className="row">
                    <div className="col-12">
                      <i
                        className="fa fa-edit edit-icon"
                        onClick={showText}
                        title="Edit Song"
                      ></i>
                    </div>
                    {rapGenerated.map((line, index) => {
                      return (
                        <>
                          <div className="col-12" onDoubleClick={showText}>
                            <p
                              key={line}
                              className={
                                rapGenerated.length - 1 == index
                                  ? "p-0 mt-0 song_last_line"
                                  : "p-0 mt-0"
                              }
                            >
                              {line}{" "}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div id="song_text" className="display_none">
                    <i
                      className="fa fa-arrow-left goback_icon"
                      onClick={showSongPop}
                    >
                      {" "}
                      back
                    </i>
                    <textarea onChange={(e) => setRapLines(e.target.value)}>
                      {rapGenerated.join("\r\n")}
                    </textarea>
                  </div>
                </div>

                <Button onClick={closeModal} className="btn btn-secondary">
                  Close
                </Button>
                {isLines ? (
                  <button
                    className="submit-button-song-pop"
                    //   onClick={() => rapRegeneration(rapGenerated, song_lines)}
                  >
                    Re-Write Song{" "}
                    {writeAccess() === false ? (
                      <>
                        <i className="fa fa-lock"></i>
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                ) : (
                  <>
                    <button
                      className="submit-button-song-pop btn-primary"
                      onClick={() => writeAgain()}
                    >
                      Write Next Line{" "}
                      {writeAccess() === false ? (
                        <>
                          <i className="fa fa-lock"></i>
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className="submit-button-song-pop btn_mr"
                      onClick={(e) => nextLineOfSong(prevSong)}
                    >
                      Write Again{" "}
                      {writeAccess() === false ? (
                        <>
                          <i className="fa fa-lock"></i>
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className="submit-button-song-pop btn_mr"
                      onClick={() => rapSaved(user.username, [], "SAVE_ONLY")}
                    >
                      Save{" "}
                      {saveAccess() === false ? (
                        <>
                          <i className="fa fa-lock"></i>
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default NextLyricModal;
