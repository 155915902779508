import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL, BASE_URL_SPANISH } from "../components/Api/api";
import swal from "sweetalert";
import { useRef } from "react";

const AuthContext = createContext({
  user: "",
  authTokens: "",
  words: "",
  wordOfDay: "",
  userSearchedCount: "",
  languageSelected: "",
  options: [],
  rapGenerated: [],
  userDetails: "",
  savedChats: [],
  baseUrl: "",
  REACT_APP_API_BASE_URL_SPANISH: "",
  SpanishWords: [],
  credits: 10, // default credits value
  timeLeft: null, // timer for credits
  IsRapSuccessFullyGenerated: "",
  pending: [],
  rejecteds: [],
  accepteds: [],
  loginUser: () => {},
  forgotPassword: () => {},
  changeLanguageGet: () => {},
  getUserCredits: () => {},
  updateToken: () => {},
  homeWordsPhrasesGet: () => {},
  logoutUser: () => {},
  changeLanguage: () => {},
  loginTheUser: () => {},
  searchedWords: () => {},
  wordOfTheDayGet: () => {},
  wordOfTheDayPost: () => {},
  userSearchCountPost: () => {},
  acceptOrRejectGet: () => {},
  userSearchCountGet: () => {},
  userAcceptedCountGet: () => {},
  userAcceptedCountPost: () => {},
  emailVerification: () => {},
  loadWords: () => {},
  rapGeneration: () => {},
  setupTheUser: () => {},
  saveChats: () => {},
  saveChatsGet: () => {},
  RhymesEditing: () => {},
  deleteRhymes: () => {},
  submittedRhymesStatus: () => {},
});

export default AuthContext;

export const AuthProvider = ({ children }) => {
  // The user and the token values will change on the first login only.----

  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("auth-tokens")
      ? JSON.parse(localStorage.getItem("auth-tokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("auth-tokens")
      ? jwt_decode(localStorage.getItem("auth-tokens"))
      : null
  );
  let [userDetails, setUserDetails] = useState(() =>
    localStorage.getItem("user-details")
      ? JSON.parse(localStorage.getItem("user-details"))
      : null
  );
  let [loading, setLoading] = useState(authTokens ? true : false);

  const REACT_APP_API_BASE_URL_SPANISH = "https://api.rhymes.world/spanish";

  const navigate = useNavigate();
  const location = useLocation();

  const d = new Date();
  var day = d.toLocaleString("en-UK", { day: "2-digit" });
  var month = d.toLocaleString("en-UK", { month: "2-digit" });
  var year = d.toLocaleString("en-UK", { year: "numeric" });

  const today_date = year + "-" + month + "-" + day;

  let emailVerification = async () => {
    let response = await fetch(
      "https://api.rhymes.world/auth/send-verification-email",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authTokens.access,
        },
      }
    );
    if (response.ok) {
      swal("Verification link is sent in your email. Please check your email.");
    }
  };

  const writeAccess = () => {
    if (userDetails?.data?.is_superuser === true) {
      return true;
    }
    let isAccess = false;
    if (userDetails?.data?.write_song_uses > 0) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data.is_email_verified === false) {
      return false;
    }
    return isAccess;
  };

  const [languageSelected, setLanguageSelected] = useState("");
  const [homeWords, setHomeWords] = useState("");
  const [homePhrases, setHomePhrases] = useState("");

  //GETTING THE SELECTED LANGUAGE OF THE USER-------------------------------------------------------------------
  let changeLanguageGet = async () => {
    let response = await fetch(
      "https://api.rhymes.world/auth/language-change",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    if (response.ok) {
      for (var i in data) {
        if (data[i].user === user?.username) {
          let reqValue = await data[i].selected_language;
          setLanguageSelected(reqValue);
        } else if (!user) {
          setLanguageSelected("English");
        }
      }
    }
  };

  useEffect(() => changeLanguageGet(), []);

  useEffect(() => {
    loadWords(languageSelected);
    homeWordsPhrasesGet();
  }, [languageSelected]);

  //GETTING THE SELECTED LANGUAGE OF THE USER-------------------------------------------------------------------

  let homeWordsPhrasesGet = async () => {
    let reqCond = "";
    if (String(languageSelected) === "English") {
      reqCond = BASE_URL;
    } else if (String(languageSelected) === "Spanish") {
      reqCond = REACT_APP_API_BASE_URL_SPANISH;
    }
    if (reqCond) {
      await fetch(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/home-words-phrases/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let response = await fetch(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/home-words-phrases`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.ok) {
        setHomeWords(data.words);
        setHomePhrases(data.phrases);
      }
    }
  };

  useEffect(() => homeWordsPhrasesGet(), []);
  // useEffect(() => homeWordsPhrasesGet(), [languageSelected]);

  //GETTING ALL THE ACCEPTED WORDS AND PHRASES WITH ROOT WORDS-----------------------------------------
  const [options, setOptions] = useState([]);

  const loadWords = async (language) => {
    let reqCond = "";
    if (language === "English") {
      reqCond = BASE_URL;
    } else if (language === "Spanish") {
      reqCond = REACT_APP_API_BASE_URL_SPANISH;
    }
    if (reqCond) {
      const response = await axios.get(`${reqCond}/get-all-words`);
      setOptions(response.data);
    }
  };

  useEffect(() => {
    loadWords();
  }, []);

  // SEARCHED WORDS -------------------------------------------
  let searchedWords = async (item) => {
    let items = { word: item.word, date: today_date, user: item.user };
    let response = await fetch(
      `${
        String(languageSelected) === "English"
          ? BASE_URL
          : REACT_APP_API_BASE_URL_SPANISH
      }/add-searched-rhymes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(items),
      }
    );
  };

  //COUNT OF ACCEPTED WORDS BY USERS --------------------------
  const [userAcceptedCount, setUserAcceptedCount] = useState();
  let userAcceptedCountPost = async (item) => {
    let input = { user: item };
    let response = await fetch(`${BASE_URL}/user-words-accepted-count`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let data = await response.json();
    if (response.ok) {
      console.log("count updated!", data);
    } else {
      console.log("Something went wrong!");
    }
  };

  let userAcceptedCountGet = async () => {
    let response = await fetch(`${BASE_URL}/user-words-accepted-count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (response.ok) {
      setUserAcceptedCount(data);
    }
  };

  //COUNT  OF THE REJECTED WORDS PER USER ---------------------
  let userRejectedCountGet = async () => {
    let response = await fetch(`${BASE_URL}/user-words-rejected-count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
  };
  // COUNT OF SEARCHED WORDS BY USER --------------------------
  const [userSearchedCount, setUserSearchedCount] = useState();
  let userSearchCountPost = async (item) => {
    let input = { user: item };
    let response = await fetch(`${BASE_URL}/user-searched-count`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let data = await response.json();
    if (response.ok) {
    } else {
      console.log("Something went wrong!");
    }
  };

  let userSearchCountGet = async () => {
    let response = await fetch(`${BASE_URL}/user-searched-count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (response.ok) {
      setUserSearchedCount(data);
    }
  };

  // SET UP USER DETAILS

  let setupTheUser = async (user_token) => {
    let response = await fetch("https://api.rhymes.world/auth/user-details", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user_token,
      },
    });
    let data = await response.json();
    if (response.ok) {
      setUserDetails(data);
      localStorage.setItem("user-details", JSON.stringify(data));
    } else {
      console.log("something wrong");
    }
  };

  // GET THE WRITING AND SAVING CREDITS DETAILS-------------------------------

  let getUserCredits = async (user_token) => {
    let response = await fetch(`${BASE_URL}/api/get-user-credits`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user_token,
      },
    });
    let data = await response.json();
    if (response.ok) {
      setUserDetails(data);
      localStorage.setItem("user-details", JSON.stringify(data));
    } else {
      console.log("something wrong");
    }
  };

  // LOGIN FUNCTION -------------------------------------------
  const [wordsSubmittedOnLogin, setwordsSubmittedOnLogin] = useState(false);
  let loginUser = async (items) => {
    let response = await fetch("https://api.rhymes.world/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(items.loginCreds),
    });
    let data = await response.json();
    if (response.ok) {
      setAuthTokens(data);
      let loginDecodedUser = jwt_decode(data.access);
      setUser(loginDecodedUser);
      localStorage.setItem("auth-tokens", JSON.stringify(data));
      if (items.arr) {
        wordInput({ words: items.arr, user: loginDecodedUser });
        navigate(-2, { state: location.state, showConfetti: true });
      } else {
        navigate("/", { state: { showConfetti: true } });
      }
      setupTheUser(data.access);
    } else {
      alert("your credentials are wrong");
    }
  };

  //LOGIN AFTER SIGNUP FUNCTION-------------------------------------------

  let loginTheUser = async (item) => {
    let response = await fetch("https://api.rhymes.world/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item.loginItem),
    });
    let data = await response.json();
    if (response.ok) {
      setAuthTokens(data);
      let decodedUser = jwt_decode(data.access);
      setUser(decodedUser);
      localStorage.setItem("auth-tokens", JSON.stringify(data));
      if (decodedUser.username && item.wordSubmitItem) {
        wordInput({ words: item.wordSubmitItem, user: decodedUser });
      } else {
        navigate("/", { state: { showConfetti: true } });
      }
      setupTheUser(data.access);
    } else {
      alert("your credentials are wrong");
    }
  };

  // WORD INPUT FUNCTION-----------------------------------------

  let wordInput = async (items) => {
    if (items.user) {
      let item = {
        user: items.user.username,
        word:
          items.words.inputArr[0].toUpperCase() +
          items.words.inputArr.substr(1),
        Word_of_the_day: items.words.Word_of_the_day,
      };
      let response = await fetch(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/add-after-search`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(item),
        }
      );
      if (response.ok) {
      } else {
        console.log("something went wrong");
      }
    } else {
      let item = {
        user: items.user.username,
        word: items.inputArr[0].toUpperCase() + items.inputArr.substr(1),
        Word_of_the_day: items.words.Word_of_the_day,
      };
      let response = await fetch(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/add-after-search`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(item),
        }
      );
      if (response.ok) {
        navigate(-1, { state: location.state });
      } else {
        console.log("something went wrong");
      }
    }
  };

  //ACCEPT OR REJECT FUNCTION----------------------------------

  const [words, setWords] = useState([]);
  const [SpanishWords, setSpanishWords] = useState([]);

  let acceptOrRejectGet = async (e) => {
    axios
      .get(`${BASE_URL}/accept-or-reject`)
      .then((response) => setWords(response.data));
  };

  let acceptOrRejectSpanishGet = async (e) => {
    axios
      .get(`${REACT_APP_API_BASE_URL_SPANISH}/accept-or-reject`)
      .then((response) => setSpanishWords(response.data));
  };

  useEffect(() => {
    acceptOrRejectGet();
    acceptOrRejectSpanishGet();
  }, []);

  // let acceptOrRejectPost = async (e) => {
  //   e.preventDefault();
  //   console.log("Accept or Reject Post -----------------");
  //   let item = { word: "word", is_accepted: true };
  //   let response = await fetch("http://127.0.0.1:8000/api/accept-or-reject", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(item),
  //   });
  //   console.log(response);
  //   if (response.ok) {
  //     console.log("The words has been submitted");
  //   } else {
  //     console.log("something went wrong");
  //   }
  // };

  //WORD OF THE DAY FUNCTION------------------------------------

  const [wordOfDay, setWordOfDay] = useState([]);
  const notify = () => toast("Rhyme of the day has been set!");
  const notify2 = () => toast("Something went wrong!");

  let wordOfTheDayGet = async (e) => {
    axios
      .get(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/word-of-the-day`
      )
      .then((response) => setWordOfDay(response.data));
  };

  useEffect(() => {
    wordOfTheDayGet();
  }, []);

  let wordOfTheDayPost = async (e) => {
    e.preventDefault();
    let item = { Word_of_the_day: e.target.wordOfTheDay.value };
    let response = await fetch(
      `${
        String(languageSelected) === "English"
          ? BASE_URL
          : REACT_APP_API_BASE_URL_SPANISH
      }/word-of-the-day`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      }
    );
    if (response.ok) {
      notify();
    } else {
      notify2();
    }
    e.target.wordOfTheDay.value = "";
  };

  //CHATGPT RAP GENERATION--------------------------------------
  const [rapGenerated, setrapGenerated] = useState([]);
  const [rapLines, setRapLines] = useState([]);
  const [isRapSuccessFullyGenerated, setIsRapSuccessFullyGenerated] =
    useState(false);
  const premiumAlert = () => {
    swal("Please use premium version to write song");
    return;
  };

  const emailAlert = () => {
    swal({
      title: "Please verify your email!",
      buttons: ["Not Now", "Verify Now"],
      dangerMode: false,
    }).then(function (isConfirm) {
      if (isConfirm) {
        emailVerification();
      } else {
        return;
      }
    });
    return;
  };

  let rapGeneration = async (list, songType, anyWord) => {
    setrapGenerated([]);
    if (list.length > 0) {
      console.log("Writing your song");
      let item = {
        list: list,
        songType: songType,
        language_selected: languageSelected,
        // legend: legend,
        subscription_status:
          userDetails?.data?.is_subscribe === true ? true : false,
      };
      console.log("list of words:", list);
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      if (user) {
        headers["Authorization"] = "Bearer " + authTokens.access;
      }
      let response = await fetch(`${BASE_URL}/rap-generation`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item),
      });
      let resData = await response.json();
      console.log("Response", resData);
      if (response.status === 200) {
        if (
          credits > 0 ||
          userDetails?.data.is_superuser ||
          userDetails?.data.is_subscribe
        ) {
          if (
            userDetails?.data.is_superuser === false ||
            userDetails?.data.is_subscribe === false
          ) {
            setCredits(credits - 3);
          }
          setrapGenerated(resData?.rap_lines);
          list = list.filter((word) => word !== anyWord);
          // setupTheUser(authTokens.access);
          setRapLines(resData?.rap_lines.join("\r\n"));
          setIsRapSuccessFullyGenerated(true);
          console.log(isRapSuccessFullyGenerated);
        } else if (
          userDetails?.data.is_subscribe === false &&
          userDetails?.data.is_superuser === false
        ) {
          navigate("/plans");
        }
      } else {
        alert("Something went wrong");
      }
    } else {
      console.log("no words found");
    }
  };

  //CHATS SAVING API ----------------------------------------------------
  const [savedChats, setSavedChats] = useState([]);

  let saveChats = async (item) => {
    let response = await fetch(`${BASE_URL}/save-chats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let data = await response.json();
    if (response.ok) {
    } else {
      alert("Something went wrong");
    }
  };

  let saveChatsGet = async (item) => {
    let response = await fetch(`${BASE_URL}/get-save-chats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let data = await response.json();
    if (response.ok) {
      setSavedChats(data);
    }
  };

  //PASSWORD RESET---------------------------------------------

  let forgotPassword = async (item) => {
    let response = await fetch("https://api.rhymes.world/auth/password_reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let data = await response.json();
    if (response.ok) {
      alert("An email has been sent to your Gmail.");
    } else {
      alert("Please try again later");
    }
  };

  //LANGUAGE CHANGE---------------------------------------------
  let changeLanguage = async (item) => {
    let items = { username: user.username, selected_language: item };
    let response = await fetch(
      "https://api.rhymes.world/auth/language-change",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(items),
      }
    );
    let data = await response.json();
    if (response.ok) {
      setBaseUrl(item === "English" ? BASE_URL : BASE_URL_SPANISH);
    } else {
      alert("Something went wrong");
    }
  };

  let [baseUrl, setBaseUrl] = useState(
    languageSelected === "English" ? BASE_URL : BASE_URL_SPANISH
  );

  //RHYMES EDITING API------------------------------------------
  let RhymesEditing = async (item) => {
    // let response = await fetch("http://127.0.0.1:8000/update-rhymes/", {
    let response = await fetch("https://api.rhymes.world/update-rhymes/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let data = await response.json();
    if (response.ok) {
      console.log(data);
      alert("rhymes Updated");
    } else {
      alert("Something went wrong");
    }
  };

  //RHYMES DELETING API------------------------------------------
  let deleteRhymes = async (item) => {
    console.log(item);
    let response = await fetch("https://api.rhymes.world/update-rhymes/", {
      // let response = await fetch("https://api.rhymes.world/update-rhymes/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    if (response.ok) {
      if (response.status === 204) {
        console.log("Rhyme deleted successfully");
        alert("Rhyme deleted successfully");
      } else {
        // Only attempt to parse JSON if the response is not 204
        let data = await response.json();
        console.log(data);
        // Handle other success scenarios that might return content
      }
    } else {
      let errorText = "Something went wrong";
      if (response.status === 404) {
        errorText = "Rhyme not found";
      }
      console.error(errorText);
      alert(errorText);
    }
  };

  //STATUS OF THE RHYMES SUBMITTED BY THE USER----------------------------------------------------------
  const [accepteds, setAccepteds] = useState([]);
  const [rejecteds, setRejecteds] = useState([]);
  const [pending, setPending] = useState([]);

  let submittedRhymesStatus = async (item) => {
    let response = await fetch(
      "https://api.rhymes.world/submitted-words-status/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      }
    );
    let data = await response.json();
    if (response.ok) {
      setAccepteds(data.Accepted);
      setRejecteds(data.Rejected);
      setPending(data.Pending);
    } else {
      alert("Something went wrong");
    }
  };

  useEffect(() => {
    console.log(pending, rejecteds, accepteds);
  }, [pending, rejecteds, accepteds]);

  //CREDITS SYSTEM----------------------------------------------
  const [credits, setCredits] = useState(() => {
    const savedCredits = localStorage.getItem("credits");
    const lastUpdated = localStorage.getItem("lastUpdated");
    if (savedCredits && lastUpdated) {
      const timePassed = new Date().getTime() - new Date(lastUpdated).getTime();
      if (timePassed < 24 * 60 * 60 * 1000) {
        return parseInt(savedCredits, 10);
      }
    }
    return 10;
  });

  useEffect(() => {
    localStorage.setItem("credits", credits);
    localStorage.setItem("lastUpdated", new Date().toISOString());
  }, [credits]);

  const [timeLeft, setTimeLeft] = useState(() => {
    const savedEndTime = localStorage.getItem("endTime");
    if (savedEndTime) {
      const currentTime = new Date().getTime();
      const remainingTime =
        new Date(parseInt(savedEndTime, 10)).getTime() - currentTime;
      return remainingTime > 0 ? remainingTime : null;
    }
    return null;
  });

  // Start Timer Function
  const startTimer = () => {
    if (timeLeft === null) {
      // Check if the timer is not already running
      const currentTime = new Date().getTime();
      const endTime = currentTime + 24 * 60 * 60 * 1000; // 24 hours
      localStorage.setItem("endTime", endTime.toString()); // Save endTime in local storage
      setTimeLeft(24 * 60 * 60 * 1000);
    }
  };

  useEffect(() => {
    if (credits <= 0) {
      startTimer();
    }
  }, [credits]);

  // Timer Countdown Logic
  useEffect(() => {
    let interval;

    if (timeLeft > 0) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const savedEndTime = parseInt(localStorage.getItem("endTime"), 10);
        const timeRemaining = savedEndTime - now;
        if (timeRemaining <= 0) {
          clearInterval(interval);
          setCredits(10); // Reset credits
          setTimeLeft(null);
          localStorage.removeItem("endTime"); // Remove endTime from local storage
        } else {
          setTimeLeft(timeRemaining);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  //LOGOUT FUNCTION---------------------------------------------

  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    setUserDetails(null);
    localStorage.removeItem("auth-tokens");
    localStorage.removeItem("user-details");
    navigate("/");
  };

  //TOKEN UPDATION-----------------------------------------------

  let updateToken = async () => {
    let response = await fetch("https://api.rhymes.world/auth/login-refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: authTokens.refresh }),
    });
    let data = await response.json();

    if (response.ok) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("auth-tokens", JSON.stringify(data));
    } else {
      logoutUser();
    }
    if (loading) {
      setLoading(false);
    }
  };

  const [isWriteSongBtnClicked, setIsWriteSongBtnClicked] = useState(false);

  // for music player
  const [audioRef, setAudioRef] = useState(useRef());
  let contextData = {
    user: user,
    authTokens: authTokens,
    words: words,
    wordOfDay: wordOfDay,
    wordsSubmittedOnLogin: wordsSubmittedOnLogin,
    userSearchedCount: userSearchedCount,
    options: options,
    userDetails: userDetails,
    rapLines: rapLines,
    rapGenerated: rapGenerated,
    credits: credits, // Add credits to the context data
    timeLeft: timeLeft, // Add timeLeft to the context data
    accepteds: accepteds,
    rejecteds: rejecteds,
    pending: pending,
    setCredits: setCredits, // Function to set credits
    setrapGenerated: setrapGenerated,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loginTheUser: loginTheUser,
    wordOfTheDayGet: wordOfTheDayGet,
    wordOfTheDayPost: wordOfTheDayPost,
    searchedWords: searchedWords,
    acceptOrRejectGet: acceptOrRejectGet,
    userSearchCountPost: userSearchCountPost,
    userSearchCountGet: userSearchCountGet,
    userAcceptedCountGet: userAcceptedCountGet,
    userAcceptedCountPost: userAcceptedCountPost,
    loadWords: loadWords,
    rapGeneration: rapGeneration,
    setupTheUser: setupTheUser,
    emailVerification: emailVerification,
    updateToken: updateToken,
    forgotPassword: forgotPassword,
    isWriteSongBtnClicked: isWriteSongBtnClicked,
    setIsWriteSongBtnClicked: setIsWriteSongBtnClicked,
    isRapSuccessFullyGenerated: isRapSuccessFullyGenerated,
    setIsRapSuccessFullyGenerated: setIsRapSuccessFullyGenerated,
    audioRef: audioRef,
    setAudioRef: setAudioRef,
    getUserCredits: getUserCredits,
    saveChats: saveChats,
    saveChatsGet: saveChatsGet,
    savedChats: savedChats,
    changeLanguage: changeLanguage,
    setLanguageSelected: setLanguageSelected,
    languageSelected: languageSelected,
    changeLanguageGet: changeLanguageGet,
    homeWordsPhrasesGet: homeWordsPhrasesGet,
    homePhrases: homePhrases,
    homeWords: homeWords,
    baseUrl: baseUrl,
    SpanishWords: SpanishWords,
    REACT_APP_API_BASE_URL_SPANISH: REACT_APP_API_BASE_URL_SPANISH,
    RhymesEditing: RhymesEditing,
    deleteRhymes: deleteRhymes,
    submittedRhymesStatus: submittedRhymesStatus,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }
    let fourMinutes = 1000 * 60 * 4;
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? "" : children}
    </AuthContext.Provider>
  );
};
