import React, { useState, useEffect } from "react";
import "./Translator.css";
import { BASE_URL } from "../Api/api";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import swap from "../../assets/images/swap-icon.jpg";
import Loader from "../Loader/Loader";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Translator = () => {
  const { authTokens } = useContext(AuthContext);
  const [lyrics, setLyrics] = useState("");
  const [translatedResult, setTranslatedResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Song");

  const handleTypeChange = (e) => {
    setType(e.target.value);
    console.log(type);
  };

  useEffect(() => {console.log(type)}, [type]);

  const translateLyrics = async () => {
    setLoading(true);
    setTranslatedResult("");
    if (lyrics === "") {
      alert("Please enter some text");
    } else {
      let response = await fetch(`${BASE_URL}/lyrics-convertion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + authTokens.access,
        },
        body: JSON.stringify({ lyrics: lyrics, style: type }),
      });
      console.log("response calculation");
      let data = await response.json();
      if (response.ok) {
        console.log(data.final_lines);
        setTranslatedResult(data.final_lines);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  return (
    <div className="translator-main">
      <div className="translator-card">
        <div className="translator-content">
          <div className="left">
            <textarea
              type="textarea"
              value={lyrics}
              className="input-box-translator"
              onChange={(e) => setLyrics(e.target.value)}
              placeholder="Your Lyrics Here..."
            />
          </div>
          <div className="translator-icon">
            <img src={swap} alt="swap" />
          </div>
          <div className="right">
            <div className="result-box-translator">
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  {translatedResult ? (
                    <textarea
                      value={translatedResult}
                      onChange={(e) => e.target.value}
                    />
                  ) : (
                    "Translated Results Here..."
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Type"
              onChange={handleTypeChange}
            >
              <MenuItem value={"Song"}>Song</MenuItem>
              <MenuItem value={"Jingle"}>Jingle</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="translate-button">
          <button onClick={translateLyrics}>Translate</button>
        </div>
      </div>
    </div>
  );
};

export default Translator;
