import React, { useState, useEffect, useRef, useContext } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { pauseCircle } from "react-icons-kit/fa/pauseCircle";
import { playCircle } from "react-icons-kit/fa/playCircle";
import { iosSkipbackward } from "react-icons-kit/ionicons/iosSkipbackward";
import { iosSkipforward } from "react-icons-kit/ionicons/iosSkipforward";
import { list } from "react-icons-kit/ikons/list";
import { Icon } from "react-icons-kit";
import Slider from "@mui/material/Slider";
import "./MusicPlayer.css";
import song1 from "../../assets/audio/country_songs/_5-9_ FREE Acoustic Guitar Type Beat 2021 Rising Up Sad Hip Hop Emo Rap Instrumental -ZqttIDUBYlg-192k-1690948869.mp3";
import song2 from "../../assets/audio/country_songs/_Adult Rock_ FREE Acoustic Guitar Type Beat Sad Times Trap Rock Country Rap Instrumental 2021 -p0K-uOP8G5o-192k-1690948840.mp3";
import song3 from "../../assets/audio/country_songs/_Bed Time_ FREE Acoustic Guitar Type Beat Unwritten Singer Songwriter Instrumental -12UqPbWgBgU-192k-1690949515.mp3";
import song4 from "../../assets/audio/country_songs/_Country Girl Shake It for Me_ Instrumental Version -a-cCxO_oqes-192k-1690949958.mp3";
import song5 from "../../assets/audio/country_songs/_Country Heat_ FREE ACOUSTIC Guitar Type Beat Heat Sad Rap x Country Instrumental 2020 -znxDNKP_-pE-192k-1690949024.mp3";
import song6 from "../../assets/audio/country_songs/_Cowboys Get Sad Too_ FREE Acoustic Guitar Type Beat Made It Sad Trap Emo Rap Instrumental 2022 -xj-oykEnHNE-192k-1690948931.mp3";
import song7 from "../../assets/audio/country_songs/_Farm Motivation_ FREE Acoustic Guitar Type Beat 2023 Inspiring Emo Rap x Trap Country Instrumental -os2oxMoUH4o-192k-1690948770.mp3";
import song8 from "../../assets/audio/country_songs/_Pedal Down_ Jason Aldean Type Beat Urban Country Instrumental Pedal Down -E8cpXTNLC5I-192k-1690949663.mp3";
import song9 from "../../assets/audio/country_songs/_Send It_ FREE FOR PROFIT Morgan Wallen Type Beat 2023 Taillights Country Type Beat-l9BBd56tNzk-192k-1690950199.mp3";
import song10 from "../../assets/audio/country_songs/_Top Floor_ FREE Jason Aldean Hard Country Type Beat Barb Wire -29OwcvT42Ys-192k-1690949813.mp3";
import song11 from "../../assets/audio/country_songs/_Wind Blowin'_ songwriters_backing_track__acoustic_guitar_song_38_-w0ZEOnsEAd4-192k-1690948760.mp3";
import song12 from "../../assets/audio/country_songs/_Yesterday Was Then_ FREE Sad Acoustic Guitar x Indie Type Beat Yesterday -2Y6vNTTZ1Es-192k-1690948982.mp3";
import song13 from "../../assets/audio/country_songs/_You Make It Easy_ Jason Aldean You Make It Easy Instrumental -4lfzwCXwrUs-192k-1690949886.mp3";
import rap1 from "../../assets/audio/rap_songs/_Lil Wayne - La La La (Instrumental).mp3";
import rap2 from "../../assets/audio/rap_songs/(FREE) J.I.D X EARTHGANG X J COLE TYPE BEAT - DIRECT DEPOSIT .mp3";
import rap3 from "../../assets/audio/rap_songs/[FREE] Jack Harlow x DaBaby Type Beat - No No No .mp3";
import rap4 from "../../assets/audio/rap_songs/Dababy - Suge ( Yea Yea) Instrumental KFPKen (1).mp3";
import rap5 from "../../assets/audio/rap_songs/Full Clip-Gangstarr(Instrumental).mp3";
import rap6 from "../../assets/audio/rap_songs/Jack Harlow - It Can t Be (Instrumental).mp3";
import rap7 from "../../assets/audio/rap_songs/Jack Harlow - They Don t Love It (Instrumental).mp3";
import rap8 from "../../assets/audio/rap_songs/Jack Harlow - WHATS POPPIN [Instrumental] BEST VERSION reprod. PUDA.mp3";
import rap9 from "../../assets/audio/rap_songs/Mac Miller - Diablo Instrumental.mp3";
import rap10 from "../../assets/audio/rap_songs/Meek Mill - Early Mornings (INSTRUMENTAL).mp3";
import rap11 from "../../assets/audio/rap_songs/Migos Ft. Youngboy Never Broke Again - Need It [Instrumental] (Prod. By Buddah Bless).mp3";
import rap12 from "../../assets/audio/rap_songs/Nipsey Hussle - Respect Ya Passion [Instrumental] (Prod. by Bink).mp3";
import rap13 from "../../assets/audio/rap_songs/Offset Ft. Cardi B - Clout [Instrumental] (Prod. By Southside CuBeatz) DL via Hipstrumentals.mp3";
import rap14 from "../../assets/audio/rap_songs/Shook Ones, Pt II (Instrumental).mp3";
import rap15 from "../../assets/audio/rap_songs/Young M.A - BIG (Instrumental).mp3";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const isMobile = window.innerWidth <= 425; //425 is the mobile max-width

const MusicPlayer = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const { audioRef, credits, timeLeft, userDetails } = useContext(AuthContext);
  const [playing, setPlaying] = useState(false);
  const dropdownRef = useRef(null);
  const [genres, setGenres] = useState("rap_songs");
  const [volume, setVolume] = useState(0.5); // Default volume: 0.5 (50%)
  const audioContext = useRef(null);
  const gainNode = useRef(null);
  const sourceRef = useRef(null);

  const country_songs = [
    song1,
    song2,
    song3,
    song4,
    song5,
    song6,
    song7,
    song8,
    song9,
    song10,
    song11,
    song12,
    song13,
  ];

  const rap_songs = [
    rap1,
    rap2,
    rap3,
    rap4,
    rap5,
    rap6,
    rap7,
    rap8,
    rap9,
    rap10,
    rap11,
    rap12,
    rap13,
    rap14,
    rap15,
  ];

  const navigate = useNavigate();

  const displayTimeLeft = () => {
    if (!timeLeft) return "No active timer";
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  const handleNextSong = () => {
    let songType;
    if (genres === "rap_songs") {
      songType = rap_songs;
    } else {
      songType = country_songs;
    }
    if (playing) {
      if (currentSongIndex < songType.length - 1) {
        setCurrentSongIndex(currentSongIndex + 1);
      } else {
        setCurrentSongIndex(0);
      }
    } else if (playing !== true) {
      if (currentSongIndex < songType.length - 1) {
        setCurrentSongIndex(currentSongIndex + 1);
      } else {
        setCurrentSongIndex(0);
      }
      setPlaying(true);
    }
  };

  const handlePrevSong = () => {
    let songType;
    if (genres === "rap_songs") {
      songType = rap_songs;
    } else {
      songType = country_songs;
    }
    if (audioRef.current.currentTime > 2) {
      audioRef.current.currentTime = 0;
    } else {
      setCurrentSongIndex(
        (currentSongIndex - 1 + songType.length) % songType.length
      );
    }
  };

  const startAudioContext = () => {
    if (audioContext.current === null) {
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      gainNode.current = audioContext.current.createGain();

      // Ensure audioRef.current is not null
      if (audioRef.current) {
        sourceRef.current = audioContext.current.createMediaElementSource(
          audioRef.current
        );
        sourceRef.current.connect(gainNode.current);
        gainNode.current.connect(audioContext.current.destination);
      }
    }

    if (audioContext.current.state === "suspended") {
      audioContext.current.resume();
    }
  };

  const startPlaying = () => {
    startAudioContext(); // Start or resume the audio context on user interaction
    if (!playing) {
      audioRef.current.play();
      setPlaying(true);
    } else {
      audioRef.current.pause();
      setPlaying(false);
    }
  };

  const genresHandler = (genre) => {
    setGenres(genre);
    setShowDropdown(!showDropdown);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    gainNode.current.gain.value = newVolume;
  };

  useEffect(() => {
    if (!sourceRef.current) {
      sourceRef.current = audioContext?.current?.createMediaElementSource(
        audioRef?.current
      );
      sourceRef?.current?.connect(gainNode?.current);
      gainNode?.current?.connect(audioContext?.current.destination);
    }

    const player = audioRef.current;
    player.src =
      genres === "rap_songs"
        ? rap_songs[currentSongIndex]
        : country_songs[currentSongIndex];
    player.load();

    if (playing) {
      player.play();
    }

    player.addEventListener("ended", handleNextSong);
    return () => {
      player.removeEventListener("ended", handleNextSong);
    };
  }, [currentSongIndex, genres, playing]);

  useEffect(() => {
    if (rap_songs.length > 0) {
      const getRandomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;
      setCurrentSongIndex(getRandomInt(0, rap_songs.length));
    }
  }, [rap_songs.length]);

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if user clicked inside the dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        showDropdown === true
      ) {
        // setShowDropdown(false);
      }
    }

    // Attach the click outside listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {}, [showDropdown]);

  return (
    <div className="musicPlayer-main">
      <div className="musicPlayer-App">
        {isMobile ? (
          ""
        ) : (
          <>
            {userDetails?.data?.is_subscribe ||
            userDetails?.data?.is_superuser ? (
              ""
            ) : (
              <>
                <div
                  className="search_credits_timer"
                  onClick={() => navigate("/plans")}
                >
                  <button className="credits_button">Credits: {credits}</button>
                  {timeLeft && <p>Resets in {displayTimeLeft()}</p>}
                </div>
              </>
            )}
          </>
        )}

        <div className="player">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="genres-dropdown"
          >
            <Icon
              icon={list}
              size={40}
              style={{ color: "rgba(71, 212, 255, 1)" }}
            />
          </button>
          {showDropdown && (
            <ul className="music-dropdown" ref={dropdownRef}>
              <li onClick={() => genresHandler("rap_songs")}>
                Rap Beats &nbsp; <>{genres === "rap_songs" ? <>✔️</> : ""}</>
              </li>
              <li onClick={() => genresHandler("country_songs")}>
                Country Beats &nbsp;
                <>{genres === "country_songs" ? <>✔️</> : ""}</>
              </li>
            </ul>
          )}
          <div
            className="player-buttons"
            style={{
              marginRight: "-80px",
              ...(isMobile && { marginRight: "0px" }),
            }}
          >
            <button onClick={handlePrevSong} className="backward-button">
              <Icon
                icon={iosSkipbackward}
                size={26}
                style={{ color: "rgba(71, 212, 255, 1)" }}
              />
            </button>
            {playing ? (
              <button onClick={startPlaying} className="play-or-pause">
                <Icon
                  icon={pauseCircle}
                  size={40}
                  color="red"
                  style={{ color: "rgba(71, 212, 255, 1)" }}
                />
              </button>
            ) : (
              <button onClick={startPlaying} className="play-or-pause">
                <Icon
                  icon={playCircle}
                  size={40}
                  style={{ color: "rgba(71, 212, 255, 1)" }}
                />
              </button>
            )}
            <button onClick={handleNextSong} className="forward-button">
              <Icon
                icon={iosSkipforward}
                size={26}
                style={{ color: "rgba(71, 212, 255, 1)" }}
              />
            </button>
          </div>
          <audio ref={audioRef} id="audio_player">
            {genres === "rap_songs" ? (
              <>
                <source
                  src={country_songs[currentSongIndex]}
                  type="audio/mp3"
                ></source>
              </>
            ) : (
              <source
                src={rap_songs[currentSongIndex]}
                type="audio/mp3"
              ></source>
            )}
          </audio>

          {isMobile ? (
            <div
              className="search_credits_timer_musicBar"
              onClick={() => navigate("/plans")}
            >
              {timeLeft ? (
                <button className="credits_timer_button">
                  Resets {displayTimeLeft()}
                </button>
              ) : (
                <button className="credits_button">Credits: {credits}</button>
              )}
            </div>
          ) : (
            <div className="genre-playing">
              <VolumeUpIcon sx={{ color: "rgba(71, 212, 255)" }} />
              <Slider
                value={volume}
                step={0.01}
                min={0}
                max={1}
                onChange={handleVolumeChange}
                aria-label="Volume"
                className="volume-slider"
                sx={{
                  width: "100px",
                  color: "rgba(71, 212, 255)",
                  marginRight: "20px",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
