import React from "react";
import "./WhatRhymesWithOrange.css";
import wrwo from "../../assets/images/wrwo-bg.jpg";
import wrwoCover from "../../assets/images/wrwo-cover.jpg";
import { useNavigate } from "react-router-dom";

const WhatRhymesWithOrange = () => {
  return (
    <div className="wrwo_main">
      <img src={wrwo} alt="wrwo" className="wrwo_bg" />
      <div className="wrwo_content">
        <div>
          <img src={wrwoCover} alt="wrwo-cover" className="wrwo_cover" />
        </div>
        <div>
          <a
            href="https://www.amazon.com/What-Rhymes-Orange-Mr-B/dp/B0D39F7915/ref=sr_1_1?crid=1P9TRRIJYH6YZ&dib=eyJ2IjoiMSJ9.4zcxr91SyLgkQYrhIHUU9KLteB6vrkdNttcNE2-fGvrJj6gzNOxdJhkFXwb5RetArOKp07j2WN8vD_UZlaUwPcJ0BMozd8SaKFgH6_uCAed65kkIUKBs237oEMHQ5yMhOVjYMv5VOh3NSBIYEKU"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="wrwo_button">Buy Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhatRhymesWithOrange;
