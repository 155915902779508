import React, { useContext, useEffect } from "react";
import "./Navbar.css";
import AuthContext from "../../context/AuthContext";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { useState } from "react";
import { FormControl, InputAdornment, MenuItem, Select } from "@mui/material";

const isMobile = window.innerWidth <= 425; //425 is the mobile max-width

const NavBar = () => {
  let {
    user,
    logoutUser,
    userDetails,
    languageSelected,
    setLanguageSelected,
    changeLanguage,
  } = useContext(AuthContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const navbarSuperUser = [
    "Home",
    "Songs",
    "Accept/Reject",
    "Chat",
    "Pricing",
    "Settings",
    "About",
    "Logout",
  ];
  const navbarNormalUsers = ["Home", "Songs", "Pricing", "Settings", "Logout"];
  const navbarNonLoggedIn = ["Join Now"];

  const handleLanguageChange = (event) => {
    setLanguageSelected(event.target.value);
    changeLanguage(event.target.value);
  };

  useEffect(() => {}, [languageSelected]);

  const navigateToBlog = (e) => {
    e.preventDefault();
    window.location.href = "http://blog.rhymes.world:81";
  };

  return (
    <header>
      {user ? (
        <>
          {userDetails?.data?.is_superuser === true ? (
            <>
              {["xl"].map((expand) => (
                <Navbar key={expand} expand={expand} className="Navbar">
                  <Container fluid>
                    <Navbar.Brand to="/">
                      <Link to="/">
                        <img
                          alt=""
                          src={Logo}
                          width="30"
                          height="30"
                          className="d-inline-block align-top nav-img"
                        />
                        <span id="nav-title">
                          Rhymes.<strong>World</strong>
                        </span>
                      </Link>
                      <FormControl sx={{ width: "8rem", height: "3rem" }}>
                        {isMobile ? (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },

                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-9rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "09px 26px",
                                    paddingLeft: "10px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        ) : (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              open={hovered}
                              onOpen={() => setHovered(true)} // Controlled open state
                              onClose={() => setHovered(false)}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid ",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-8rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "13px 10px",
                                    paddingLeft: "0px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        )}
                      </FormControl>
                    </Navbar.Brand>
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                      onClick={handleToggleOffcanvas}
                    />
                    <Navbar.Offcanvas
                      style={{ width: "50%" }}
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                      show={showOffcanvas}
                      onHide={handleCloseOffcanvas}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                          id={`offcanvasNavbarLabel-expand-${expand}`}
                        >
                          Mr B's Rhymes.World
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                          {/* {navbarSuperUser.map((curElem) => (
                            <>
                              <Link to={curElem === "Home"? "/":""} id={curElem}>
                                {curElem}
                              </Link>
                            </>
                          ))} */}
                          <Link to="/" id="Home" onClick={handleCloseOffcanvas}>
                            Home
                          </Link>
                          <Link
                            to="/Songs"
                            id="song"
                            onClick={handleCloseOffcanvas}
                          >
                            Songs
                          </Link>
                          <Link
                            to="/Accept-or-reject"
                            id="accept-or-reject"
                            onClick={handleCloseOffcanvas}
                          >
                            Accept/Reject
                          </Link>
                          <a
                            href="http://blog.rhymes.world:81"
                            onClick={navigateToBlog}
                            id="accept-or-reject"
                          >
                            Blog
                          </a>

                          <Link to="/translator" id="translator">
                            Translate
                          </Link>
                          <Link
                            to="/Plans"
                            id="profile"
                            onClick={handleCloseOffcanvas}
                          >
                            Premium
                          </Link>
                          <Link
                            to="/settings"
                            id="profile"
                            onClick={handleCloseOffcanvas}
                          >
                            Settings
                          </Link>
                          {/* <Link to="/games" id="games">
                            Games
                          </Link> */}
                          <Link
                            to="/chat"
                            id="games"
                            onClick={handleCloseOffcanvas}
                          >
                            Chat
                          </Link>
                          <Link
                            to="/about-us"
                            id="about-us"
                            onClick={handleCloseOffcanvas}
                          >
                            About
                          </Link>
                          {/* <Link to="/video-editing" id="about-us">
                            Editing
                          </Link> */}
                          <Link to="/user-profile" id="about-us">
                            Profile
                          </Link>
                          <Link to="/what-rhymes-with-orange" id="about-us">
                            Shop
                          </Link>
                          <Link onClick={logoutUser} id="logout">
                            Logout
                          </Link>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              ))}
            </>
          ) : (
            <>
              {["xl"].map((expand) => (
                <Navbar key={expand} expand={expand} className="Navbar">
                  <Container fluid>
                    <Navbar.Brand to="/">
                      <Link to="/">
                        <img
                          alt=""
                          src={Logo}
                          width="30"
                          height="30"
                          className="d-inline-block align-top nav-img"
                        />
                        <span id="nav-title">
                          Rhymes.<strong>World</strong>
                        </span>
                      </Link>
                      <FormControl sx={{ width: "8rem", height: "3rem" }}>
                        {isMobile ? (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },

                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-8rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "09px 26px",
                                    paddingLeft: "10px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        ) : (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              open={hovered}
                              onOpen={() => setHovered(true)} // Controlled open state
                              onClose={() => setHovered(false)}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid ",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-8rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "13px 10px",
                                    paddingLeft: "0px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        )}
                      </FormControl>
                    </Navbar.Brand>
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                    />
                    <Navbar.Offcanvas
                      style={{ width: "50%" }}
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                          id={`offcanvasNavbarLabel-expand-${expand}`}
                        >
                          Mr B's Rhymes World
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                          <Link to="/" id="Home" onClick={handleCloseOffcanvas}>
                            Home
                          </Link>
                          <Link
                            to="/Songs"
                            id="song"
                            onClick={handleCloseOffcanvas}
                          >
                            Songs
                          </Link>
                          <a
                            href="http://blog.rhymes.world:81"
                            onClick={navigateToBlog}
                            id="accept-or-reject"
                          >
                            Blog
                          </a>

                          <Link
                            to="/Plans"
                            id="profile"
                            onClick={handleCloseOffcanvas}
                          >
                            Premium
                          </Link>
                          <Link
                            to="/settings"
                            id="profile"
                            onClick={handleCloseOffcanvas}
                          >
                            Settings
                          </Link>
                          <Link to="/what-rhymes-with-orange" id="about-us">
                            Shop
                          </Link>
                          <Link onClick={logoutUser} id="logout">
                            Logout
                          </Link>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="Navbar">
              <Container fluid>
                <Navbar.Brand to="/">
                  <Link to="/">
                    <img
                      alt=""
                      src={Logo}
                      width="30"
                      height="30"
                      className="d-inline-block align-top nav-img"
                    />
                    <span id="nav-title">
                      Rhymes.<strong>World</strong>
                    </span>
                  </Link>
                  {/* <Link to="/blog">Blog</Link> */}
                  <a
                    href="http://blog.rhymes.world:81"
                    onClick={navigateToBlog}
                    id="accept-or-reject"
                  >
                    Blog
                  </a>

                  {/* <FormControl sx={{ width: "8rem", height: "3rem" }}>
                        {isMobile ? (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },

                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-8rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "09px 26px",
                                    paddingLeft: "10px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        ) : (
                          <>
                            <Select
                              id="demo-simple-select"
                              value={languageSelected}
                              onChange={handleLanguageChange}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              open={hovered}
                              onOpen={() => setHovered(true)} // Controlled open state
                              onClose={() => setHovered(false)}
                              sx={{
                                border: "none", // Remove the border
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid ",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiSelect-icon": {
                                  // Targeting the dropdown arrow
                                  marginLeft: "-8rem",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input":
                                  {
                                    padding: "13px 10px",
                                    paddingLeft: "0px",
                                  },
                              }}
                              startAdornment={
                                // Add the globe emoji as a start adornment
                                <InputAdornment
                                  position="start"
                                  sx={{ width: "5px", marginRight: "0px" }}
                                >
                                  🌐
                                </InputAdornment>
                              }
                            >
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                          </>
                        )}
                      </FormControl> */}
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  style={{ width: "50%" }}
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      Mr B's Rhymes.World
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      {/* <Link to="/" id="Home">
                        Home
                      </Link> */}
                      <Link to="/what-rhymes-with-orange" id="about-us">
                        Shop
                      </Link>
                      <Link to="/sign-up" id="Sign-up">
                        Join Now
                      </Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </>
      )}
    </header>
  );
};

export default NavBar;
