import React, { useRef } from "react";
import "./Settings.css";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import FirstSearch from "../../assets/images/first-search.png";
import FirstAccepted from "../../assets/images/first-accepted.png";
import FirstRejected from "../../assets/images/first-rejected.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserDetailsUpdateModal from "./UserDetailsUpdateModal";
import UserBadgesModal from "./UserBadgesModal";
import Blurry from "../../assets/images/Blurry_2-1.png";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import swal from "sweetalert";
import { BASE_URL } from "../Api/api";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

const Profile = () => {
  const {
    user,
    userDetails,
    languageSelected,
    setLanguageSelected,
    changeLanguage,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userSearchedCount, setUserSearchedCount] = useState();
  const [userAcceptedCount, setUserAcceptedCount] = useState();
  const [userRejectedCount, setUserRejectedCount] = useState();
  const [searchCount, setSearchCount] = useState();
  const [acceptedCount, setAcceptedCount] = useState();
  const [rejectedCount, setRejectedCount] = useState();
  const [badgeType, setBadgeType] = useState();
  const [openUserUpdateModal, setOpenUserUpdateModal] = useState(false);
  const [openUserBadgeModal, setOpenUserBadgeModal] = useState(false);

  useEffect(() => {
    let userSearchCountGet = async () => {
      let response = await fetch(
        "https://api.rhymes.world/api/user-searched-count",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.ok) {
        setUserSearchedCount(data);
        for (var i in data) {
          if (data[i].user === user.username) {
            setSearchCount(data[i].searchCount);
          }
        }
      }
    };
    userSearchCountGet();

    let AcceptedUserCountGet = async () => {
      let response = await fetch(
        "https://api.rhymes.world/api/user-words-accepted-count",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.ok) {
        setUserAcceptedCount(data);
        console.log("accepted:", data);
      }
      for (var i in data) {
        if (data[i].user === user.username) {
          setAcceptedCount(data[i].AcceptedCount);
        }
      }
    };
    AcceptedUserCountGet();

    let RejectedUserCountGet = async () => {
      let response = await fetch(
        "https://api.rhymes.world/api/user-words-rejected-count",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.ok) {
        setUserRejectedCount(data);
      }
      for (var i in data) {
        if (data[i].user === user.username) {
          setRejectedCount(data[i].RejectedCount);
        }
      }
    };
    RejectedUserCountGet();
  }, []);

  const handleLanguageChange = (event) => {
    setLanguageSelected(event.target.value);
    changeLanguage(event.target.value);
  };

  const { wordFromURL } = useParams();
  const location = useLocation();
  const inputRef = useRef();

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  const emptySearchField = () => toast("Please enter a word");
  const [isPageRender, setisPageRender] = useState(true);
  let { searchedWords, userSearchCountPost, options, credits, setCredits } =
    useContext(AuthContext);

  const REACT_APP_API_BASE_URL_SPANISH = "https://api.rhymes.world/spanish";

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      if (user) {
        if (userDetails.data.is_subscribe || userDetails.data.is_superuser) {
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (credits > 0) {
          setCredits(credits - 1);
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (userDetails.data.is_subscribe === false) {
          navigate("/plans");
        }
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${searchingWordDup}`, {
          state: { isStumble: false },
        });
      } else if (credits === 0) {
        navigate("/login");
      }
      selectText();
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    if (user) {
      if (userDetails.data.is_superuser || userDetails.data.is_subscribe) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 0);
        navigate(`/search/${text}`);
      } else if (userDetails.data.is_subscribe === false) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      navigate(`/search/${text}`);
    } else if (credits === 0) {
      navigate("/login");
    }
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.id === "customCloseButton") {
        swal.close();
      }
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        credits > 0
      ) {
        if (
          userDetails.data.is_superuser === false &&
          userDetails.data.is_subscribe === false
        ) {
          setCredits(credits - 1);
        }

        // Call search API

        selectText();
        setSuggestions([]);
        setisPageRender(false);
        setsearchingWords([]);
        setsearchingphrases([]);
        setLoading(true);
        if (isMobile === true) {
          inputRef.current.blur();
        }
        if (toggleState === false) {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/get-clean-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          for (let i of result["words"]) {
            const splitArr = i.word
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                  String(i.word)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
          setLoading(false);
        } else {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/search-rhyming-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                setSuggestions([]);
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          if (wordFromURL === "Orange" || wordFromURL === "orange") {
            for (let i of result["words"]) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            }
          } else {
            for (let i of result["words"]) {
              const splitArr = String(i.word)
                .split(regex)
                .filter((word) => !/\p{Emoji}/u.test(word));
              if (splitArr.length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (String(i.word).split("-").length > 1) {
                  setsearchingphrases((prev) =>
                    [...prev, String(i.word)].sort()
                  );
                } else {
                  if (
                    wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                      String(i.word) &&
                    !(String(i.word) in searchingWords)
                  ) {
                    if (wordFromURL !== String(i.word)) {
                      setsearchingWords((prev) =>
                        [...prev, String(i.word)].sort()
                      );
                    }
                  }
                }
              }
            }
          }

          setLoading(false);
        }
      } else if (credits <= 0) {
        navigate("plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);

      // Call search API

      selectText();
      setSuggestions([]);
      setisPageRender(false);
      setsearchingWords([]);
      setsearchingphrases([]);
      setLoading(true);
      if (isMobile === true) {
        inputRef.current.blur();
      }
      if (toggleState === false) {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/get-clean-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        for (let i of result["words"]) {
          const splitArr = i.word
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          } else {
            if (String(i.word).split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                String(i.word)
              ) {
                if (wordFromURL !== String(i.word)) {
                  setsearchingWords((prev) => [...prev, String(i.word)].sort());
                }
              }
            }
          }
        }
        setLoading(false);
      } else {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/search-rhyming-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              setSuggestions([]);
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        if (wordFromURL === "Orange" || wordFromURL === "orange") {
          for (let i of result["words"]) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          }
        } else {
          for (let i of result["words"]) {
            const splitArr = String(i.word)
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                    String(i.word) &&
                  !(String(i.word) in searchingWords)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
        }

        setLoading(false);
      }
    } else if (credits <= 0) {
      navigate("/login");
    }
  };

  return (
    <div className="settings-body">
      {/* <h1 className="profile-name">Settings</h1> */}
      <div className="search-field">
        <Form
          onSubmit={searchSubmitHandler}
          action="#"
          className="d-flex justify-cotent"
        >
          <input
            type="text"
            id="afterSearchInput"
            placeholder="Rhyming Word"
            name="searchWord"
            className="rhymingWord-Aftersearch searchInput"
            style={{ paddingLeft: "20px" }}
            ref={inputRef}
            autoFocus
            value={searchingWordDup}
            enterKeyHint="Go"
            autoComplete="off"
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
              }, 200);
            }}
            onFocus={() => {
              if (searchingWordDup) {
                // restore suggestions if there's a search term
                onChangeHandler(searchingWordDup);
              }
            }}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <button
            type="submit"
            className="submit-button-afterSearch"
            onClick={searchGet}
          >
            Find Rhymes
          </button>
          <input type="submit" style={{ display: "none" }} />
        </Form>

        <div className="afterSearch-suggestionsBox">
          {suggestions &&
            suggestions.slice(0, 8).map((suggestion, i) => (
              <div
                key={i}
                onClick={() => onSuggestHandler(suggestion)}
                className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                  i === activeSuggestionIndex ? "hover" : ""
                }`}
              >
                {suggestion}
              </div>
            ))}
        </div>
      </div>
      <div className="profile-main">
        <div className="profile-user-details">
          <div className="profile-user">
            <div className="profile-user-titles">
              <div className="py-1">Username:</div>
              <div className="py-1"> Email Address:</div>
              {/* <div className="py-1">Language Selected:</div> */}
            </div>
            <div className="profile-user-username-email">
              <div className="py-1">{user.username}</div>
              <div className="py-1">{user.email} </div>
              {/* <div className="py-1">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languageSelected}
                    label="Age"
                    onChange={handleLanguageChange}
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Spanish">Spanish</MenuItem>
                  </Select>
                </FormControl>{" "}
              </div> */}
            </div>
          </div>
          <button
            className="profile-user-update-button"
            onClick={() => {
              setOpenUserUpdateModal(true);
            }}
          >
            Edit Details
          </button>
        </div>
        <div className="profile-password-change">
          <div className="profile-password">
            <div>Password:</div>
            <div>**********</div>
          </div>
          {/* <button className="profile-password-change-button">
            Change Password
          </button> */}
        </div>
        <div className="profile-membership-details">
          <div className="profile-membership">
            <div>Membership:</div>
            <div>
              {userDetails.data.is_subscribe ? (
                <>Subscribed</>
              ) : (
                <>Unsubscribed</>
              )}
            </div>
          </div>
          <button
            className="profile-membership-update-button"
            onClick={() => {
              navigate("/plans");
            }}
          >
            Update Plan
          </button>
        </div>
        <div className="profile-user-badges">
          Badges Earned
          <div className="profile-user-badge">
            {userSearchedCount && searchCount > 0 ? (
              <img
                src={FirstSearch}
                alt="First Search"
                onClick={() => {
                  setOpenUserBadgeModal(true);
                  setBadgeType("Search");
                }}
              ></img>
            ) : (
              ""
            )}
            {userAcceptedCount && acceptedCount > 0 ? (
              <img
                src={FirstAccepted}
                alt="First Search"
                onClick={() => {
                  setOpenUserBadgeModal(true);
                  setBadgeType("Accepted");
                }}
              ></img>
            ) : (
              ""
            )}
            {userRejectedCount && rejectedCount > 0 ? (
              <img
                src={FirstRejected}
                alt="First Search"
                onClick={() => {
                  setOpenUserBadgeModal(true);
                  setBadgeType("Rejected");
                }}
              ></img>
            ) : (
              ""
            )}
            <img
              src={Blurry}
              alt="Blurry"
              onClick={() => {
                setOpenUserBadgeModal(true);
                setBadgeType("Blurry");
              }}
            ></img>
          </div>
        </div>
      </div>
      <a href="/privacy-policy">Privacy</a>
      <a href="/terms-of-service">Terms</a>
      <UserDetailsUpdateModal
        open={openUserUpdateModal}
        setOpen={setOpenUserUpdateModal}
        username={user.username}
        email={user.email}
      />

      <UserBadgesModal
        open={openUserBadgeModal}
        setOpen={setOpenUserBadgeModal}
        modalType={badgeType}
      />
    </div>
  );
};

export default Profile;
