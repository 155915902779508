import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import "./UserDetailsUpdateModal.css";
import { BASE_URL } from "../Api/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "28px",
  boxShadow: 24,
  p: 4,
  padding: "40px",
};

export default function UserDetailsUpdateModal({ open, setOpen, username }) {
  const handleClose = () => {
    setOpen(false);
  };

  const { user, updateToken, authTokens } = useContext(AuthContext);
  const [userName, setUserName] = useState(user.username);
  const [emailAddress, setEmailAddress] = useState(user.email);

  useEffect(() => {
    console.log("inside the details update modal");
  }, []);

  let updateUserDetails = async (e) => {
    e.preventDefault();
    let items = {
      "original-username": user.username,
      "updated-username": userName,
      "updated-email": emailAddress,
    };
    let response = await fetch('https://api.rhymes.world/auth/update-user-details', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(items),
    });
    if (response.ok) {
      setOpen(false);
      updateToken();
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} username={userName}>
        <Box sx={style}>
          <div>
            <form onSubmit={updateUserDetails}>
              <div className="details-updation-fields">
                <div className="user-updation-username">
                  <strong>User Name</strong>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    className="user-details-input"
                  />
                </div>
                <div className="user-updation-email">
                  <strong>Email Address</strong>
                  <input
                    type="text"
                    className="user-details-input"
                    value={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button className="user-details-update-button">
                Save Changes
              </button>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
