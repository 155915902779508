import React from 'react'
import RingLoader from "react-spinners/RingLoader";


const Loader = () => {

  return (
    <div className="sweet-loading">
    <RingLoader
      size={40}
      color='#d75d00'
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
  )
}

export default Loader