import React from "react";
import "./Blog.css";

const Blog = () => {
  return (
    <iframe
      src="http://dev.rhymes.world:81"
      style={{ width: "100%", height: "100vh", border: "none", zIndex:999999 }}
      title="WordPress Site"
    />
  );
};

export default Blog;
