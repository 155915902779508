import React, { useEffect, useState } from "react";
import { Stage, Sprite, TilingSprite, Graphics, Text } from "@pixi/react";
import { Texture } from "pixi.js";

import blue from "../../assets/GamesImages/blue.png";
import green from "../../assets/GamesImages/green.png";
import red from "../../assets/GamesImages/red.png";
import backgroundImage from "../../assets/GamesImages/backgroundImage.png";

// Define your word list
const wordList = [
  "cake",
  "fake",
  "lake",
  "make",
  "Dog",
  "jog",
  "log",
  "blog",
  "shake",
  "bake",
  "wake",
  "flog",
  "clog",
  "bog",
];

const relatedWords = {
  cake: ["fake", "lake", "make", "shake", "bake", "wake"],
  fake: ["cake", "lake", "make", "shake", "bake", "wake"],
  lake: ["cake", "fake", "make", "shake", "bake", "wake"],
  make: ["cake", "fake", "lake", "shake", "bake", "wake"],
  shake: ["cake", "fake", "lake", "make", "bake", "wake"],
  bake: ["cake", "fake", "lake", "make", "shake", "wake"],
  wake: ["cake", "fake", "lake", "make", "bake", "shake"],
  Dog: ["jog", "log", "blog", "flog", "clog", "bog"],
  jog: ["Dog", "log", "blog", "flog", "clog", "bog"],
  log: ["Dog", "jog", "blog", "flog", "clog", "bog"],
  blog: ["Dog", "jog", "log", "flog", "clog", "bog"],
  flog: ["jog", "log", "blog", "Dog", "clog", "bog"],
  clog: ["jog", "log", "blog", "flog", "Dog", "bog"],
  bog: ["jog", "log", "blog", "flog", "clog", "Dog"],
};

const lightBrown = 0xd1b482;

const BalloonGame = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [balloons, setBalloons] = useState([]);
  const [isOverGameScreen, setIsOverGameScreen] = useState(false);
  const [score, setScore] = useState(0);
  const [correctWord, setCorrectWord] = useState("");
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameActive, setIsGameActive] = useState(true);
  const [isBalloonInteractive, setIsBalloonInteractive] = useState(true);
  const [level, setLevel] = useState(1);
  const [balloonSpeed, setBalloonSpeed] = useState(1);
  const [isLevelComplete, setIsLevelComplete] = useState(false);

  const backgroundTexture = Texture.from(backgroundImage);

  useEffect(() => {
    const initialBalloons = [];
    const randomCorrectWord = getRandomWord();
    setCorrectWord(randomCorrectWord);

    for (let i = 0; i < 10; i++) {
      let randomWord;
      do {
        randomWord = getRandomWord();
      } while (randomWord === randomCorrectWord);

      initialBalloons.push(generateBalloon(randomWord, true));
    }

    setBalloons(initialBalloons);

    const animate = () => {
      moveBalloons();
      requestAnimationFrame(animate);
    };
    animate();
  }, []);

  const getRandomWord = () => {
    return wordList[Math.floor(Math.random() * wordList.length)];
  };

  const generateBalloon = (word, isInteractive = true) => {
    const colors = [Texture.from(blue), Texture.from(green), Texture.from(red)];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    const x = Math.random() * window.innerWidth; //horizontal coordinate
    const y = Math.random() * (window.innerHeight - 200); //vertical coordinate
    const speedX = Math.random() * 2 - 1;
    const speedY = -Math.random() * 2;

    return { texture: randomColor, x, y, speedX, speedY, word, isInteractive };
  };

  const moveBalloons = () => {
    setBalloons((prevBalloons) => {
      return prevBalloons.map((balloon) => {
        const newX = balloon.x + balloon.speedX * balloonSpeed; // Update balloon speed
        const newY = balloon.y + balloon.speedY * balloonSpeed;

        if (newX > window.innerWidth || newX < -100 || newY < -100) {
          return generateBalloon(balloon.word); // Regenerate the balloon
        }

        return { ...balloon, x: newX, y: newY };
      });
    });
  };

  const handleMouseMove = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    setMousePosition({ x: mouseX, y: mouseY });
  };

  const handleMouseEnter = () => {
    setIsOverGameScreen(true);
  };

  const handleMouseLeave = () => {
    setIsOverGameScreen(false);
  };

  const handleBalloonClick = (index, clickedWord) => {
    if (correctWord && relatedWords[correctWord] && clickedWord) {
      const lowercaseCorrectWord = correctWord.toLowerCase();
      const lowercaseClickedWord = clickedWord.toLowerCase();

      const lowercaseRelatedWords = relatedWords[correctWord].map((word) =>
        word.toLowerCase()
      );

      setBalloons((prevBalloons) => {
        const newBalloons = [...prevBalloons];
        if (newBalloons[index]) {
          // Check if the balloon exists
          if (
            lowercaseClickedWord === lowercaseCorrectWord ||
            lowercaseRelatedWords.includes(lowercaseClickedWord)
          ) {
            // Clicking the correct word or a related word, increment score
            newBalloons.splice(index, 1);

            // Disable interactivity for the clicked balloon
            if (newBalloons[index]) {
              // Check again before modifying the balloon
              newBalloons[index].isInteractive = false;
            }

            setScore(score + 1);

            // Check if the score is a multiple of 2 (the target)
            if ((score + 1) % 4 === 0) {
              // Increment the level and balloon speed
              setLevel(level + 1);
              setBalloonSpeed(balloonSpeed + 2);
              setIsLevelComplete(true);
            }
          } else {
            // Clicking an unrelated word, handle game over
            handleGameOver();
          }
        }
        return newBalloons;
      });
    }
  };

  const handleGameOver = () => {
    setIsGameOver(true);
    setIsGameActive(false);
  };

  const handlePlayAgain = () => {
    setIsGameOver(false);
    setIsGameActive(true);

    const initialBalloons = [];
    const randomCorrectWord = getRandomWord();
    setCorrectWord(randomCorrectWord);

    for (let i = 0; i < 10; i++) {
      let randomWord;
      do {
        randomWord = getRandomWord();
      } while (randomWord === randomCorrectWord);

      initialBalloons.push(generateBalloon(randomWord, true)); // Start balloons as interactive
    }

    setBalloons(initialBalloons);
    setScore(0);
    setLevel(1);
    setBalloonSpeed(1);
    setIsLevelComplete(false);
  };

  function getFontSizeForBalloon(balloon) {
    switch (balloon.texture) {
      case Texture.from(blue):
        return 360;
      case Texture.from(green):
        return 900;
      case Texture.from(red):
        return 700;
      default:
        return 360;
    }
  }

  function getTextPositionYForBalloon(balloon) {
    switch (balloon.texture) {
      case Texture.from(blue):
        return -200;
      case Texture.from(green):
        return -20;
      case Texture.from(red):
        return -20;
      default:
        return -20;
    }
  }

  const handleNextLevel = () => {
    setIsLevelComplete(false);
    setIsGameActive(true);

    const initialBalloons = [];
    const randomCorrectWord = getRandomWord();
    setCorrectWord(randomCorrectWord);

    for (let i = 0; i < 10; i++) {
      let randomWord;
      do {
        randomWord = getRandomWord();
      } while (randomWord === randomCorrectWord);

      initialBalloons.push(generateBalloon(randomWord, true)); // Start balloons as interactive
    }

    setBalloons(initialBalloons);
    setScore(score);
    // Instead of incrementing the level by 1, set it to the current level
    setLevel(level);
    setBalloonSpeed(balloonSpeed + 2);
  };

  const renderLevelText = () => {
    if (isGameOver) {
      return "Game Over";
    } else if (isLevelComplete) {
      return `Level ${level - 1} Completed`;
    } else {
      return `Level: ${level}`;
    }
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={isGameActive ? "target-cursor" : ""}
      style={{ overflow: "hidden", zIndex:999 }}
    >
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <TilingSprite
          texture={backgroundTexture}
          width={window.innerWidth}
          height={window.innerHeight}
        />

        <Graphics
          draw={(g) => {
            g.beginFill(lightBrown);
            g.drawRect(0, window.innerHeight - 200, window.innerWidth, 200);
            g.endFill();
          }}
        />

        {balloons.map((balloon, index) => (
          <Sprite
            key={index}
            texture={balloon.texture}
            x={balloon.x}
            y={balloon.y}
            anchor={0.5}
            width={80}
            height={80}
            interactive={isBalloonInteractive} // Set interactivity
            pointerdown={() =>
              isGameActive && handleBalloonClick(index, balloon.word)
            }
          >
            <Text
              text={balloon.word}
              style={{
                fill: "black",
                fontSize: getFontSizeForBalloon(balloon),
                align: "center",
              }}
              anchor={0.5}
              x={balloon.x}
              y={balloon.y + getTextPositionYForBalloon(balloon)}
            />
          </Sprite>
        ))}

        {!isGameOver &&
          !isLevelComplete &&
          balloons.findIndex((balloon) => balloon.word === correctWord) ===
            -1 && (
            <Text
              text={correctWord}
              style={{
                fill: "black",
                fontSize: 30,
                align: "center",
              }}
              anchor={0.5}
              x={window.innerWidth / 2}
              y={window.innerHeight - 100}
            />
          )}
      </Stage>

      {isGameOver && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ fontSize: "40px", color: "white", marginBottom: "20px" }}
          >
            Game Over
          </div>
          <button
            onClick={handlePlayAgain}
            style={{ fontSize: "30px", padding: "10px 20px" }}
          >
            Play Again
          </button>
        </div>
      )}

      {isLevelComplete && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ fontSize: "40px", color: "white", marginBottom: "20px" }}
          >
            {renderLevelText()}
          </div>
          <button
            onClick={handleNextLevel}
            style={{ fontSize: "30px", padding: "10px 20px" }}
          >
            Next Level
          </button>
        </div>
      )}

      <div
        style={{
          marginLeft: "150px",
          textAlign: "left",
          position: "absolute",
          bottom: "40px",
          width: "100%",
        }}
      >
        <p style={{ fontSize: "30px", color: "black" }}>Score: {score}</p>
        <div
          style={{
            fontSize: "30px",
            color: "black",
          }}
        >
          Level: {level}
        </div>
      </div>
    </div>
  );
};

export default BalloonGame;
