import React from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
  return (
    <div className="terms_main">
      <h3>Songs.World Terms of Service</h3>

      <p>Effective Date: December 21, 2023.</p>

      <h5>1. Acceptance of Terms</h5>
      <p>
        By accessing and using Songs.World, you acknowledge and agree to these
        Terms of Service. If you do not agree to these terms, please do not use
        our services. Songs.World reserves the right to update these terms at
        any time without prior notice.
      </p>

      <h5>2. Use of Service</h5>
      <p>
        Songs.World provides a platform for songwriting, lyric generation, and
        musical creativity. You agree to use Songs.World only for lawful
        purposes and in a way that does not infringe upon the rights of,
        restrict, or inhibit anyone else's use and enjoyment of Songs.World.
      </p>

      <h5>3. Intellectual Property Rights</h5>
      <p>
        The content on Songs.World, including but not limited to the website
        design, text, graphics, audio-visual materials, and the extensive
        database of rhyming words and phrases, is the property of Songs.World or
        its licensors and is protected by intellectual property laws. You agree
        not to reproduce, duplicate, copy, sell, resell, or exploit any portion
        of the Service, including the rhyming words and phrases database,
        without express written permission from Songs.World.
      </p>

      <h5>4. User-Generated Content</h5>
      <p>
        Users can create, generate, and upload content, including lyrics and
        songs, on Songs.World. For any music, lyrics, or other creative works
        you upload or generate on Songs.World, you retain all copyrights and
        ownership rights to your original content, excluding instrumental beats
        that are provided by Songs.World, unless otherwise noted.
      </p>

      <h5>5. User Conduct</h5>
      <p>
        You are responsible for all your activities on Songs.World and shall not
        use the Service for illegal purposes, to infringe upon others' rights,
        to upload harmful or offensive content, or to violate any laws.
        Songs.World reserves the right to remove content that violates these
        terms.
      </p>

      <h5>6. Disclaimer of Warranties</h5>
      <p>
        Songs.World is provided "as is" without any warranties, express or
        implied. Songs.World does not guarantee that the service will be
        uninterrupted or error-free.
      </p>

      <h5>7. Limitation of Liability</h5>
      <p>
        Songs.World shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages arising out of your use of the
        service.
      </p>

      <h5>8. Indemnification</h5>
      <p>
        You agree to indemnify and hold harmless Songs.World and its affiliates,
        officers, agents, employees, and licensors from any claim or demand made
        by any third party due to or arising out of your use of the Service,
        your violation of these Terms of Service, or your violation of any
        rights of another.
      </p>

      <h5>9. Modification to the Service</h5>
      <p>
        Songs.World reserves the right at any time to modify or discontinue,
        temporarily or permanently, the Service (or any part thereof) with or
        without notice.
      </p>

      <h5>10. Governing Law</h5>
      <p>
        These Terms of Service shall be governed by the laws of the jurisdiction
        in which Songs.World operates. Any disputes arising under these Terms
        shall be subject to the exclusive jurisdiction of these courts.
      </p>

      <h5>11. General Provisions</h5>
      <p>
        If any part of these Terms of Service is held invalid or unenforceable,
        that portion shall be construed in a manner consistent with applicable
        law to reflect, as nearly as possible, the original intentions of the
        parties, and the remaining portions shall remain in full force and
        effect.
      </p>

      <h5>12. Contact Information</h5>
      <p>
        If you have any questions about these Terms of Service, please contact
        us at songsdotworld@gmail.com
      </p>
    </div>
  );
};

export default TermsOfService;
