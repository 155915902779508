import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./AfterSearch.css";
import { Button, Form } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import MusicPlayer from "../MusicPlayer/MusicPlayer";
import { BASE_URL, BASE_URL_SPANISH } from "../Api/api";
import Loader from "../Loader/Loader";
import SpongeBobCrying from "../../assets/gifs/spongeBob-Crying.gif";
import NextLyricModal from "../CustomModal/NextLyricModal";
import styled from "styled-components";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RapModal from "../CustomModal/RapModal";
import GeneratedRapModal from "../CustomModal/GeneratedRapModal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Confetti from "react-confetti";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Box, InputLabel, MenuItem, Select, FormControl } from "@mui/material";
// import { useTour } from "../../context/TourContext";

const REACT_APP_API_BASE_URL_SPANISH = "https://api.rhymes.world/spanish";
const convertToTitleCase = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width

const AfterSearch = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [addFlag, setAddFlag] = useState(false);
  const [inputWord, setInputWord] = useState("");
  const [loading, setLoading] = useState(true);
  const [openRapModal, setOpenRapModal] = useState(false);
  const [openGeneratedRapModal, setOpenGeneratedRapModal] = useState(false);
  const [gptRapLoading, setGptRapLoading] = useState(false);
  const [rapGeneratedFlag, setRapGeneratedFlag] = useState(false);
  const [anyWord, setAnyWord] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [editableWord, setEditableWord] = useState("");
  const [category, setCategory] = useState("general");
  const navigate = useNavigate();
  const location = useLocation();

  let {
    user,
    searchedWords,
    userSearchCountPost,
    options,
    userDetails,
    rapGenerated,
    isWriteSongBtnClicked,
    setIsWriteSongBtnClicked,
    isRapSuccessFullyGenerated,
    languageSelected,
    credits,
    timeLeft,
    setCredits,
    RhymesEditing,
    deleteRhymes,
  } = useContext(AuthContext);

  // const { setRun, run, isTourActive } = useTour();

  // useEffect(() => {
  //   const hasVisited = localStorage.getItem("hasVisited");
  //   if (isTourActive) {
  //     setTimeout(() => {
  //       setRun(hasVisited === String(false) ? true : false);
  //     }, 1000);
  //   }
  // }, []);

  const displayTimeLeft = () => {
    if (!timeLeft) return "No active timer";
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (isWriteSongBtnClicked) {
      rapLoadingHandler();
    }
  }, [isWriteSongBtnClicked]);

  const rapLoadingHandler = () => {
    setLoadingProgress(0);
    let progressInterval = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        // If oldProgress is 100 or more, clear the interval
        if (oldProgress >= 90) {
          clearInterval(progressInterval);
          return 90;
        }

        // Otherwise, add 10% to the progress
        return oldProgress + 10;
      });
    }, 900);
    setIsWriteSongBtnClicked(false);
  };
  useEffect(() => {}, [loadingProgress]);

  // const ButtonGroup = styled.div`
  //   display: flex;
  // `;
  const [types, setTypes] = useState(["Phrases", "Words"]);

  const [active, setActive] = useState(types[0]);

  const addFlagHandler = (e) => {
    //A flag handler to submit the rhyming words into the database--------------
    e.preventDefault();
    setAddFlag(true);
  };
  const submitHandler = (e) => {
    //If the submitted word is not blank , it calls the words submitting function "wordInput"------------
    e.preventDefault();
    setAddFlag(true);
    setInputWord("");
    if (inputWord !== null) {
      wordInput();
    } else {
      alert("Please enter a word");
    }
  };

  //Below variables are used for getting the words submitted as normalized---------------------------------

  let wordToBeSubmitted = "";
  let input = "";
  let dashInput = "";
  let dashNextLineInput = "";
  let nextLineInput = "";
  const wordInput = async () => {
    //This function is used to submit the rhymes to the database
    //There are two cases -- if Word of the day is present or not.
    //the word submitted will be trimmed and then the words are submitted with respect to their length, next line or dash present between them.
    const notify = () =>
      toast(`${inputWord} has been submitted`, { autoClose: 2000 });
    const notify2 = () => toast("Something went wrong");
    const notifyNextLine = (word) =>
      toast(`${word} has been submitted`, { autoClose: 2000 });
    if (searchingArr["Word_of_the_day"]) {
      let trimmedInput = inputWord.trim();
      if (trimmedInput.split("\n").length > 1) {
        nextLineInput = trimmedInput.split("\n");
      } else if (trimmedInput.split(" ").length > 1) {
        input = trimmedInput.split(" ");
      } else if (trimmedInput.split("-").length > 1) {
        dashInput = trimmedInput.split("-");
      }
      if (input.length > 1) {
        for (let i in input) {
          input[i] = input[i][0].toUpperCase() + input[i].substring(1);
        }
        wordToBeSubmitted = input.join(" ");
      } else if (dashInput.length > 1) {
        for (let i in dashInput) {
          dashInput[i] =
            dashInput[i][0].toUpperCase() + dashInput[i].substring(1);
        }
        wordToBeSubmitted = dashInput.join("-");
      } else {
        wordToBeSubmitted =
          trimmedInput[0].toUpperCase() + trimmedInput.substring(1);
      }
      if (nextLineInput.length > 0) {
        for (let word in nextLineInput) {
          if (nextLineInput[word].split("-")) {
            dashNextLineInput = nextLineInput[word].split("-");
            for (let j in dashNextLineInput) {
              dashNextLineInput[j] = convertToTitleCase(dashNextLineInput[j]);
            }
            nextLineInput[word] = dashNextLineInput.join("-");
          } else {
            nextLineInput[word] = convertToTitleCase(nextLineInput[word]);
          }

          let username = user ? user.username : "AnonymousUser";
          let item = {
            user: username,
            word: nextLineInput[word],
            Word_of_the_day:
              searchingArr["Word_of_the_day"][0].toUpperCase() +
              searchingArr["Word_of_the_day"].substr(1),
          };
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/add-after-search`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(item),
            }
          );
          if (response.ok) {
            notifyNextLine(
              nextLineInput[word][0].toUpperCase() +
                nextLineInput[word].substring(1)
            );
          } else {
            notify2();
          }
        }
      } else {
        let username = user ? user.username : "AnonymousUser";
        let item = {
          user: username,
          word: wordToBeSubmitted,
          Word_of_the_day:
            searchingArr["Word_of_the_day"][0].toUpperCase() +
            searchingArr["Word_of_the_day"].substr(1),
        };

        // let accessToken = authTokens.access
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/add-after-search`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(item),
          }
        );
        if (response.ok) {
          notify();
        } else {
          notify2();
        }
      }
    } else {
      let trimmedInput = inputWord.trim();
      if (trimmedInput.split("\n").length > 1) {
        nextLineInput = trimmedInput.split("\n");
      } else if (trimmedInput.split(" ").length > 1) {
        input = trimmedInput.split(" ");
      } else if (trimmedInput.split("-").length > 1) {
        dashInput = trimmedInput.split("-");
      }
      if (input.length > 1) {
        for (let i in input) {
          input[i] = input[i][0].toUpperCase() + input[i].substring(1);
        }
        wordToBeSubmitted = input.join(" ");
      } else if (dashInput.length > 1) {
        for (let i in dashInput) {
          dashInput[i] =
            dashInput[i][0].toUpperCase() + dashInput[i].substring(1);
        }
        wordToBeSubmitted = dashInput.join("-");
      } else {
        wordToBeSubmitted =
          trimmedInput[0].toUpperCase() + trimmedInput.substring(1);
      }
      if (nextLineInput.length > 0) {
        for (let word in nextLineInput) {
          nextLineInput[word] = convertToTitleCase(nextLineInput[word]);
          let username = user ? user.username : "AnonymousUser";
          let item = {
            user: username,
            word: nextLineInput[word],
            Word_of_the_day:
              wordFromURL[0].toUpperCase() + wordFromURL.substr(1),
          };
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/add-after-search`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(item),
            }
          );
          if (response.ok) {
            notifyNextLine(
              nextLineInput[word][0].toUpperCase() +
                nextLineInput[word].substring(1)
            );
          } else {
            notify2();
          }
        }
      } else {
        let username = user ? user.username : "AnonymousUser";
        let item = {
          user: username,
          word: wordToBeSubmitted,
          Word_of_the_day: wordFromURL[0].toUpperCase() + wordFromURL.substr(1),
        };

        // let accessToken = authTokens.access
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/add-after-search`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(item),
          }
        );
        if (response.ok) {
          notify();
        } else {
          notify2();
        }
      }
    }
  };

  const inputLetterHandler = (e) => {
    setInputWord(e.target.value);
  };

  //WORDS SUBMITTING FUNCTIONALITY WITHOUT RHYME OF THE DAY--------------------------------
  const [addRandomFlag, setaddRandomFlag] = useState(false);

  const submitHandler2 = (e) => {
    e.preventDefault();
    setAddFlag(true);
    setaddRandomFlag(true);
  };

  const { wordFromURL } = useParams();

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const inputRef = useRef();

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const emptySearchField = () => toast("Please enter a word");

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      if (user) {
        if (userDetails.data.is_subscribe || userDetails.data.is_superuser) {
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (credits > 0) {
          setCredits(credits - 1);
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (userDetails.data.is_subscribe === false) {
          navigate("/plans");
        }
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${searchingWordDup}`, {
          state: { isStumble: false },
        });
      } else if (credits === 0) {
        navigate("/login");
      }
      selectText();
    }
  };

  const [isPageRender, setisPageRender] = useState(true);
  const [slantWords, setSlantWords] = useState(true);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        credits > 0
      ) {
        if (
          userDetails.data.is_superuser === false &&
          userDetails.data.is_subscribe === false
        ) {
          setCredits(credits - 1);
        }

        // Call search API

        selectText();
        setSuggestions([]);
        setisPageRender(false);
        setsearchingWords([]);
        setsearchingphrases([]);
        setLoading(true);
        if (isMobile === true) {
          inputRef.current.blur();
        }
        if (toggleState === false) {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/get-clean-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            console.log(result);
            setSearchingArr(result);
            setSlantWords(result.slant_words);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          for (let i of result["words"]) {
            const splitArr = i.word
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, i]);
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, i]);
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                  String(i.word)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) => [...prev, i]);
                  }
                }
              }
            }
          }
          setLoading(false);
        } else {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/search-rhyming-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            console.log(result);
            setSearchingArr(result);
            setSlantWords(result.slant_words);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                setSuggestions([]);
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          if (wordFromURL === "Orange" || wordFromURL === "orange") {
            for (let i of result["words"]) {
              setsearchingphrases((prev) => [...prev, i]);
            }
            // setsearchingphrases(result.words);
          } else {
            for (let i of result["words"]) {
              const splitArr = String(i.word)
                .split(regex)
                .filter((word) => !/\p{Emoji}/u.test(word));
              if (splitArr.length > 1) {
                setsearchingphrases((prev) => [...prev, i]);
              } else {
                if (String(i.word).split("-").length > 1) {
                  setsearchingphrases((prev) => [...prev, i]);
                } else {
                  if (
                    wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                      String(i.word) &&
                    !(String(i.word) in searchingWords)
                  ) {
                    if (wordFromURL !== String(i.word)) {
                      setsearchingWords((prev) => [...prev, i]);
                    }
                  }
                }
              }
            }
          }

          setLoading(false);
        }
      } else if (credits <= 0) {
        navigate("plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);

      // Call search API

      selectText();
      setSuggestions([]);
      setisPageRender(false);
      setsearchingWords([]);
      setsearchingphrases([]);
      setLoading(true);
      if (isMobile === true) {
        inputRef.current.blur();
      }
      if (toggleState === false) {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/get-clean-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          setSlantWords(result.slant_words);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        for (let i of result["words"]) {
          const splitArr = i.word
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, i]);
          } else {
            if (String(i.word).split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, i]);
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                String(i.word)
              ) {
                if (wordFromURL !== String(i.word)) {
                  setsearchingWords((prev) => [...prev, i]);
                }
              }
            }
          }
        }
        setLoading(false);
      } else {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/search-rhyming-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          setSlantWords(result.slant_words);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              setSuggestions([]);
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        if (wordFromURL === "Orange" || wordFromURL === "orange") {
          for (let i of result["words"]) {
            setsearchingphrases((prev) => [...prev, i]);
          }
        } else {
          for (let i of result["words"]) {
            const splitArr = String(i.word)
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, i]);
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, i]);
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                    String(i.word) &&
                  !(String(i.word) in searchingWords)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) => [...prev, i]);
                  }
                }
              }
            }
          }
        }

        setLoading(false);
      }
    } else if (credits <= 0) {
      navigate("/login");
    }
  };

  // useEffect(() => {console.log(searchingphrases[0].id)}, [searchingphrases,searchingWords]);

  //GETTING THE RANDOM WORD--------------------------------------------------------------------------------------
  let getRandomWord = async (e) => {
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        credits > 0
      ) {
        if (
          userDetails.data.is_subscribe === false &&
          userDetails.data.is_superuser === false
        ) {
          setCredits(credits - 1);
        }
        setAddFlag(false);
        setsearchingWords([]);
        setsearchingphrases([]);
        setLoading(true);
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/get-random-word`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        let result = await response.json();
        if (response.ok) {
          getRandom(result.word);
        } else if (response.status === 400) {
          getRandomWord();
        } else {
          console.log("Something went wrong!");
        }
      } else if (credits <= 0) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      setAddFlag(false);
      setsearchingWords([]);
      setsearchingphrases([]);
      setLoading(true);
      let response = await fetch(
        `${
          languageSelected === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/get-random-word`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let result = await response.json();
      if (response.ok) {
        getRandom(result.word);
      } else if (response.status === 400) {
        getRandomWord();
      } else {
        console.log("Something went wrong!");
      }
    } else {
      navigate("/login");
    }
  };

  const getRandom = (word) => {
    if (word === "") {
      alert("Enter a rhyme fool!");
    } else {
      if (user) {
        if (
          userDetails.data.is_superuser ||
          userDetails.data.is_subscribe ||
          credits > 0
        ) {
          if (
            userDetails.data.is_subscribe === false &&
            userDetails.data.is_superuser === false
          ) {
            setCredits(credits - 1);
          }
          navigate(`/search/${word}`, { state: { isStumble: true } });
        } else if (credits === 0) {
          navigate("/plans");
        }
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${word}`, { state: { isStumble: true } });
      } else {
        navigate("/login");
      }
    }
  };

  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    if (isPageRender === false) {
      searchGet();
    }

    if (location.state?.showConfetti) {
      setConfetti(true);
      setTimeout(() => setConfetti(false), 3100);
    }
  }, [location, gptRapLoading]);

  useEffect(() => {
    if (user && addFlag) {
      var focusAdd = document.getElementById("floatingInputAddMore");
      focusAdd.focus();
    } else {
      if (isPageRender) {
        if (isMobile === true) {
          inputRef.current.blur();
        }
        if (user) {
          if (
            userDetails.data.is_superuser ||
            userDetails.data.is_subscribe ||
            credits > 0
          ) {
            if (userDetails.data.is_subscribe === false) {
              setCredits(credits - 1);
            }
            console.log("Searching in progress");
            setisPageRender(false);
            setSuggestions([]);
            let searchGet = async (e) => {
              setsearchingWords([]);
              setsearchingphrases([]);
              let response = await fetch(
                `${
                  languageSelected === "English"
                    ? BASE_URL
                    : REACT_APP_API_BASE_URL_SPANISH
                }/search-rhyming-words/${wordFromURL}`
              );
              let result = await response.json();
              if (response.ok) {
                console.log(result);
                if (user) {
                  userSearchCountPost(user.username);
                }
                setSearchingArr(result);
                setSlantWords(result.slant_words);
                let username = "AnonymousUser";
                if (user) {
                  username = user.username;
                }
                if (location.state) {
                  if (location.state.isStumble !== true) {
                    searchedWords({ word: wordFromURL, user: username });
                  }
                } else {
                  searchedWords({ word: wordFromURL, user: username });
                }
              } else {
                console.log("something went wrong");
              }
              if (result.words?.length > 0) {
                if (wordFromURL === "Orange" || wordFromURL === "orange") {
                  for (let i of result["words"]) {
                    setsearchingphrases((prev) => [...prev, i]);
                  }
                } else {
                  for (let i of result.words) {
                    const splitArr = String(i.word)
                      .split(regex)
                      .filter((word) => !/\p{Emoji}/u.test(word));
                    if (splitArr.length > 1) {
                      setsearchingphrases((prev) => [...prev, i]);
                    } else {
                      if (String(i.word).split("-").length > 1) {
                        setsearchingphrases((prev) => [...prev, i]);
                      } else {
                        if (
                          wordFromURL[0].toUpperCase() +
                            wordFromURL.substr(1) !==
                          String(i.word)
                        ) {
                          if (wordFromURL !== String(i.word)) {
                            setsearchingWords((prev) => [...prev, i]);
                          }
                        }
                      }
                    }
                  }
                }
              }
              setLoading(false);
            };
            searchGet();
          }
        } else if (credits > 0) {
          console.log("credits are greater than 0");
          setCredits(credits - 1);
          console.log("Searching in progress");
          setisPageRender(false);
          setSuggestions([]);
          let searchGet = async (e) => {
            setsearchingWords([]);
            setsearchingphrases([]);
            let response = await fetch(
              `${
                languageSelected === "English"
                  ? BASE_URL
                  : REACT_APP_API_BASE_URL_SPANISH
              }/search-rhyming-words/${wordFromURL}`
            );
            let result = await response.json();
            if (response.ok) {
              if (user) {
                userSearchCountPost(user.username);
              }
              setSearchingArr(result);
              setSlantWords(result.slant_words);
              let username = "AnonymousUser";
              if (user) {
                username = user.username;
              }
              if (location.state) {
                if (location.state.isStumble !== true) {
                  searchedWords({ word: wordFromURL, user: username });
                }
              } else {
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              console.log("something went wrong");
            }
            if (result.words?.length > 0) {
              if (wordFromURL === "Orange" || wordFromURL === "orange") {
                for (let i of result["words"]) {
                  setsearchingphrases((prev) => [...prev, i]);
                }
              } else {
                for (let i of result.words) {
                  const splitArr = String(i.word)
                    .split(regex)
                    .filter((word) => !/\p{Emoji}/u.test(word));
                  if (splitArr.length > 1) {
                    setsearchingphrases((prev) => [...prev, i]);
                  } else {
                    if (String(i.word).split("-").length > 1) {
                      setsearchingphrases((prev) => [...prev, i]);
                    } else {
                      if (
                        wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                        String(i.word)
                      ) {
                        if (wordFromURL !== String(i.word)) {
                          setsearchingWords((prev) => [...prev, i]);
                        }
                      }
                    }
                  }
                }
              }
            }
            setLoading(false);
          };
          searchGet();
        } else if (credits === 0) {
          if (user) {
            navigate("/plans");
          } else {
            console.log("no credits and no user logges in ");
            navigate("/login");
          }
        }
      }
    }
  }, [
    searchingArr,
    wordFromURL,
    isPageRender,
    searchingWords,
    searchingphrases,
    addFlag,
  ]);

  const wordsRef = useRef(null);
  const phrasesRef = useRef(null);
  const slantRef = useRef(null);
  const [isPhrasesActive, setisPhrasesActive] = useState(false);
  const [isWordsActive, setisWordsActive] = useState(false);
  const [isSlantActive, setisSlantActive] = useState(false);

  const wordsHandleClick = () => {
    wordsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const phrasesHandleClick = () => {
    phrasesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const slantHandleClick = () => {
    slantRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [toggleLable, setToggleLable] = useState("Explicit");

  const toggleHandler = (state) => {
    // if(state === false){
    //   setToggleLable("Clean")
    // }else{
    //   setToggleLable("Explixcit")
    // }
    searchGet(state);
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    if (user) {
      if (userDetails.data.is_superuser || userDetails.data.is_subscribe) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 0);
        navigate(`/search/${text}`);
      } else if (userDetails.data.is_subscribe === false) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      navigate(`/search/${text}`);
    } else if (credits === 0) {
      navigate("/login");
    }
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  const [clickedArray, setClickedArray] = useState([]);
  const [clickedWordClass, setClickedWordClass] = useState([]);
  const [blur, setBlur] = useState(false);
  const [blurPercentage, setBlurPercentage] = useState(1);

  const handleBlurChange = (e) => {
    setBlurPercentage(e.target.value);
  };

  const blurStyle = {
    filter: blur ? `blur(${blurPercentage}px)` : "none",
  };

  const handleClickOutside = (event) => {
    if (
      blur &&
      !document.querySelector(".slider-container").contains(event.target)
    ) {
      setBlur(false);
    }
  };

  const wordsAndPhrasesHandler = (item) => {
    if (clickedArray.includes(item) === false) {
      setClickedArray([...clickedArray, item]);
    } else {
      setClickedArray(clickedArray.filter((val) => val !== item));
    }
  };

  const isClickedArray = (item) => {
    if (user) {
      if (
        userDetails?.data?.is_superuser === true &&
        clickedArray.includes(item) === true
      ) {
        return "array_words";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (
      wordFromURL === "blurry" ||
      wordFromURL === "blurred" ||
      wordFromURL === "blur" ||
      wordFromURL === "blurring" ||
      wordFromURL === "Blurry" ||
      wordFromURL === "Blurred" ||
      wordFromURL === "Blur" ||
      wordFromURL === "Blurring" ||
      wordFromURL === "blurs" ||
      wordFromURL === "Blurs"
    ) {
      setBlur(true);
    } else {
      setBlur(false);
    }
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [clickedArray, wordFromURL]);

  const [modalShow, setModalShow] = useState(false);
  const [lyricModalShow, setLyricModalShow] = useState(false);
  const clearClickedArray = () => {
    setClickedArray([]);
  };
  const [isRemoveClicked, setisRemoveClicked] = useState(false);
  const removeHandler = (e) => {
    e.preventDefault();
    setisRemoveClicked(!isRemoveClicked);
    let req_index = e.target.getAttribute("data-index");

    let new_arr = clickedArray;
    new_arr.splice(req_index, 1);
    setClickedArray((prev) => (prev = new_arr));
  };

  const [tabsValue, setTabsValue] = useState(0);

  const tabsValueHandler = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    if (isRapSuccessFullyGenerated) {
      console.log("rap is generated");
      setGptRapLoading(false);
      setOpenRapModal(false);
      setOpenGeneratedRapModal(true);
    } else {
      // setIsRapSuccessFullyGenerated(false);
      // setrapGenerated([]);
    }
    console.log("URL", BASE_URL);
  }, [isRapSuccessFullyGenerated]);

  const setOpenRapModalHandler = () => {
    if (userDetails) {
      setOpenRapModal(true);
    } else {
      navigate("/sign-up");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const anyWordHandler = (e) => {
    e.preventDefault();
    clickedArray.push(anyWord);
    setAnyWord("");
  };

  const [editingStatus, setEditingStatus] = useState({});
  const [editedList, setEditedList] = useState([]);

  // Function to enable editing for a phrase
  const enableEditing = (index, curElem, id) => {
    setEditingStatus({
      ...editingStatus,
      [index]: { editing: true, value: curElem },
    });
  };

  useEffect(() => {}, [editingStatus, editedList]);

  // Function to handle changes in the input fields
  const handleInputChange = (index, id, newValue) => {
    setEditingStatus({
      ...editingStatus,
      [index]: { ...editingStatus[index], value: newValue },
    });
    const existingIndex = editedList.findIndex((item) => item.id === id);

    if (existingIndex >= 0) {
      // If the id exists, create a new list with the updated item
      const updatedList = editedList.map((item, index) =>
        index === existingIndex ? { ...item, word: newValue } : item
      );
      setEditedList(updatedList);
    } else {
      // If the id doesn't exist, add the new object to the list
      setEditedList([...editedList, { id: id, word: newValue }]);
    }
  };

  const handleEditFlag = () => {
    setEditFlag(!editFlag);
  };

  const [categoryFlag, setCategoryFlag] = useState(false);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const categoryFilterHandler = (category) => {
    setCategoryFlag(true);
    console.log(
      searchingWords.filter((obj) => {
        return String(obj.category) === String(category);
      })
    );
    console.log("WORDS = ", searchingWords);
    console.log("PHRASES = ", searchingphrases);
  };

  const tabSx = { width: "25px", padding: "0" };

  return (
    <div className={"after-search-main"} style={blurStyle}>
      <div className={"after-search"}>
        <div className="after-search-fields">
          <div className="search-field">
            <Form
              onSubmit={searchSubmitHandler}
              className="d-flex justify-cotent"
            >
              <input
                type="text"
                id="afterSearchInput"
                placeholder="What rhymes with..."
                name="searchWord"
                className="rhymingWord-Aftersearch searchInput"
                style={{ paddingLeft: "20px" }}
                ref={inputRef}
                autoFocus
                value={searchingWordDup}
                enterKeyHint="Go"
                autoComplete="off"
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestions([]);
                  }, 200);
                }}
                onFocus={() => {
                  if (searchingWordDup) {
                    // restore suggestions if there's a search term
                    onChangeHandler(searchingWordDup);
                  }
                }}
                onChange={(e) => {
                  onChangeHandler(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
              <button
                type="submit"
                className="submit-button-afterSearch"
                // onClick={searchGet}
              >
                <span className="afterSearch-button-text">Find Rhymes</span>
                <span className="afterSearch-button-icon">😎</span>{" "}
                {/* Magnifying glass icon */}
              </button>

              <input type="submit" style={{ display: "none" }} />
            </Form>

            <div className="afterSearch-suggestionsBox">
              {suggestions &&
                suggestions.slice(0, 8).map((suggestion, i) => (
                  <div
                    key={i}
                    onClick={() => onSuggestHandler(suggestion)}
                    className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                      i === activeSuggestionIndex ? "hover" : ""
                    }`}
                  >
                    {suggestion}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="aftersearch-content">
        {loading ? (
          <div className="afterSearch-loader">
            <Loader />
          </div>
        ) : (
          <div>
            {searchingWords.length > 0 || searchingphrases.length > 0 ? (
              <>
                {categoryFlag === false ? (
                  <>
                    <h1 className="word-search">
                      <div className="search-results-for-param">
                        <div>
                          <span className="search-result-for-text">
                            Rhymes for{" "}
                          </span>
                          <span>
                            <strong
                              className={
                                clickedArray.includes(
                                  wordFromURL[0].toUpperCase() +
                                    wordFromURL.substring(1)
                                )
                                  ? "word-search-selected"
                                  : "word-search"
                              }
                              onClick={wordsAndPhrasesHandler.bind(
                                null,
                                wordFromURL[0].toUpperCase() +
                                  wordFromURL.substring(1)
                              )}
                            >
                              "
                              {wordFromURL[0].toUpperCase() +
                                wordFromURL.substring(1)}
                              "
                            </strong>
                          </span>
                        </div>
                        {/* <div className="stumbleAfterSearch">
                        <button
                          onClick={getRandomWord}
                          className="stumble-image-afterSearch"
                        >
                          Stumble{" "}
                          <img
                            src={stumbleIcon}
                            alt="stumble Icon"
                            width="23px"
                            height="23px"
                          />
                        </button>
                      </div> */}
                      </div>
                    </h1>
                    <div className="afterSearch-arrows-results">
                      <div className="afterSearch-leftArrow">
                        <button onClick={handleBack}>
                          <ArrowBackIcon />
                        </button>
                      </div>
                      <div className="words-and-phrases" data-mcs-theme="dark">
                        {user?.username &&
                        userDetails?.data?.is_superuser &&
                        searchingphrases[0]?.id ? (
                          <>
                            <button onClick={() => handleEditFlag()}>
                              {editFlag ? "Editing Mode" : "Edit"}
                            </button>
                            <button onClick={() => RhymesEditing(editedList)}>
                              save
                            </button>
                          </>
                        ) : (
                          ""
                        )}

                        <TabContext value={tabsValue}>
                          <TabList onChange={tabsValueHandler}>
                            <Tab
                              label="Phrases"
                              disabled={searchingphrases.length === 0}
                              sx={
                                isMobile
                                  ? {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "100px",
                                      borderTopRightRadius: "16px",
                                    }
                                  : {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "133px",
                                      borderTopRightRadius: "16px",
                                    }
                              }
                              value={0}
                            />
                            <Tab
                              label="Words"
                              disabled={searchingWords.length === 0}
                              sx={
                                isMobile
                                  ? {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "100px",
                                      borderTopRightRadius: "16px",
                                    }
                                  : {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "133px",
                                      borderTopRightRadius: "16px",
                                    }
                              }
                              value={1}
                            />
                            <Tab
                              label="Slants"
                              disabled={slantWords.length === 0}
                              sx={
                                isMobile
                                  ? {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "100px",
                                      borderTopRightRadius: "16px",
                                    }
                                  : {
                                      "&.Mui-selected": {
                                        background: "white",
                                        color: "black",
                                        fontWeight: 600,
                                      },
                                      borderTopLeftRadius: "16px",
                                      width: "133px",
                                      borderTopRightRadius: "16px",
                                    }
                              }
                              value={2}
                            />
                          </TabList>

                          {/* </Tabs> */}
                          <div>
                            <TabPanel
                              value={0}
                              sx={
                                isMobile
                                  ? { width: 20, padding: 0 }
                                  : { padding: 0 }
                              }
                            >
                              {searchingphrases.length > 0 ? (
                                <div>
                                  <div ref={phrasesRef}>
                                    <div>
                                      <div className="search-results">
                                        {searchingphrases
                                          .sort((a, b) =>
                                            a.word.localeCompare(b.word, "es")
                                          )
                                          .map((curElem, index) => (
                                            <div
                                              className="PHRASE"
                                              key={curElem.id}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className="editing-input"
                                                        />
                                                        <DeleteRoundedIcon
                                                          onClick={() =>
                                                            deleteRhymes({
                                                              id: curElem.id,
                                                            })
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                          <div>
                            <TabPanel value={1} sx={{ padding: 0 }}>
                              {searchingWords.length > 0 ? (
                                <div>
                                  <div ref={wordsRef}>
                                    <div className="search-results">
                                      {searchingWords
                                        .sort((a, b) =>
                                          a.word.localeCompare(b.word, "es")
                                        )
                                        .map((curElem, index) => {
                                          return (
                                            <div
                                              className="WORD"
                                              key={curElem.i}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                          <div>
                            <TabPanel value={2} sx={{ padding: 0 }}>
                              {slantWords.length > 0 ? (
                                <div>
                                  <div ref={slantRef}>
                                    <div className="search-results">
                                      {slantWords
                                        .sort((a, b) =>
                                          a.word.localeCompare(b.word, "es")
                                        )
                                        .map((curElem, index) => {
                                          return (
                                            <div
                                              className="WORD"
                                              key={curElem.i}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                        </TabContext>
                      </div>
                      <div className="afterSearch-rightArrow">
                        <button onClick={getRandomWord}>
                          <ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="word-search">
                      <div className="search-results-for-param">
                        <div>
                          <span className="search-result-for-text">
                            Rhymes for{" "}
                          </span>
                          <span>
                            <strong
                              className={
                                clickedArray.includes(
                                  wordFromURL[0].toUpperCase() +
                                    wordFromURL.substring(1)
                                )
                                  ? "word-search-selected"
                                  : "word-search"
                              }
                              onClick={wordsAndPhrasesHandler.bind(
                                null,
                                wordFromURL[0].toUpperCase() +
                                  wordFromURL.substring(1)
                              )}
                            >
                              "
                              {wordFromURL[0].toUpperCase() +
                                wordFromURL.substring(1)}
                              "
                            </strong>
                          </span>
                        </div>
                        {/* <div className="stumbleAfterSearch">
                        <button
                          onClick={getRandomWord}
                          className="stumble-image-afterSearch"
                        >
                          Stumble{" "}
                          <img
                            src={stumbleIcon}
                            alt="stumble Icon"
                            width="23px"
                            height="23px"
                          />
                        </button>
                      </div> */}
                      </div>
                    </h1>
                    <div className="afterSearch-arrows-results">
                      <div className="afterSearch-leftArrow">
                        <button onClick={handleBack}>
                          <ArrowBackIcon />
                        </button>
                      </div>
                      <div className="words-and-phrases" data-mcs-theme="dark">
                        {user?.username &&
                        userDetails?.data?.is_superuser &&
                        searchingphrases[0]?.id ? (
                          <>
                            <button onClick={() => handleEditFlag()}>
                              {editFlag ? "Editing Mode" : "Edit"}
                            </button>
                            <button onClick={() => RhymesEditing(editedList)}>
                              save
                            </button>
                          </>
                        ) : (
                          ""
                        )}

                        <TabContext value={tabsValue}>
                          <TabList onChange={tabsValueHandler}>
                            <Tab
                              label="Phrases"
                              disabled={searchingphrases.length === 0}
                              sx={{
                                "&.Mui-selected": {
                                  background: "white",
                                  color: "black",
                                  fontWeight: 600,
                                },
                                borderTopLeftRadius: "16px",
                                width: "133px",
                                borderTopRightRadius: "16px",
                              }}
                              value={0}
                            />
                            <Tab
                              label="Words"
                              disabled={searchingWords.length === 0}
                              sx={{
                                "&.Mui-selected": {
                                  background: "white",
                                  fontWeight: 600,
                                  color: "black",
                                },
                                borderTopLeftRadius: "16px",
                                borderTopRightRadius: "16px",
                                width: "133px",
                              }}
                              value={1}
                            />
                            <Tab
                              label="Slants"
                              disabled={slantWords.length === 0}
                              sx={{
                                "&.Mui-selected": {
                                  background: "white",
                                  fontWeight: 600,
                                  color: "black",
                                },
                                borderTopLeftRadius: "16px",
                                borderTopRightRadius: "16px",
                                width: "133px",
                              }}
                              value={2}
                            />
                          </TabList>

                          {/* </Tabs> */}
                          <div>
                            <TabPanel value={0} sx={{ padding: 0 }}>
                              {searchingphrases.length > 0 ? (
                                <div>
                                  <div ref={phrasesRef}>
                                    <div>
                                      <div className="search-results">
                                        {searchingphrases
                                          .filter((obj) => {
                                            return obj.category === category;
                                          })
                                          .sort((a, b) =>
                                            a.word.localeCompare(b.word, "es")
                                          )
                                          .map((curElem, index) => (
                                            <div
                                              className="PHRASE"
                                              key={curElem.id}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className="editing-input"
                                                        />
                                                        <DeleteRoundedIcon
                                                          onClick={() =>
                                                            deleteRhymes({
                                                              id: curElem.id,
                                                            })
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                          <div>
                            <TabPanel value={1} sx={{ padding: 0 }}>
                              {searchingWords.length > 0 ? (
                                <div>
                                  <div ref={wordsRef}>
                                    <div className="search-results">
                                      {searchingWords
                                        .filter((obj) => {
                                          return obj.category === category;
                                        })
                                        .sort((a, b) =>
                                          a.word.localeCompare(b.word, "es")
                                        )
                                        .map((curElem, index) => {
                                          return (
                                            <div
                                              className="WORD"
                                              key={curElem.i}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                          <div>
                            <TabPanel value={2} sx={{ padding: 0 }}>
                              {slantWords.length > 0 ? (
                                <div>
                                  <div ref={slantRef}>
                                    <div className="search-results">
                                      {slantWords
                                        .filter((obj) => {
                                          return obj.category === category;
                                        })
                                        .sort((a, b) =>
                                          a.word.localeCompare(b.word, "es")
                                        )
                                        .map((curElem, index) => {
                                          return (
                                            <div
                                              className="WORD"
                                              key={curElem.i}
                                            >
                                              <div>
                                                {userDetails?.data
                                                  ?.is_superuser && editFlag ? (
                                                  <>
                                                    {editingStatus[index]
                                                      ?.editing ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editingStatus[index]
                                                              .value
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              index,
                                                              curElem.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() =>
                                                            enableEditing(
                                                              index,
                                                              curElem.word,
                                                              curElem.id
                                                            )
                                                          }
                                                          className={
                                                            clickedArray.includes(
                                                              curElem.word
                                                            )
                                                              ? "array_words"
                                                              : ""
                                                          }
                                                        >
                                                          {curElem.word}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      onClick={wordsAndPhrasesHandler.bind(
                                                        null,
                                                        curElem.word,
                                                        clickedWordClass
                                                      )}
                                                      className={
                                                        clickedArray.includes(
                                                          curElem.word
                                                        )
                                                          ? "array_words"
                                                          : ""
                                                      }
                                                    >
                                                      {curElem.word}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </TabPanel>
                          </div>
                        </TabContext>
                      </div>
                      <div className="afterSearch-rightArrow">
                        <button onClick={getRandomWord}>
                          <ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h1 className="word-search no-rhymes-search">
                  "{wordFromURL[0].toUpperCase() + wordFromURL.substring(1)}"
                </h1>

                <div
                  className="words-and-phrases mCustomScrollbar no-rhymes-search-text"
                  data-mcs-theme="dark"
                >
                  {loading ? (
                    "Loading..."
                  ) : (
                    <div>
                      Dang, no rhymes found :(
                      <div>
                        <img
                          src={SpongeBobCrying}
                          alt=":("
                          className="sponge-bob-crying"
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        <div
          className={
            userDetails?.data?.is_superuser
              ? "addRhymes-and-generateSongs-admin"
              : "addRhymes-and-generateSongs"
          }
        >
          {/* <form
              onSubmit={anyWordHandler}
              className="d-flex justify-content-center"
            >
              <input
                placeholder="Wanna add anything else?"
                // name="song_username"
                type="text"
                className="username_input_pop"
                value={anyWord} 
                style={{ paddingLeft: "20px" }}
                onChange={(e) => setAnyWord(e.target.value)}
              />
              <button className="add-moreWord-button">Add</button>
            </form> */}
          {userDetails?.data?.is_superuser ? (
            <div className="afterSearch_category_box">
              <button
                className="category_deselect_button"
                onClick={() => setCategoryFlag(false)}
              >
                x
              </button>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value={"alcohol_drugs"}>Alcohol/Drugs</MenuItem>
                    <MenuItem value={"art_culture_entertainment"}>
                      Art/Culture/Entertainment
                    </MenuItem>
                    <MenuItem value={"aspirational"}>Aspirational</MenuItem>
                    <MenuItem value={"country"}>Country</MenuItem>
                    <MenuItem value={"education_learning"}>
                      Education/Learning
                    </MenuItem>
                    <MenuItem value={"fashion"}>Fashion</MenuItem>
                    <MenuItem value={"food"}>Food</MenuItem>
                    <MenuItem value={"funny"}>Funny</MenuItem>
                    <MenuItem value={"gangster_rap_ghetto"}>
                      Gangster/Ghetto
                    </MenuItem>
                    <MenuItem value={"general"}>General</MenuItem>
                    <MenuItem value={"health_wellness"}>
                      Health/Wellness
                    </MenuItem>
                    <MenuItem value={"history"}>History</MenuItem>
                    <MenuItem value={"law_justice"}>Law/Justice</MenuItem>
                    <MenuItem value={"love"}>Love</MenuItem>
                    <MenuItem value={"money_work_career"}>
                      Money/Work/Career
                    </MenuItem>
                    <MenuItem value={"nature"}>Nature</MenuItem>
                    <MenuItem value={"philosophy_ideas"}>
                      Philosophy/Ideas
                    </MenuItem>
                    <MenuItem value={"politics"}>Politics</MenuItem>
                    <MenuItem value={"relationships"}>Relationships</MenuItem>
                    <MenuItem value={"religion"}>Religion</MenuItem>
                    <MenuItem value={"science"}>Science</MenuItem>
                    <MenuItem value={"sensual_sexual"}>Sensual/Sexual</MenuItem>
                    <MenuItem value={"sports"}>Sports</MenuItem>
                    <MenuItem value={"technology"}>Technology</MenuItem>
                    <MenuItem value={"travel_adventure"}>
                      Travel/Adventure
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Button onClick={() => categoryFilterHandler(category)}>
                Go
              </Button>
            </div>
          ) : (
            ""
          )}
          <div>
            <button
              className="submit-button-song"
              onClick={() => {
                setOpenRapModal(true);
              }}
            >
              Write Song
              {clickedArray.length > 0 ? "(" + clickedArray.length + ")" : ""}
            </button>
          </div>
        </div>

        {blur && (
          <div className="slider-container">
            <input
              type="range"
              min="1"
              max="20"
              value={blurPercentage}
              onChange={handleBlurChange}
            />
          </div>
        )}
      </div>
      {user ? (
        <>
          <div className="add-more-word">
            {addFlag !== true ? (
              <>
                <button
                  className="submit-button-rhyme-before"
                  onClick={addFlagHandler}
                >
                  Add Rhymes
                </button>
              </>
            ) : (
              <div className="AddMore-form">
                <Form>
                  {userDetails?.data?.is_superuser === true ? (
                    <>
                      <textarea
                        id="floatingInputAddMore"
                        type="text"
                        placeholder={`What rhymes with ${
                          wordFromURL[0].toUpperCase() +
                          wordFromURL.substring(1)
                        }?`}
                        name="inputWord"
                        className="rhymingWord"
                        value={inputWord}
                        onChange={inputLetterHandler}
                        style={{ padding: "10px" }}
                      />
                    </>
                  ) : (
                    <input
                      id="floatingInputAddMore"
                      type="text"
                      placeholder={`What rhymes with ${
                        wordFromURL[0].toUpperCase() + wordFromURL.substring(1)
                      }?`}
                      name="inputWord"
                      className="rhymingWord"
                      value={inputWord}
                      onChange={inputLetterHandler}
                      style={{ padding: "10px" }}
                    />
                  )}
                  <button
                    className="submit-button-rhyme"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Add
                  </button>
                </Form>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}

      {confetti && <Confetti gravity={0.5} />}

      {/* <!-- Modal --> */}
      <RapModal
        open={openRapModal}
        setOpen={setOpenRapModal}
        setShowConfetti={true}
        words={clickedArray}
        onHide={() => setModalShow(false)}
        clearClickedArray={clearClickedArray}
        removeHandler={removeHandler}
        setClickedArray={setClickedArray}
        onOpenRapGeneratedModal={() => {
          setOpenGeneratedRapModal(true);
        }}
      />
      {/* end modal */}
      {/* Modal */}
      {userDetails?.data?.is_subscribe || userDetails?.data?.is_superuser ? (
        <GeneratedRapModal
          open={openGeneratedRapModal}
          setOpen={setOpenGeneratedRapModal}
          wordsArray={clickedArray}
        />
      ) : (
        <GeneratedRapModal
          open={openGeneratedRapModal}
          setOpen={setOpenGeneratedRapModal}
          wordsArray={clickedArray}
          setShowConfetti={true}
        />
      )}
      {/* end modal */}
      {/* <!-- Modal --> */}
      <NextLyricModal
        show={lyricModalShow}
        onHide={() => setLyricModalShow(false)}
        words={clickedArray}
        clearClickedArray={clearClickedArray}
        removeHandler={removeHandler}
        setClickedArray={setClickedArray}
      />
      {/* end modal */}
      <ToastContainer />
    </div>
  );
};

export default AfterSearch;
