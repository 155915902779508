import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./UserBadgesModal.css";
import Search from "../../assets/images/first-search.png";
import Accepted from "../../assets/images/first-accepted.png";
import Rejected from "../../assets/images/first-rejected.png";
import Blurry from "../../assets/images/Blurry_2-1.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "16px",
  p: 4,
  padding: "40px",
};

export default function UserDetailsUpdateModal({ open, setOpen, modalType }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="badge-modal-user">
            <div>
              <button onClick={handleClose} className="badges-close-button">
                x
              </button>
            </div>
            <img
              src={
                modalType === "Search"
                  ? Search
                  : modalType === "Accepted"
                  ? Accepted
                  : modalType === "Rejected"
                  ? Rejected
                  : Blurry
              }
              alt={modalType}
              className="modal-image"
            />
            <strong>{modalType}</strong>
            <div>
              {modalType === "Search" ? (
                <>
                  <div>Completed your first search.</div>
                </>
              ) : modalType === "Accepted" ? (
                <>
                  <div>Got your first rhyme accepted!</div>
                </>
              ) : modalType === "Rejected" ? (
                <>
                  <div>Got your first rhyme rejected.</div>
                </>
              ) : (
                <>
                  <div>Unlocked your Blurry badge.</div>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
