import React, { useState, useContext, useEffect } from "react";
import "./Song.css";
import { BASE_URL } from "../Api/api";
import AuthContext from "../../context/AuthContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import deletionGif from "../../assets/gifs/song-deletion.gif";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { useRef } from "react";

const Song = () => {
  let { user, authTokens } = useContext(AuthContext);
  const [song_data, setSongData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef();
  const songDeletedSuccessfully = () =>
    toast("Song deleted.", { autoClose: 1000 });

  useEffect(() => {
    let song_list = async () => {
      let items = { user: user.username };
      let response = await fetch(`${BASE_URL}/songs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authTokens.access,
        },
        body: JSON.stringify(items),
      });
      let data = await response.json();
      if (response.status === 200) {
        console.log(data);
        setSongData(data);
      } else {
        console.log("no data found");
      }
    };
    song_list();
  }, [authTokens.access, user.username]);
  

  const convertDate = (date) => {
    if (date) {
      let now = new Date(date).toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        // hour: "2-digit",
        // minute: "2-digit",
      });
      return now;
    } else {
      return "";
    }
  };

  let deleteRap = (e, id) => {
    swal({
      title: "Paws right there!",
      text: "Are you sure you want to delete this meow-sterpiece forever?",
      icon: deletionGif,
      buttons: ["Paws and Reflect", "Shred it, Kitty"],
      dangerMode: true,
      content: {
        element: "div",
        attributes: {
          innerHTML: `<button id="customCloseButton" style="position:absolute; right:10px; top:10px;">X</button>`,
        },
      },
    }).then(function (isConfirm) {
      if (isConfirm) {
        let deleteRapSong = async () => {
          let response = await fetch(`${BASE_URL}/edit-song/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authTokens.access,
            },
          });
          let data = await response.json();
          if (response.status === 200) {
            songDeletedSuccessfully();
            setSongData(data);
          }
        };
        deleteRapSong();
      } else {
      }
    });
  };

  const { wordFromURL } = useParams();

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  const emptySearchField = () => toast("Please enter a word");
  const [isPageRender, setisPageRender] = useState(true);
  let {
    searchedWords,
    userSearchCountPost,
    options,
    userDetails,
    rapGenerated,
    isWriteSongBtnClicked,
    setIsWriteSongBtnClicked,
    isRapSuccessFullyGenerated,
  } = useContext(AuthContext);

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const searchSubmitHandler = (e) => {
    // e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      navigate(`/search/${searchingWordDup}`, { state: { isStumble: false } });
      selectText();
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    navigate(`/search/${text}`);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.id === "customCloseButton") {
        swal.close();
      }
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    selectText();
    setSuggestions([]);
    setisPageRender(false);
    setsearchingWords([]);
    setsearchingphrases([]);
    setLoading(true);
    if (isMobile === true) {
      inputRef.current.blur();
    }
    if (toggleState === false) {
      let response = await fetch(`${BASE_URL}/get-clean-words/${wordFromURL}`);
      let result = await response.json();
      if (response.ok) {
        setSearchingArr(result);
        // setSlantWords(result.slant_words);
        let username = "AnonymousUser";
        if (user) {
          username = user.username;
          userSearchCountPost(user.username);
        }
        if (location.state) {
          if (location.state.isStumble !== true) {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          searchedWords({ word: wordFromURL, user: username });
        }
      } else {
        console.log("something went wrong");
      }
      for (let i of result["words"]) {
        const splitArr = i
          .split(regex)
          .filter((word) => !/\p{Emoji}/u.test(word));
        if (splitArr.length > 1) {
          setsearchingphrases((prev) => [...prev, i].sort());
        } else {
          if (i.split("-").length > 1) {
            setsearchingphrases((prev) => [...prev, i].sort());
          } else {
            if (wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !== i) {
              if (wordFromURL !== i) {
                setsearchingWords((prev) => [...prev, i].sort());
              }
            }
          }
        }
      }
      setLoading(false);
    } else {
      let response = await fetch(
        `${BASE_URL}/search-rhyming-words/${wordFromURL}`
      );
      let result = await response.json();
      if (response.ok) {
        setSearchingArr(result);
        // setSlantWords(result.slant_words);
        let username = "AnonymousUser";
        if (user) {
          username = user.username;
          userSearchCountPost(user.username);
        }
        if (location.state) {
          if (location.state.isStumble !== true) {
            setSuggestions([]);
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          searchedWords({ word: wordFromURL, user: username });
        }
      } else {
        console.log("something went wrong");
      }
      if (wordFromURL === "Orange" || wordFromURL === "orange") {
        console.log(searchingArr);
        setsearchingphrases(result.words);
      } else {
        for (let i of result["words"]) {
          const splitArr = i
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, i].sort());
          } else {
            if (i.split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, i].sort());
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !== i &&
                !(i in searchingWords)
              ) {
                if (wordFromURL !== i) {
                  setsearchingWords((prev) => [...prev, i].sort());
                }
              }
            }
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <>
      <div className="song_main">
        <div className="search-field">
          <Form
            onSubmit={searchSubmitHandler}
            action="#"
            className="d-flex justify-cotent"
          >
            <input
              type="text"
              id="afterSearchInput"
              placeholder="Rhyming Word"
              name="searchWord"
              className="rhymingWord-Aftersearch searchInput"
              style={{ paddingLeft: "20px" }}
              ref={inputRef}
              autoFocus
              value={searchingWordDup}
              enterKeyHint="Go"
              autoComplete="off"
              onBlur={() => {
                setTimeout(() => {
                  setSuggestions([]);
                }, 200);
              }}
              onFocus={() => {
                if (searchingWordDup) {
                  // restore suggestions if there's a search term
                  onChangeHandler(searchingWordDup);
                }
              }}
              onChange={(e) => {
                onChangeHandler(e.target.value);
              }}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
            />
            <button
              type="submit"
              className="submit-button-afterSearch"
              onClick={searchGet}
            >
              Find Rhymes
            </button>
            <input type="submit" style={{ display: "none" }} />
          </Form>

          <div className="afterSearch-suggestionsBox">
            {suggestions &&
              suggestions.slice(0, 8).map((suggestion, i) => (
                <div
                  key={i}
                  onClick={() => onSuggestHandler(suggestion)}
                  className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                    i === activeSuggestionIndex ? "hover" : ""
                  }`}
                >
                  {suggestion}
                </div>
              ))}
          </div>
        </div>
        <div className="song_card">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Song</th>
                  {/* <th>Created At</th> */}
                </tr>
              </thead>
              <tbody>
                {song_data?.length > 0 ? (
                  <>
                    {song_data.map((element) => {
                      return (
                        <>
                          <tr>
                            <td
                              key={element.id}
                              onClick={() =>
                                navigate("/edit-song/" + element.id)
                              }
                              className="song_row"
                            >
                              <span className="song_columns">
                                {element.title
                                  ? element.title
                                  : "Unititled Song"}
                              </span>
                            </td>
                            {/* <td>{convertDate(element.created_at)}</td> */}
                            {/* <td>
                              <Link to={"/edit-song/" + element.id}>
                                <i className="fa fa-edit"></i>
                              </Link>
                            </td> */}
                            <td className="delete_bar">
                              <i
                                className="fa fa-trash delete_icon"
                                onClick={(e) => deleteRap(e, element.id)}
                              ></i>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  "Songs will display here once generated."
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default Song;
