import React, { useContext, useState, useEffect } from "react";
import "./Login.css";
import "./LoginForm.css";
import { LinkContainer } from "react-router-bootstrap";
import { FloatingLabel, Form } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import ReactGA from "react-ga";

const LoginPage = () => {
  useEffect(() => {}, []);
  const navigate = useNavigate();

  let { loginUser, user } = useContext(AuthContext);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const location = useLocation();

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [navigate, user]);

  const loginHandler = (e) => {
    e.preventDefault();
    let loginItem = {
      username: e.target.username.value,
      password: e.target.password.value,
    };
    if (location.state) {
      let items = {
        inputArr: location.state.inputArr,
        Word_of_the_day: location.state.Word_of_the_day,
      };
      loginUser({ loginCreds: loginItem, arr: items });
    } else {
      loginUser({ loginCreds: loginItem });
    }
  };

  const handlePassToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const loginPanel = (
    <div className="login-main">
      <div className="login-card">
        <div id="text-1">
          <span>Welcome Back! Login</span>
        </div>
        {/* Login Form */}
        <Form onSubmit={loginHandler} className="d-flex flex-column">
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            name="username"
            placeholder="    Enter your email address"
            className="login-input"
            style={{ paddingLeft: "20px" }}
          />

          <label htmlFor="">Password</label>
          <span>
            <input
              type={type}
              name="password"
              placeholder="    Enter strong password (minimum 6 characters)"
              className="login-input"
              style={{ paddingLeft: "20px" }}
            />
            <Icon
              onClick={handlePassToggle}
              icon={icon}
              size={20}
              className="password-icon"
            />
          </span>
          <LinkContainer to="/reset-password/">
            <div id="text-4">Forgot password?</div>
          </LinkContainer>
          <button className="login-button" type="submit">
            Let's Rhyme
          </button>
        </Form>

        <div id="text-5">
          <span>New here?&nbsp;</span>
          <span>
            <LinkContainer to="/sign-up">
              <span>
                <strong>Sign Up</strong> Now
              </span>
            </LinkContainer>
          </span>
        </div>
      </div>
    </div>
  );
  return <>{user === null ? loginPanel : null}</>;
};

export default LoginPage;
