import React, { useState, useEffect, useRef, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import AdminPage from "./components/Admin_page/AdminPage";
import Login from "./components/HomePage/Login";
import Signup from "./components/HomePage/Signup";
import EditSong from "./components/EditSongPage/EditSong";
import Song from "./components/SongPage/Song";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import PrivateRoute from "./utils/PrivateRoute";
import AcceptOrReject from "./components/AcceptOrReject/AcceptOrReject";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import Search from "./components/SearchPage/Search";
import Blog from "./components/BlogPage/Blog";
import Community from "./components/CommunityPage/Community";
import AfterSearch from "./components/AfterSearch/AfterSearch";
import ReactGA from "react-ga";
import Settings from "./components/Settings/Settings";
import EditSongtModal from "./components/EditSongPage/EditSong";
import GeneratedRapModal from "./components/CustomModal/GeneratedRapModal";
import Translator from "./components/Translator/Translator";
import AboutUs from "./components/AboutUs/AboutUs";
import BalloonGame from "./components/Games/BalloonGame";
import WriteSongPage from "./components/SongPage/WriteSongPage";
import ChatBot from "./components/ChatBot/ChatBot";
import TalkingCharacter from "./components/ChatBot/TalkingCharacter";
import VideoEditing from "./components/VideoEditting/VideoEditing";
import { Icons, ToastContainer } from "react-toastify";
import MusicPlayer from "./components/MusicPlayer/MusicPlayer";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService/TermsOfService";
import UserProfile from "./components/UserProfiles/UserProfile";
import ForgotPass from "./components/ForgotPass/ForgotPass";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import NewHomePage from "./components/NewHomePage/NewHomePage";
import { TourProvider } from "./context/TourContext";
import AppWithJoyride from "./components/TourManager/TourManager";
import ZoomSdk from "./components/ZoomSdk/ZoomSdk";
// import { ZoomMtg } from "@zoomus/websdk";
import CreateMeeting from "./components/ZoomSdk/CreateMeeting";
import AuthPage from "./components/ZoomSdk/AuthPage";
import homeWaterBg from "./assets/videos/homeWaterBg.mp4";
import homeWaterBgIphone from "./assets/images/iPhoneBG.png";
import CategoryResult from "./components/CategoryResult/CategoryResult";
import WhatRhymesWithOrange from "./components/ShopNow/WhatRhymesWithOrange";

const TRACKING_ID = "UA-258417562-1";
ReactGA.initialize(TRACKING_ID);
const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width

const App = () => {
  let payload = {
    meetingNumber: 87874002846,
    role: 0,
    sdkKey: "2euwchFXTY6VT39JKW7pIA",
    sdkSecrets: "ffPEjbws5LlvGcyTse0AaW3HloBwBjLk",
    passWord: "6zipku",
    userName: "Testing",
    userEmail: "dodwalnandita@gmail.com",
    leaveUrl: "http://localhost:3000",
  };

  let { user, userDetails } = useContext(AuthContext);
  let UserLoggin = localStorage.getItem("auth-tokens")
    ? JSON.parse(localStorage.getItem("auth-tokens"))
    : null;
  useEffect(() => {
    let UserLoggin = localStorage.getItem("auth-tokens")
      ? JSON.parse(localStorage.getItem("auth-tokens"))
      : null;
    if (UserLoggin) {
      console.log("User is logged in:", user);
      // Perform some action when the user is logged in
    } else {
      console.log("No user logged in");
      // Perform some other action when there is no user
    }
  }, []);

  return (
    <>
      {/* <TourProvider steps={steps}> */}
      <TourProvider>
        <AuthProvider>
          {isMobile ? (
            <img
              alt="background-water"
              className="fixed-bg"
              src={homeWaterBgIphone}
              preload="auto"
              style={{ position: "fixed" }}
            />
          ) : (
            <video
              className="fixed-bg"
              src={homeWaterBg}
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              style={{ position: "fixed" }}
            />
          )}

          <Navbar />
          <main className="app-main">
            <Routes>
              {UserLoggin ? (
                <>
                  <Route path="/" element={<Search />} />
                </>
              ) : (
                <>
                  {/* <Route path="/" element={<NewHomePage />} /> */}
                  <Route path="/" element={<Search />} />
                </>
              )}

              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<Signup />} />
              <Route
                path="/Admin/*"
                element={
                  <PrivateRoute>
                    <AdminPage />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/meeting/*"
                element={
                  <PrivateRoute>
                    <ZoomSdk payload={payload} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-meeting/*"
                element={
                  <PrivateRoute>
                    <CreateMeeting />
                  </PrivateRoute>
                }
              />
              <Route
                path="/auth"
                element={
                  <PrivateRoute>
                    <AuthPage />
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="/Accept-or-reject/*"
                element={
                  <PrivateRoute>
                    <AcceptOrReject />
                  </PrivateRoute>
                }
              />
              <Route path="/search/:wordFromURL" element={<AfterSearch />} />
              <Route path="/category/:category" element={<CategoryResult />} />
              <Route path="/blog" element={<Blog />} />
              <Route
                path="/Community/*"
                element={
                  <PrivateRoute>
                    <Community />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings/*"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/songs/*"
                element={
                  <PrivateRoute>
                    <Song />
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit-song/:id"
                element={
                  <PrivateRoute>
                    <EditSong />
                  </PrivateRoute>
                }
              />
              <Route path="/plans/*" element={<CheckoutPage />} />
              <Route path="/write-song/*" element={<WriteSongPage />} />
              <Route
                path="/translator/*"
                element={
                  <PrivateRoute>
                    <Translator />
                  </PrivateRoute>
                }
              />
              <Route
                path="/about-us/*"
                element={
                  <PrivateRoute>
                    <AboutUs />
                  </PrivateRoute>
                }
              />
              <Route
                path="/generated-song/*"
                element={
                  <PrivateRoute>
                    <GeneratedRapModal />
                  </PrivateRoute>
                }
              />
              <Route
                path="/games/*"
                element={
                  <PrivateRoute>
                    <BalloonGame />
                  </PrivateRoute>
                }
              />
              <Route
                path="/chat/*"
                element={
                  <PrivateRoute>
                    <ChatBot />
                  </PrivateRoute>
                }
              />
              <Route
                path="/talking-character/*"
                element={
                  <PrivateRoute>
                    <TalkingCharacter />
                  </PrivateRoute>
                }
              />
              <Route
                path="/video-editing/*"
                element={
                  <PrivateRoute>
                    <VideoEditing />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-profile/*"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />
              <Route path="*" component={"Invalid Path"} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/reset-password/" element={<ForgotPass />} />
              <Route
                path="/what-rhymes-with-orange"
                element={<WhatRhymesWithOrange />}
              />
            </Routes>
          </main>
          <MusicPlayer />
          {/* </div> */}
        </AuthProvider>

        <AppWithJoyride />
      </TourProvider>
      <ToastContainer />
    </>
  );
};

export default App;
