import React, { useState, useEffect } from "react";
import img from "../../assets/images/Mr.B.png";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import "./AcceptOrReject.css";
import { BASE_URL } from "../Api/api";
import ReactPaginate from "react-paginate";
import { arrowRight2 } from "react-icons-kit/icomoon/arrowRight2";
import { arrowLeft2 } from "react-icons-kit/icomoon/arrowLeft2";
import Icon from "react-icons-kit";

const AdminPage = () => {
  let {
    words,
    SpanishWords,
    REACT_APP_API_BASE_URL_SPANISH,
    languageSelected,
  } = useContext(AuthContext);
  const [acceptRejectObj, setAcceptRejectObj] = useState({});
  let radios = document.getElementsByName("acceptReject");
  const [count, setcount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 100;
  const offset = currentPage * PER_PAGE;

  const currentPageData = words
    .slice(offset, offset + PER_PAGE)
    .map((curElem) => {
      return (
        <tr key={curElem.word}>
          <td>{curElem.user}</td>
          <td>{curElem.Word_of_the_day}</td>
          <td>{curElem.word}</td>
          <td>
            <form>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="accept"
                    name="acceptReject"
                    id={curElem.word}
                  />
                  ✅
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="reject"
                    name="acceptReject"
                    id={curElem.word}
                  />
                  ❌
                </label>
              </div>
            </form>
          </td>
          <td>
            <form>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="checkbox"
                  name="checkbox"
                  id={curElem.word}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const acceptRadioButton = document.querySelector(
                        `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                      );
                      if (acceptRadioButton) {
                        acceptRadioButton.checked = true;
                      }
                    }
                  }}
                />
              </div>
            </form>
          </td>
          <td>
            <form>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="slantCheckbox"
                  name="slantCheckbox"
                  id={curElem.word}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const acceptRadioButton = document.querySelector(
                        `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                      );
                      if (acceptRadioButton) {
                        acceptRadioButton.checked = true;
                      }
                    }
                  }}
                />
              </div>
            </form>
          </td>
          <td>
            <form>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="explicitCheckbox"
                  name="explicitCheckbox"
                  id={curElem.word}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const acceptRadioButton = document.querySelector(
                        `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                      );
                      if (acceptRadioButton) {
                        acceptRadioButton.checked = true;
                      }
                    }
                  }}
                />
              </div>
            </form>
          </td>
        </tr>
      );
    });

  const currentPageSpanishData = SpanishWords.slice(
    offset,
    offset + PER_PAGE
  ).map((curElem) => {
    return (
      <tr key={curElem.word}>
        <td>{curElem.user}</td>
        <td>{curElem.Word_of_the_day}</td>
        <td>{curElem.word}</td>
        <td>
          <form>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="accept"
                  name="acceptReject"
                  id={curElem.word}
                />
                ✅
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="reject"
                  name="acceptReject"
                  id={curElem.word}
                />
                ❌
              </label>
            </div>
          </form>
        </td>
        <td>
          <form>
            <div className="checkbox">
              <input
                type="checkbox"
                value="checkbox"
                name="checkbox"
                id={curElem.word}
                onChange={(e) => {
                  if (e.target.checked) {
                    const acceptRadioButton = document.querySelector(
                      `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                    );
                    if (acceptRadioButton) {
                      acceptRadioButton.checked = true;
                    }
                  }
                }}
              />
            </div>
          </form>
        </td>
        <td>
          <form>
            <div className="checkbox">
              <input
                type="checkbox"
                value="slantCheckbox"
                name="slantCheckbox"
                id={curElem.word}
                onChange={(e) => {
                  if (e.target.checked) {
                    const acceptRadioButton = document.querySelector(
                      `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                    );
                    if (acceptRadioButton) {
                      acceptRadioButton.checked = true;
                    }
                  }
                }}
              />
            </div>
          </form>
        </td>
        <td>
          <form>
            <div className="checkbox">
              <input
                type="checkbox"
                value="explicitCheckbox"
                name="explicitCheckbox"
                id={curElem.word}
                onChange={(e) => {
                  if (e.target.checked) {
                    const acceptRadioButton = document.querySelector(
                      `input[type="radio"][name="acceptReject"][id="${e.target.id}"][value="accept"]`
                    );
                    if (acceptRadioButton) {
                      acceptRadioButton.checked = true;
                    }
                  }
                }}
              />
            </div>
          </form>
        </td>
      </tr>
    );
  });

  const pageCount = Math.ceil(words.length / PER_PAGE);
  const pageCountSpanish = Math.ceil(SpanishWords.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const acceptRejectHandler = async (objectOfAllWords) => {
    let response = await fetch(
      `${
        String(languageSelected) === "English"
          ? BASE_URL
          : REACT_APP_API_BASE_URL_SPANISH
      }/accept-or-reject`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectOfAllWords),
      }
    );
    if (response.ok) {
      alert("all the words have been accepted or rejected.");
      window.location.reload();
    } else {
      console.log("something went wrong");
    }
  };
  let cboxes = document.getElementsByName("checkbox");
  let sboxes = document.getElementsByName("slantCheckbox");
  let eboxes = document.getElementsByName("explicitCheckbox");

  const acceptRejectValue = (e) => {
    e.preventDefault();
    let j_value = 0;
    for (let i = 0; i < radios.length; i++) {
      let isChecked = false;
      let isSlantChecked = false;
      let isExplicitChecked = false;
      if (i % 2 === 0) {
        isChecked = cboxes[j_value].checked ? true : false;
        isSlantChecked = sboxes[j_value].checked ? true : false;
        isExplicitChecked = eboxes[j_value].checked ? true : false;
        j_value++;
      }
      if (radios[i].checked) {
        setcount((count) => count + 1);
        let is_accepted = radios[i].value === "accept" ? true : false;
        setAcceptRejectObj((prev) => ({
          ...prev,
          [i]: {
            word: radios[i].id,
            is_accepted: is_accepted,
            is_oneWay: isChecked,
            is_slant: isSlantChecked,
            is_explicit: isExplicitChecked,
          },
        }));
      }
    }
  };
  let lengthOfAcceptRejectObject = Object.keys(acceptRejectObj).length;
  if (
    lengthOfAcceptRejectObject === count &&
    lengthOfAcceptRejectObject !== 0
  ) {
    acceptRejectHandler(acceptRejectObj);
  }

  const selectAllAccepts = () => {
    const acceptedRadios = document.querySelectorAll(
      'input[type="radio"][value="accept"]'
    );
    acceptedRadios.forEach((radio) => {
      radio.checked = true;
    });
  };

  const selectAllOneWay = () => {
    const oneWayCheckbox = document.querySelectorAll(
      'input[type="checkbox"][value="checkbox"]'
    );
    oneWayCheckbox.forEach((checkbox) => {
      checkbox.checked = true;
    });
    const acceptedRadios = document.querySelectorAll(
      'input[type="radio"][value="accept"]'
    );
    acceptedRadios.forEach((radio) => {
      radio.checked = true;
    });
  };

  return (
    <div className="accept-reject-main">
      <div className="accept-reject-card">
        <div className="image">
          <img className="logo" src={img} alt="Mr. B" />
        </div>
        <div className="container">
          <div className="input-word">
            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={acceptRejectValue}
            >
              Submit
            </button>
            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={selectAllAccepts}
            >
              Select all accepted
            </button>
            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={selectAllOneWay}
            >
              Select all one way
            </button>

            {languageSelected === "English" ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Word of the day</th>
                      <th scope="col">Word Submitted</th>
                      <th scope="col">Accept or Reject</th>
                      <th scope="col">One Way</th>
                      <th scope="col">Slant</th>
                      <th scope="col">Explicit</th>
                    </tr>
                  </thead>
                  <tbody>{currentPageData}</tbody>
                </table>
                <ReactPaginate
                  previousLabel={
                    pageCount > 0 ? <Icon icon={arrowLeft2} /> : ""
                  }
                  nextLabel={pageCount > 0 ? <Icon icon={arrowRight2} /> : ""}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </>
            ) : languageSelected === "Spanish" ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Word of the day</th>
                      <th scope="col">Word Submitted</th>
                      <th scope="col">Accept or Reject</th>
                      <th scope="col">One Way</th>
                      <th scope="col">Slant</th>
                      <th scope="col">Explicit</th>
                    </tr>
                  </thead>
                  <tbody>{currentPageSpanishData}</tbody>
                </table>
                <ReactPaginate
                  previousLabel={
                    pageCountSpanish > 0 ? <Icon icon={arrowLeft2} /> : ""
                  }
                  nextLabel={
                    pageCountSpanish > 0 ? <Icon icon={arrowRight2} /> : ""
                  }
                  pageCount={pageCountSpanish}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </>
            ) : (
              ""
            )}

            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={acceptRejectValue}
            >
              Submit
            </button>
            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={selectAllAccepts}
            >
              Select all accepted
            </button>
            <button
              className="submit-button-WOTD"
              type="submit"
              onClick={selectAllOneWay}
            >
              Select all one way
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
