import React from "react";
import { Button, Form } from "react-bootstrap";
import "./Search.css";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { BASE_URL } from "../Api/api";
import Confetti from "react-confetti";
import { useTour } from "../../context/TourContext";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Search = () => {
  const { setRun, run, setIsTourActive } = useTour();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // const hasVisited = localStorage.getItem("hasVisited");
    // setRun(hasVisited === "false" ? true : false);
    // setIsTourActive(hasVisited === "false" ? true : false);
  }, []);

  // Helping functions for searching rhyming words-------------------------------------------------------------------------------
  let {
    wordOfDay,
    user,
    userDetails,
    options,
    REACT_APP_API_BASE_URL_SPANISH,
    languageSelected,
    changeLanguageGet,
    words,
    homeWordsPhrasesGet,
    homeWords,
    homePhrases,
    credits,
    setCredits,
    timeLeft,
  } = useContext(AuthContext);
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  let [searchWord, setSearchWord] = useState("");
  const [confetti, setConfetti] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState("general");

  const handleConfettiClick = () => {
    setConfetti(true);
    // Automatically stop the confetti after a few seconds
    setTimeout(() => setConfetti(false), 3000);
  };

  const displayTimeLeft = () => {
    if (!timeLeft) return "No active timer";
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  let searchGet = async (e) => {
    // e.preventDefault();
    if (searchWord === "") {
      alert("Enter a rhyme fool!");
    } else {
      if (user) {
        if (
          userDetails.data.is_superuser ||
          userDetails.data.is_subscribe ||
          run === true
        ) {
          navigate(`/search/${searchWord}`, { state: { isBlurry: true } });
        } else if (credits >= 1) {
          setCredits(credits - 1);
          navigate(`/search/${searchWord}`, { state: { isBlurry: true } });
        } else if (
          userDetails.data.is_subscribe === false &&
          userDetails?.data.is_superuser === false
        ) {
          navigate("/plans");
        }
      } else {
        if (credits > 0) {
          setCredits(credits - 1);
          navigate(`/search/${searchWord}`, { state: { isBlurry: true } });
        } else {
          navigate("/login");
        }
      }
    }
  };

  let searchRecent = async (Word) => {
    // e.preventDefault();
    if (user) {
      if (
        userDetails.data.is_subscribe ||
        userDetails?.data.is_superuser ||
        run === true
      ) {
        handleConfettiClick();
        navigate(`/search/${Word}`, {
          state: { isBlurry: true, showConfetti: true },
        });
      } else if (credits > 0) {
        handleConfettiClick();
        setCredits(credits - 1);
        navigate(`/search/${Word}`, {
          state: { isBlurry: true, showConfetti: true },
        });
      } else if (
        userDetails.data.is_subscribe === false &&
        userDetails?.data.is_superuser === false
      ) {
        navigate("/plans");
      }
    } else {
      console.log(credits);
      if (credits > 0) {
        handleConfettiClick();
        setCredits(credits - 1);
        navigate(`/search/${Word}`, {
          state: { isBlurry: true, showConfetti: true },
        });
      } else if (credits === 0) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    var focus = document.getElementById("floatingInputSearch");
    focus.focus();
    changeLanguageGet();
    homeWordsPhrasesGet();
  }, []);

  // Helping functions for inputting rhyming words---------------------------------------------------------------------------

  const d = new Date();
  var day = d.toLocaleString("en-UK", { day: "2-digit" });
  var month = d.toLocaleString("en-UK", { month: "2-digit" });
  var year = d.toLocaleString("en-UK", { year: "numeric" });

  const today_date = year + "-" + month + "-" + day;

  const [inputArr, setInputArr] = useState([]);
  const [inputWord, setInputWord] = useState("");
  const [isRemoveClicked, setisRemoveClicked] = useState(false);

  let getRandomWord = async (e) => {
    e.preventDefault();
    let response = await fetch(
      `${
        String(languageSelected) === "English"
          ? BASE_URL
          : REACT_APP_API_BASE_URL_SPANISH
      }/get-random-word`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      getRandom(result.word);
    } else {
      console.log("Something went wrong!");
    }
  };

  const getRandom = (word) => {
    if (word === "") {
      alert("Enter a rhyme fool!");
    } else if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        run === true
      ) {
        navigate(`/search/${word}`, { state: { isStumble: true } });
      } else if (credits > 1) {
        setCredits(credits - 1);
        navigate(`/search/${word}`, { state: { isStumble: true } });
      } else if (
        userDetails.data.is_subscribe === false &&
        userDetails?.data.is_superuser === false
      ) {
        navigate("/plans");
      }
    } else {
      if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${word}`, { state: { isStumble: true } });
      } else {
        navigate("/login");
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (user) {
      if (inputArr.length > 0) {
        setInputWord("");
        wordInput();
        setInputArr([]);
      } else {
        inputArr.push({ inputWord });
        setInputWord("");
        wordInput();
        setInputArr([]);
      }
    } else {
      navigate("/login");
    }
  };
  const changInput = (e) => {
    if (inputWord === "") {
      alert("Enter a valid word!");
    } else {
      e.preventDefault();
      setInputArr([...inputArr, { inputWord }]);
      setInputWord("");
    }
  };

  const removeHandler = (e) => {
    e.preventDefault();
    setisRemoveClicked(!isRemoveClicked);
    let req_index = e.target.getAttribute("data-index");

    let new_arr = inputArr;
    new_arr.splice(req_index, 1);
    setInputArr((prev) => (prev = new_arr));
  };

  let reqWord = "";
  for (let value of wordOfDay) {
    if (value["date"] === today_date) {
      reqWord = value["Word_of_the_day"];
    }
  }

  let wordInput = async (e) => {
    for (let word in inputArr) {
      if (inputArr[word].inputWord === "") {
        continue;
      }
      const notify = () =>
        toast(`${inputArr[word].inputWord} has been submitted`);
      const notify2 = () => toast("Something went wrong");
      let item = {
        user: user.username,
        word:
          inputArr[word].inputWord[0].toUpperCase() +
          inputArr[word].inputWord.substr(1),
        Word_of_the_day: reqWord,
      };
      // console.log(authTokens.access)
      // let accessToken = authTokens.access
      let response = await fetch(
        `${
          String(languageSelected) === "English"
            ? BASE_URL
            : REACT_APP_API_BASE_URL_SPANISH
        }/home-input`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(item),
        }
      );
      if (response.ok) {
        notify();
      } else {
        notify2();
      }
    }
  };

  const mappingHelper = inputArr.map((value, index) => {
    return (
      <div key={index}>
        <span className="before-submission container">
          {value.inputWord}&nbsp;
          <button
            className="remove-button"
            data-index={index}
            onClick={removeHandler}
          >
            X
          </button>
        </span>
      </div>
    );
  });

  const inputLetterHandler = (e) => {
    setInputWord(e.target.value);
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchWord) {
          onSuggestHandler(searchWord);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setSearchWord(text);
    setActiveSuggestionIndex(-1);
    setSuggestions([]);
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        run === true
      ) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${text}`);
      } else if (
        userDetails.data.is_subscribe === false &&
        userDetails?.data.is_superuser === false
      ) {
        navigate("/plans");
      }
    } else {
      if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${text}`);
      } else {
        navigate("/login");
      }
    }
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchWord(text);
  };

  useEffect(() => {
    if (location.state?.showConfetti) {
      setConfetti(true);
      setTimeout(() => setConfetti(false), 2200);
    }
  }, [location.state]);

  const [categoryChangeFlag, setCategoryChangeFlag] = useState(false);

  const handleCategoryChange = (event) => {
    setCategoryChangeFlag(true);
    setCategory(event.target.value);
  };

  useEffect(() => {
    if (categoryChangeFlag) {
      navigate(`category/${category}`);
    }
  }, [category]);

  return (
    // FOR SEARCHING RHYMING WORDS-----------------------------------------------------
    <div className="search-main">
      {confetti && <Confetti gravity={0.5} />}
      <div className="search-main-content">
        Freestyle, Write Lyrics, &
        <div className="search-main-content-bold">Search For Rhymes</div>
      </div>
      <div className="search-card">
        <div className="search-content">
          <div className="search-input-suggestions">
            <Form className="d-flex justify-content" action="#">
              <input
                type="text"
                id="floatingInputSearch"
                placeholder="What rhymes with..."
                name="searchWord"
                className="rhymingWord-search"
                style={{ paddingLeft: "20px" }}
                autoComplete="off"
                enterKeyHint="go"
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestions([]);
                  }, 200);
                }}
                onFocus={() => {
                  if (searchWord) {
                    // restore suggestions if there's a search term
                    onChangeHandler(searchWord);
                  }
                }}
                value={searchWord}
                onChange={(e) => {
                  onChangeHandler(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
              <button
                type="submit"
                className="submit-button-search"
                onClick={() => searchGet()}
              >
                <span className="button-text">Find Rhymes</span>
                <span className="button-icon"> 😎 </span>{" "}
                {/* Magnifying glass icon */}
              </button>

              {/* </FloatingLabel> */}
            </Form>
            <div className="search-suggestionsBox">
              {suggestions &&
                suggestions.slice(0, 8).map((suggestion, i) => (
                  <div
                    key={i}
                    onClick={() => onSuggestHandler(suggestion)}
                    className={`suggestions justify-content-md-center dropdown ${
                      i === activeSuggestionIndex ? "hover" : ""
                    }`}
                  >
                    {suggestion}
                  </div>
                ))}
            </div>
          </div>

          {userDetails?.data?.is_superuser ? (
            <>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value={"alcohol_drugs"}>Alcohol/Drugs</MenuItem>
                    <MenuItem value={"art_culture_entertainment"}>
                      Art/Culture/Entertainment
                    </MenuItem>
                    <MenuItem value={"aspirational"}>Aspirational</MenuItem>
                    <MenuItem value={"country"}>Country</MenuItem>
                    <MenuItem value={"education_learning"}>
                      Education/Learning
                    </MenuItem>
                    <MenuItem value={"fashion"}>Fashion</MenuItem>
                    <MenuItem value={"food"}>Food</MenuItem>
                    <MenuItem value={"funny"}>Funny</MenuItem>
                    <MenuItem value={"gangster_rap_ghetto"}>
                      Gangster/Ghetto
                    </MenuItem>
                    <MenuItem value={"general"}>General</MenuItem>
                    <MenuItem value={"health_wellness"}>
                      Health/Wellness
                    </MenuItem>
                    <MenuItem value={"history"}>History</MenuItem>
                    <MenuItem value={"law_justice"}>Law/Justice</MenuItem>
                    <MenuItem value={"love"}>Love</MenuItem>
                    <MenuItem value={"money_work_career"}>
                      Money/Work/Career
                    </MenuItem>
                    <MenuItem value={"nature"}>Nature</MenuItem>
                    <MenuItem value={"philosophy_ideas"}>
                      Philosophy/Ideas
                    </MenuItem>
                    <MenuItem value={"politics"}>Politics</MenuItem>
                    <MenuItem value={"relationships"}>Relationships</MenuItem>
                    <MenuItem value={"religion"}>Religion</MenuItem>
                    <MenuItem value={"science"}>Science</MenuItem>
                    <MenuItem value={"sensual_sexual"}>Sensual/Sexual</MenuItem>
                    <MenuItem value={"sports"}>Sports</MenuItem>
                    <MenuItem value={"technology"}>Technology</MenuItem>
                    <MenuItem value={"travel_adventure"}>
                      Travel/Adventure
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Button onClick={() => navigate(`category/${category}`)}>
                Go
              </Button>
            </>
          ) : (
            ""
          )}

          {/* <div className="recently-searched">
            <strong>Recently Searched: &nbsp;</strong>
            <span
              onClick={() => searchRecent("Orange")}
              style={{ cursor: "pointer" }}
            >
              Orange,
            </span>
            &nbsp;
            <span
              onClick={() => searchRecent("Flow")}
              style={{ cursor: "pointer" }}
            >
              Flow,
            </span>
            &nbsp;
            <span
              onClick={() => searchRecent("Barack Obama")}
              style={{ cursor: "pointer" }}
            >
              Barack Obama
            </span>
          </div> */}

          {/* <div className="start-doing-rn">Start Doing Right Now</div>
          <div className="freestyle-write-buttons">
            <button className="freestyle-btn" onClick={getRandomWord}>
              <span className="btn-text">Freestyle 🎤</span>
              <span className="btn-icon">🎤</span>
            </button> */}
          {/* <button onClick={() => navigate("/write-song")}>Write ✍🏼</button> */}
          {/* </div> */}
          {/* <div className="search-words-phrases">
            {homeWords?.length > 0 || homePhrases?.length > 0 ? (
              <>
                {String(languageSelected) === "Spanish" ? (
                  <>
                    <div className="home_words_text">
                      <div>
                        <strong>Today's Words </strong>
                      </div>
                      <div className="search_words_grid">
                        {isMobile ? (
                          <>
                            {homeWords?.slice(0, 6).map((words) => (
                              <div>
                                <button
                                  className="home-words-phrases-button"
                                  onClick={() => searchRecent(words)}
                                >
                                  {words}
                                </button>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {homeWords?.slice(0, 9).map((words) => (
                              <div>
                                <button
                                  className="home-words-phrases-button"
                                  onClick={() => searchRecent(words)}
                                >
                                  {words}
                                </button>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : String(languageSelected) === "English" ? (                                                    
                  <>
                    <div className="home_words_text">
                      <div>
                        <strong>Today's Phrases </strong>
                      </div>

                      <div className="search_phrases_grid">
                        {isMobile ? (
                          <>
                            {homePhrases?.slice(0, 6).map((phrases) => (
                              <div>
                                <button
                                  className="home-words-phrases-button"
                                  onClick={() => searchRecent(phrases)}
                                >
                                  {phrases}
                                </button>
                              </div>
                            ))}                                                   
                          </>
                        ) : (
                          <>
                            {homePhrases?.slice(0, 9).map((phrases) => (
                              <div>
                                <button
                                  className="home-words-phrases-button"
                                  onClick={() => searchRecent(phrases)}
                                >
                                  {phrases}
                                </button>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}                                                                                               
          </div> */}

          {/* <div> */}
          {/* <img
              src={eggplant}
              alt="stumble"
              onClick={getRandomWord}
              className="stumble-image"
            /> */}
          {/* </div> */}
        </div>
      </div>
      {/* <div className="search_credits_timer" onClick={() => navigate("/plans")}>
        <p>Credits: {credits}</p>
        {timeLeft && <p>Resets in {displayTimeLeft()}</p>}
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default Search;
