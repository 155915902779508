import React, { useState } from "react";
import { Form } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { Button, IconButton } from "@mui/material";
import { useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useRef } from "react";

function Chatbox({ onSendMessage, character }) {
  const [message, setMessage] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const silenceTimer = useRef(null);
  const recognitionRef = useRef(null);

  const handleSend = () => {
    onSendMessage(transcript ? transcript : message);
    SpeechRecognition.stopListening();
    setMessage("");
    resetTranscript();
    setIsListening(false);
    console.log("listening false");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    adjustTextareaHeight(event.target);
  };

  const adjustTextareaHeight = (textarea) => {
    const maxHeight = 200; // Maximum height before scrolling in px
    const defaultHeight = "47px"; // Default height when textarea is empty

    textarea.style.overflowY = "hidden"; // Hide scrollbar initially

    if (textarea.value === "") {
      textarea.style.height = defaultHeight;
    } else {
      textarea.style.height = "auto"; // Shrink to content size
      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = "auto"; // Enable scrollbar
      } else {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  };

  const startListening = () => {
    setIsListening(true);
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }

    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      recognitionRef.current = recognition;

      recognition.lang = "en-US";
      recognition.continuous = true;

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        console.log(isListening);
        const newTranscript =
          event.results[event.results.length - 1][0].transcript;
        setMessage((prev) => prev + " " + newTranscript.trim());

        if (silenceTimer.current) {
          clearTimeout(silenceTimer.current);
        }

        silenceTimer.current = setTimeout(() => {
          console.log("inside timer ", isListening);
          if (isListening) {
            console.log("listening inside after 5 seconds");
            handleSend();
          }
        }, 5000);
      };
      recognition.onend = () => {
        setTimeout(() => setIsListening(false), 10000);  // Wait for 10 seconds
    };

      recognition.start();
    }
  };

  const toggleListen = () => {
    if (isListening) {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
      setIsListening(false);
      console.log("listening false");
    } else {
      startListening();
    }
  };

  useEffect(() => {
    adjustTextareaHeight(
      document.querySelector(".chatBox-textareaWrapper textarea")
    );
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return null;
    }
  }, [message, transcript]);

  useEffect(() => {
    setMessage(transcript);

    if (silenceTimer.current) {
      clearTimeout(silenceTimer.current);
    }

    // Set a new timer for 5 seconds (5000 milliseconds)
    silenceTimer.current = setTimeout(() => {
      if (isListening) {
        handleSend();
      }
    }, 5000);

    // Cleanup timer on component unmount
    return () => {
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
    };
  }, [transcript]);

  return (
    <div className="chatBox-main">
      <Form onSubmit={handleSend} action="#" className="chatBox-form">
        <div className="chatBox-textareaWrapper">
          <textarea
            value={message}
            onChange={handleInputChange}
            rows="1"
            onKeyDown={handleKeyPress}
            placeholder={`Say what's up to ${character}`}
          />
          <div className="chatbox-buttons">
            <IconButton className="chatbox-mic-button" onClick={toggleListen}>
              {isListening ? <MicIcon /> : <MicOffIcon />}
            </IconButton>
            <Button
              className="chatbox-send-button"
              type="submit"
              endIcon={<SendIcon />}
            ></Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Chatbox;
