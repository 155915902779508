import React, { useState, useContext } from "react";
import "./RapModal.css";
import { BASE_URL } from "../Api/api";
import AuthContext from "../../context/AuthContext";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import { Box, Modal } from "@mui/material";
import Icon from "react-icons-kit";
import { ic_keyboard_backspace } from "react-icons-kit/md/ic_keyboard_backspace";

const isMobile = window.innerWidth <= 425; //425 is the mobile max-width

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "28px",
  boxShadow: 24,
  p: 4,
  ...(isMobile && {
    // Mobile-specific styles go here:
    top: "60%",
    right: "-35%",
  }),
};

function RegenrateModal({
  words,
  show,
  setRap,
  title,
  id,
  songArray,
  setSongArray,
  setShow,
}) {
  let { authTokens, userDetails, setupTheUser } = useContext(AuthContext);
  const wordsInArray = words;
  const [rapLoading, setRapLoading] = useState(false);
  const [anyWord, setAnyWord] = useState("");
  const [song_lines, setSongLines] = useState([]);
  const [isLines, setIsLines] = useState(false);
  const [isNewSong, setIsNewSong] = useState(true);

  //CHATGPT RAP GENERATION--------------------------------------
  const [rapGenerated, setrapGenerated] = useState([]);
  const writeAccess = () => {
    if (userDetails?.data?.is_superuser === true) {
      return true;
    }
    let isAccess = false;
    if (userDetails?.data?.write_song_uses > 0) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails.data.is_email_verified === false) {
      return false;
    }
    return isAccess;
  };
  const premiumAlert = () => {
    swal("Please use premium version to write song");
    return;
  };
  let rapRegeneration = async (rap, list) => {
    if (writeAccess() === false) {
      premiumAlert();
      return;
    }
    setRapLoading(true);
    setSongLines([]);
    let items = { rap: rap, index: list };
    let response = await fetch(`${BASE_URL}/rap-regeneration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });

    let data = await response.json();
    if (response.ok) {
      setrapGenerated(data.rap_lines);
      setRapLoading(false);
      list = list.filter((word) => word !== anyWord);
      setAnyWord("");
      setIsNewSong(false);
      setupTheUser(authTokens.access);
    }
  };

  const anyWordHandler = (e) => {
    e.preventDefault();
    wordsInArray.push(anyWord);
    setAnyWord("");
    setIsLines(false);
    setSongLines([]);
  };

  const closeModal = () => {
    setSongLines([]);
    setShow(false);
  };

  const regenerateSongLineHandler = (e) => {
    let value = Number(e.target.getAttribute("data-index")) + 1;
    if (song_lines.includes(value)) {
      let new_arr = song_lines.filter((val) => val !== value);
      if (new_arr.length > 0) {
        setIsLines(true);
      } else {
        setIsLines(false);
      }
      setSongLines(new_arr);
    } else {
      song_lines.push(value);
      setSongLines((prev) => (prev = song_lines));
      setIsLines(true);
    }
  };

  const updateSong = async () => {
    let songTitle = title;
    if (songTitle == null) {
      songTitle = "";
    }
    const items = { song: songArray.join(","), title: title };
    console.log(items);
    let response = await fetch(`${BASE_URL}/edit-song/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    let data = await response.json();
    if (response.status === 200) {
      setShow(false);
      setRap(data.song);
    }
  };

  const songRegeneration = async () => {
    let songTitle = title;
    if (songTitle == null) {
      songTitle = "";
    }
    console.log(songArray);
    let rap_data = songArray.join(",");
    console.log(rap_data);
    const items = { lyrics: rap_data };
    let response = await fetch(`${BASE_URL}/song-regeneration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    let data = await response.json();
    if (response.status === 200) {
      setSongArray(data.final_lines);
      setIsNewSong(false);
    }
  };
  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Box sx={style}>
        <>
          {rapLoading ? (
            <div>
              <h4 className="modal_heading">Re-Writing Lyrics...</h4>
              <div className="wordPopDiv generated_song_div_loader">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="regeneratedRap-main">
              <button
                onClick={() => closeModal()}
                className="editSong-back-button"
              >
                <Icon icon={ic_keyboard_backspace} size={20} />
              </button>
              <h4 className="modal_heading">Re-Generate Your Song</h4>
              <div
                className="wordPopDiv generated_song_div display_block"
                id="song_div"
              >
                <div className="row">
                  {rapGenerated.length > 0 ? (
                    <>
                      {rapGenerated.map((line, index) => {
                        return (
                          <>
                            <p key={line} className="p-0 mt-0">
                              {line}
                            </p>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {songArray.map((line, index) => {
                        return (
                          <>
                            <p key={line} className="p-0 mt-0">
                              {line}
                            </p>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              {isNewSong ? (
                <>
                  <button
                    className="submit-button-song-pop"
                    onClick={() => songRegeneration()}
                  >
                    Re-Write Song
                  </button>
                </>
              ) : (
                <button
                  className="submit-button-song-pop"
                  onClick={() => updateSong()}
                >
                  Update Song
                </button>
              )}
            </div>
          )}
        </>
      </Box>
    </Modal>
  );
}

export default RegenrateModal;
