import React, { useEffect } from "react";
import "./AboutUs.css";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);
  return (
    <div className="aboutUs-main">
      <div className="container mt-5">
        <div className="aboutus1stpage">
          <div className="aboutus1page-left">
            <div className="pragma-title-aboutus"> Songs World</div>
            <div className="pragma-subtitle-aboutus">
              <span>Freestyle, Write, & Search Rhymes.</span>
            </div>
          </div>
        </div>

        <div className="aboutus-content">
          <div className="aboutus-content-heading mb-3">About Us</div>
          <div className="aboutus-more-content">
            Frustrated with traditional rhyme resources, Mr B reimagined a rhyme
            dictionary infused with compound rhymes, pop culture, and clever
            wordplay. Each entry was laboriously added by Mr B himself, forming
            the foundation of our secret sauce. Harnessing AI's power,
            Songs.World introduces a cutting-edge lyric generator. Taking cues
            from Mr B’s “Rap App”, it lets users freestyle over beats from
            various genres. Created by an artist, for fellow artists, this
            platform understands the subtleties often overlooked by regular tech
            offerings.
          </div>
        </div>
        <div className="links">
          Mr B’s music and socials:
          <div className="hyperlinks">
            <a href="https://twitter.com/MrBinspire">Twitter</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
