import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { BASE_URL } from "../Api/api";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Form } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from "@mui/material";

const CategoryResult = () => {
  const [categorizedWords, setCategorizedWords] = useState([]);
  let {
    searchedWords,
    userSearchCountPost,
    options,
    credits,
    setCredits,
    user,
    userDetails,
    languageSelected,
    setLanguageSelected,
    changeLanguage,
  } = useContext(AuthContext);

  const { wordFromURL } = useParams();
  const location = useLocation();
  const inputRef = useRef();
  const navigate = useNavigate();
  const wordsRef = useRef(null);
  const phrasesRef = useRef(null);

  const [tabsValue, setTabsValue] = useState(0);

  const tabsValueHandler = (event, newValue) => {
    setTabsValue(newValue);
  };

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  const emptySearchField = () => toast("Please enter a word");
  const [isPageRender, setisPageRender] = useState(true);

  const REACT_APP_API_BASE_URL_SPANISH = "https://api.rhymes.world/spanish";

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      if (user) {
        if (userDetails.data.is_subscribe || userDetails.data.is_superuser) {
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (credits > 0) {
          setCredits(credits - 1);
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (userDetails.data.is_subscribe === false) {
          navigate("/plans");
        }
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${searchingWordDup}`, {
          state: { isStumble: false },
        });
      } else if (credits === 0) {
        navigate("/login");
      }
      selectText();
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    if (user) {
      if (userDetails.data.is_superuser || userDetails.data.is_subscribe) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 0);
        navigate(`/search/${text}`);
      } else if (userDetails.data.is_subscribe === false) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      navigate(`/search/${text}`);
    } else if (credits === 0) {
      navigate("/login");
    }
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.id === "customCloseButton") {
        swal.close();
      }
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        credits > 0
      ) {
        if (
          userDetails.data.is_superuser === false &&
          userDetails.data.is_subscribe === false
        ) {
          setCredits(credits - 1);
        }

        // Call search API

        selectText();
        setSuggestions([]);
        setisPageRender(false);
        setsearchingWords([]);
        setsearchingphrases([]);
        setLoading(true);
        if (isMobile === true) {
          inputRef.current.blur();
        }
        if (toggleState === false) {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/get-clean-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          for (let i of result["words"]) {
            const splitArr = i.word
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                  String(i.word)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
          setLoading(false);
        } else {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/search-rhyming-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                setSuggestions([]);
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          if (wordFromURL === "Orange" || wordFromURL === "orange") {
            for (let i of result["words"]) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            }
          } else {
            for (let i of result["words"]) {
              const splitArr = String(i.word)
                .split(regex)
                .filter((word) => !/\p{Emoji}/u.test(word));
              if (splitArr.length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (String(i.word).split("-").length > 1) {
                  setsearchingphrases((prev) =>
                    [...prev, String(i.word)].sort()
                  );
                } else {
                  if (
                    wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                      String(i.word) &&
                    !(String(i.word) in searchingWords)
                  ) {
                    if (wordFromURL !== String(i.word)) {
                      setsearchingWords((prev) =>
                        [...prev, String(i.word)].sort()
                      );
                    }
                  }
                }
              }
            }
          }

          setLoading(false);
        }
      } else if (credits <= 0) {
        navigate("plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);

      // Call search API

      selectText();
      setSuggestions([]);
      setisPageRender(false);
      setsearchingWords([]);
      setsearchingphrases([]);
      setLoading(true);
      if (isMobile === true) {
        inputRef.current.blur();
      }
      if (toggleState === false) {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/get-clean-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        for (let i of result["words"]) {
          const splitArr = i.word
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          } else {
            if (String(i.word).split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                String(i.word)
              ) {
                if (wordFromURL !== String(i.word)) {
                  setsearchingWords((prev) => [...prev, String(i.word)].sort());
                }
              }
            }
          }
        }
        setLoading(false);
      } else {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/search-rhyming-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              setSuggestions([]);
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        if (wordFromURL === "Orange" || wordFromURL === "orange") {
          for (let i of result["words"]) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          }
        } else {
          for (let i of result["words"]) {
            const splitArr = String(i.word)
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                    String(i.word) &&
                  !(String(i.word) in searchingWords)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
        }

        setLoading(false);
      }
    } else if (credits <= 0) {
      navigate("/login");
    }
  };

  const [categoryWords, setCategoryWords] = useState([]);
  const [categoryPhrases, setCategoryPhrases] = useState([]);

  const [categoryChangeFlag, setCategoryChangeFlag] = useState(false);
  const [categorySelected, setCategorySelected] = useState("general");

  const { category } = useParams();


  useEffect(() => {
    let searchGet = async (e) => {
      let response = await fetch(
        `https://api.rhymes.world/get_category_words/${category}`
      );
      let result = await response.json();
      if (response.ok) {
        setCategoryPhrases([])
        setCategoryWords([])
        console.log(result);
        if (result.Words?.length > 0) {
          for (let i of result.Words) {
            const splitArr = String(i.word)
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setCategoryPhrases((prev) => [...prev, i]);
            } else {
              if (String(i.word).split("-").length > 1) {
                setCategoryPhrases((prev) => [...prev, i.word]);
              } else {
                setCategoryWords((prev) => [...prev, i.word]);
              }
            }
          }
        }
        setLoading(false);
      } else {
        console.log("something went wrong");
      }
    };
    searchGet();
  }, [categorySelected,category]);

  useEffect(() => {
    console.log(categorizedWords);
    console.log(categoryWords);
    console.log(categoryPhrases);
  }, [categorizedWords, categoryPhrases, categoryWords]);


  const categorySearchHandler = (text) => {
    if (user) {
      if (userDetails.data.is_superuser || userDetails.data.is_subscribe) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 0);
        navigate(`/search/${text}`);
      } else if (userDetails.data.is_subscribe === false) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      navigate(`/search/${text}`);
    } else if (credits === 0) {
      navigate("/login");
    }
  };

  const handleCategoryChange = (event) => {
    setCategoryChangeFlag(true);
    setCategoryPhrases([]);
    setCategoryWords([]);
    setCategorySelected(event.target.value);
  };

  useEffect(() => {
    if (categoryChangeFlag) {
      navigate(`/category/${categorySelected}`);          
    }                                                   
  }, [categorySelected]);
  return (
    <div className={"after-search-main"}>
      <div className={"after-search"}>
        <div className="after-search-fields">
          <div className="search-field">
            <Form
              onSubmit={searchSubmitHandler}
              className="d-flex justify-cotent"
            >
              <input
                type="text"
                id="afterSearchInput"
                placeholder="What rhymes with..."
                name="searchWord"
                className="rhymingWord-Aftersearch searchInput"
                style={{ paddingLeft: "20px" }}
                ref={inputRef}
                autoFocus
                value={searchingWordDup}
                enterKeyHint="Go"
                autoComplete="off"
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestions([]);
                  }, 200);
                }}
                onFocus={() => {
                  if (searchingWordDup) {
                    // restore suggestions if there's a search term
                    onChangeHandler(searchingWordDup);
                  }
                }}
                onChange={(e) => {
                  onChangeHandler(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
              <button
                type="submit"
                className="submit-button-afterSearch"
                // onClick={searchGet}
              >
                <span className="afterSearch-button-text">Find Rhymes</span>
                <span className="afterSearch-button-icon">😎</span>{" "}
                {/* Magnifying glass icon */}
              </button>

              <input type="submit" style={{ display: "none" }} />
            </Form>

            <div className="afterSearch-suggestionsBox">
              {suggestions &&
                suggestions.slice(0, 8).map((suggestion, i) => (
                  <div
                    key={i}
                    onClick={() => onSuggestHandler(suggestion)}
                    className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                      i === activeSuggestionIndex ? "hover" : ""
                    }`}
                  >
                    {suggestion}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="aftersearch-content">
        {loading ? (
          <div className="afterSearch-loader">
            <Loader />
          </div>
        ) : (
          <div>
            {categoryWords.length > 0 || categoryPhrases.length > 0 ? (
              <>
                <h1 className="word-search">
                  <div className="search-results-for-param">
                    <div>
                      <span className="search-result-for-text">
                        Rhymes for{" "}
                      </span>
                      <span>
                        <strong className={"word-search"}>
                          "{category[0].toUpperCase() + category.substring(1)}"
                        </strong>
                      </span>
                      <span>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={categorySelected}
                              label="Category"
                              onChange={handleCategoryChange}
                            >
                              <MenuItem value={"alcohol_drugs"}>
                                Alcohol/Drugs
                              </MenuItem>
                              <MenuItem value={"art_culture_entertainment"}>
                                Art/Culture/Entertainment
                              </MenuItem>
                              <MenuItem value={"aspirational"}>
                                Aspirational          
                              </MenuItem>
                              <MenuItem value={"country"}>Country</MenuItem>
                              <MenuItem value={"education_learning"}>
                                Education/Learning
                              </MenuItem>
                              <MenuItem value={"fashion"}>Fashion</MenuItem>
                              <MenuItem value={"food"}>Food</MenuItem>
                              <MenuItem value={"funny"}>Funny</MenuItem>
                              <MenuItem value={"gangster_rap_ghetto"}>
                                Gangster/Ghetto
                              </MenuItem>
                              <MenuItem value={"general"}>General</MenuItem>
                              <MenuItem value={"health_wellness"}>
                                Health/Wellness
                              </MenuItem>
                              <MenuItem value={"history"}>History</MenuItem>
                              <MenuItem value={"law_justice"}>
                                Law/Justice
                              </MenuItem>
                              <MenuItem value={"love"}>Love</MenuItem>
                              <MenuItem value={"money_work_career"}>
                                Money/Work/Career
                              </MenuItem>
                              <MenuItem value={"nature"}>Nature</MenuItem>
                              <MenuItem value={"philosophy_ideas"}>
                                Philosophy/Ideas
                              </MenuItem>
                              <MenuItem value={"politics"}>Politics</MenuItem>
                              <MenuItem value={"relationships"}>
                                Relationships
                              </MenuItem>
                              <MenuItem value={"religion"}>Religion</MenuItem>
                              <MenuItem value={"science"}>Science</MenuItem>
                              <MenuItem value={"sensual_sexual"}>
                                Sensual/Sexual
                              </MenuItem>
                              <MenuItem value={"sports"}>Sports</MenuItem>
                              <MenuItem value={"technology"}>
                                Technology
                              </MenuItem>
                              <MenuItem value={"travel_adventure"}>
                                Travel/Adventure
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Button
                          onClick={() => navigate(`category/${category}`)}
                        >
                          Go
                        </Button>
                      </span>
                    </div>
                  </div>
                </h1>
                <div className="afterSearch-arrows-results">
                  <div className="words-and-phrases" data-mcs-theme="dark">
                    <TabContext value={tabsValue}>
                      <TabList onChange={tabsValueHandler}>
                        <Tab
                          label="Phrases"
                          disabled={categoryPhrases.length === 0}
                          sx={{
                            "&.Mui-selected": {
                              background: "white",
                              color: "black",
                              fontWeight: 600,
                            },
                            borderTopLeftRadius: "16px",
                            width: "133px",
                            borderTopRightRadius: "16px",
                          }}
                          value={0}
                        />
                        <Tab
                          label="Words"
                          disabled={categoryWords.length === 0}
                          sx={{
                            "&.Mui-selected": {
                              background: "white",
                              fontWeight: 600,
                              color: "black",
                            },
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            width: "133px",
                          }}
                          value={1}
                        />
                      </TabList>

                      {/* </Tabs> */}
                      <div>
                        <TabPanel value={0} sx={{ padding: 0 }}>
                          {categoryPhrases.length > 0 ? (
                            <div>
                              <div ref={phrasesRef}>
                                <div>
                                  <div className="search-results">
                                    {categoryPhrases
                                      .sort((a, b) =>
                                        `${a.word}`.localeCompare(b.word, "es")
                                      )
                                      .map((curElem, index) => (
                                        <div
                                          className="PHRASE"
                                          key={curElem.id}
                                        >
                                          <div
                                            onClick={() =>
                                              categorySearchHandler(
                                                curElem.word
                                              )
                                            }
                                          >
                                            {curElem.word}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </TabPanel>
                      </div>
                      <div>
                        <TabPanel value={1} sx={{ padding: 0 }}>
                          {categoryWords.length > 0 ? (
                            <div>
                              <div ref={wordsRef}>
                                <div className="search-results">
                                  {categoryWords
                                    .sort((a, b) =>
                                      `${a}`.localeCompare(b, "es")
                                    )
                                    .map((curElem, index) => {
                                      return (
                                        <div className="WORD" key={curElem}>
                                          <div
                                            onClick={() =>
                                              categorySearchHandler(curElem)
                                            }
                                          >
                                            {curElem}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </TabPanel>
                      </div>
                    </TabContext>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h1 className="word-search no-rhymes-search">
                  "{category[0].toUpperCase() + category.substring(1)}"
                </h1>

                <div
                  className="words-and-phrases mCustomScrollbar no-rhymes-search-text"
                  data-mcs-theme="dark"
                ></div>
              </>
            )}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default CategoryResult;
