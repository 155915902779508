import React from "react";
import "../SongPage/WriteSongPage.css";

const WriteSongPage = () => {
  return (
    <div className="write-song-main">
      <input type="text" className="write-song-textarea" />
    </div>
  );
};

export default WriteSongPage;
