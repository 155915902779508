import React, { createContext, useContext, useEffect, useState } from "react";

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const value = {
    run,
    stepIndex,
    isTourActive,
    setRun,
    setStepIndex,
    setIsTourActive,
  };

  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};
