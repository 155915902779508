import React, { useState, useContext, useEffect } from "react";
import { BASE_URL } from "../Api/api";
import { async } from "q";
import "./CheckoutPage.css";
import AuthContext from "../../context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Form } from "react-bootstrap";

function Checkout() {
  const [plans, setPlans] = useState([]);
  const [PRICE_LOOKUP_KEY, SET_PRICE_LOOKUP_KEY] = useState("boom!");
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const navigate = useNavigate();
  let { user, authTokens, setupTheUser } = useContext(AuthContext);
  useEffect(() => {
    console.log("inside checkout page");
    let getPlans = async () => {
      let response = await fetch(`${BASE_URL}/plans/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      console.log("DATA CHECKOUT", data);
      if (response.status === 200) {
        setPlans(data?.plans);
        setIsSubscribe(data?.is_subscribe);
        setPlanDetails(data?.plan_details);
        setupTheUser(authTokens?.access);
      }
    };
    getPlans();
  }, []);
  const handleCheckout = async (e, price_id) => {
    e.preventDefault();
    if (user !== null) {
      console.log(user, authTokens.access);
      let items = { lookup_key: price_id };
      let response = await fetch(`${BASE_URL}/plans/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authTokens.access,
        },
        body: JSON.stringify(items),
      });
      let data = await response.json();
      if (response.status === 200) {
        window.location = data.url;
      }
    } else {
      navigate("/sign-up");
    }
  };
  const handleBillingDetails = async (e) => {
    e.preventDefault();
    let response = await fetch(`${BASE_URL}/plans/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: {},
    });
    let data = await response.json();
    if (response.status === 200) {
      window.location = data.url;
    }
  };

  const { wordFromURL } = useParams();
  const location = useLocation();
  const inputRef = useRef();

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  const emptySearchField = () => toast("Please enter a word");
  const [isPageRender, setisPageRender] = useState(true);
  let {
    searchedWords,
    userSearchCountPost,
    options,
    userDetails,
    credits,
    setCredits,
    languageSelected,
    rapGenerated,
    isWriteSongBtnClicked,
    setIsWriteSongBtnClicked,
    isRapSuccessFullyGenerated,
  } = useContext(AuthContext);

  const REACT_APP_API_BASE_URL_SPANISH = "https://api.rhymes.world/spanish";

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      if (user) {
        if (userDetails.data.is_subscribe || userDetails.data.is_superuser) {
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (credits > 0) {
          setCredits(credits - 1);
          navigate(`/search/${searchingWordDup}`, {
            state: { isStumble: false },
          });
        } else if (userDetails.data.is_subscribe === false) {
          navigate("/plans");
        }
      } else if (credits > 0) {
        setCredits(credits - 1);
        navigate(`/search/${searchingWordDup}`, {
          state: { isStumble: false },
        });
      } else if (credits === 0) {
        navigate("/login");
      }
      selectText();
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    if (user) {
      if (userDetails.data.is_superuser || userDetails.data.is_subscribe) {
        navigate(`/search/${text}`);
      } else if (credits > 0) {
        setCredits(credits - 0);
        navigate(`/search/${text}`);
      } else if (userDetails.data.is_subscribe === false) {
        navigate("/plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);
      navigate(`/search/${text}`);
    } else if (credits === 0) {
      navigate("/login");
    }
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.id === "customCloseButton") {
        swal.close();
      }
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    if (user) {
      if (
        userDetails.data.is_superuser ||
        userDetails.data.is_subscribe ||
        credits > 0
      ) {
        if (
          userDetails.data.is_superuser === false &&
          userDetails.data.is_subscribe === false
        ) {
          setCredits(credits - 1);
        }

        // Call search API

        selectText();
        setSuggestions([]);
        setisPageRender(false);
        setsearchingWords([]);
        setsearchingphrases([]);
        setLoading(true);
        if (isMobile === true) {
          inputRef.current.blur();
        }
        if (toggleState === false) {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/get-clean-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          for (let i of result["words"]) {
            const splitArr = i.word
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                  String(i.word)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
          setLoading(false);
        } else {
          let response = await fetch(
            `${
              languageSelected === "English"
                ? BASE_URL
                : REACT_APP_API_BASE_URL_SPANISH
            }/search-rhyming-words/${wordFromURL}`
          );
          let result = await response.json();
          if (response.ok) {
            setSearchingArr(result);
            let username = "AnonymousUser";
            if (user) {
              username = user.username;
              userSearchCountPost(user.username);
            }
            if (location.state) {
              if (location.state.isStumble !== true) {
                setSuggestions([]);
                searchedWords({ word: wordFromURL, user: username });
              }
            } else {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            console.log("something went wrong");
          }
          if (wordFromURL === "Orange" || wordFromURL === "orange") {
            for (let i of result["words"]) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            }
          } else {
            for (let i of result["words"]) {
              const splitArr = String(i.word)
                .split(regex)
                .filter((word) => !/\p{Emoji}/u.test(word));
              if (splitArr.length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (String(i.word).split("-").length > 1) {
                  setsearchingphrases((prev) =>
                    [...prev, String(i.word)].sort()
                  );
                } else {
                  if (
                    wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                      String(i.word) &&
                    !(String(i.word) in searchingWords)
                  ) {
                    if (wordFromURL !== String(i.word)) {
                      setsearchingWords((prev) =>
                        [...prev, String(i.word)].sort()
                      );
                    }
                  }
                }
              }
            }
          }

          setLoading(false);
        }
      } else if (credits <= 0) {
        navigate("plans");
      }
    } else if (credits > 0) {
      setCredits(credits - 1);

      // Call search API

      selectText();
      setSuggestions([]);
      setisPageRender(false);
      setsearchingWords([]);
      setsearchingphrases([]);
      setLoading(true);
      if (isMobile === true) {
        inputRef.current.blur();
      }
      if (toggleState === false) {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/get-clean-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        for (let i of result["words"]) {
          const splitArr = i.word
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          } else {
            if (String(i.word).split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !==
                String(i.word)
              ) {
                if (wordFromURL !== String(i.word)) {
                  setsearchingWords((prev) => [...prev, String(i.word)].sort());
                }
              }
            }
          }
        }
        setLoading(false);
      } else {
        let response = await fetch(
          `${
            languageSelected === "English"
              ? BASE_URL
              : REACT_APP_API_BASE_URL_SPANISH
          }/search-rhyming-words/${wordFromURL}`
        );
        let result = await response.json();
        if (response.ok) {
          setSearchingArr(result);
          let username = "AnonymousUser";
          if (user) {
            username = user.username;
            userSearchCountPost(user.username);
          }
          if (location.state) {
            if (location.state.isStumble !== true) {
              setSuggestions([]);
              searchedWords({ word: wordFromURL, user: username });
            }
          } else {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          console.log("something went wrong");
        }
        if (wordFromURL === "Orange" || wordFromURL === "orange") {
          for (let i of result["words"]) {
            setsearchingphrases((prev) => [...prev, String(i.word)].sort());
          }
        } else {
          for (let i of result["words"]) {
            const splitArr = String(i.word)
              .split(regex)
              .filter((word) => !/\p{Emoji}/u.test(word));
            if (splitArr.length > 1) {
              setsearchingphrases((prev) => [...prev, String(i.word)].sort());
            } else {
              if (String(i.word).split("-").length > 1) {
                setsearchingphrases((prev) => [...prev, String(i.word)].sort());
              } else {
                if (
                  wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !==
                    String(i.word) &&
                  !(String(i.word) in searchingWords)
                ) {
                  if (wordFromURL !== String(i.word)) {
                    setsearchingWords((prev) =>
                      [...prev, String(i.word)].sort()
                    );
                  }
                }
              }
            }
          }
        }

        setLoading(false);
      }
    } else if (credits <= 0) {
      navigate("/login");
    }
  };
  return (
    <div className="plans_body">
      <div className="search-field">
        <Form
          onSubmit={searchSubmitHandler}
          action="#"
          className="d-flex justify-cotent"
        >
          <input
            type="text"
            id="afterSearchInput"
            placeholder="Rhyming Word"
            name="searchWord"
            className="rhymingWord-Aftersearch searchInput"
            style={{ paddingLeft: "20px" }}
            ref={inputRef}
            autoFocus
            value={searchingWordDup}
            enterKeyHint="Go"
            autoComplete="off"
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
              }, 200);
            }}
            onFocus={() => {
              if (searchingWordDup) {
                // restore suggestions if there's a search term
                onChangeHandler(searchingWordDup);
              }
            }}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <button
            type="submit"
            className="submit-button-afterSearch"
            onClick={searchGet}
          >
            Find Rhymes
          </button>
          <input type="submit" style={{ display: "none" }} />
        </Form>

        <div className="afterSearch-suggestionsBox">
          {suggestions &&
            suggestions.slice(0, 8).map((suggestion, i) => (
              <div
                key={i}
                onClick={() => onSuggestHandler(suggestion)}
                className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                  i === activeSuggestionIndex ? "hover" : ""
                }`}
              >
                {suggestion}
              </div>
            ))}
        </div>
      </div>
      <div className="plans_main p-5">
        <div>
          {userDetails.data.is_subscribe === false ? (
            <>
              {plans.slice(0, 1).map((data) => {
                return (
                  <div key={data.price_id}>
                    <div className="plans">
                      <span className="plans_div">
                        <h6>{data.name}</h6>
                        <p>{data.description}</p>
                        <h5 className="price_h">
                          {/* <i className="">$</i> */}
                          <span>
                            {data.amount} / {data.interval}
                          </span>
                        </h5>
                        <form
                          method="POST"
                          onSubmit={(e) => handleCheckout(e, data.price_id)}
                        >
                          <input
                            type="hidden"
                            id="session-id"
                            name="lookup_key"
                            value={PRICE_LOOKUP_KEY}
                          />
                          <button
                            className="checkout_btn mt-3"
                            id="checkout-and-portal-button"
                            type="submit"
                          >
                            Choose
                          </button>
                        </form>
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="col-md-12">
              <div className="plans">
                <span className="plans_div">
                  <h5>Your Plan Details</h5>
                  <hr></hr>
                  <h6>{plans[0]?.name}</h6>
                  <p>{plans[0]?.description}</p>
                  <h5>
                    <i className="">$</i>
                    <span>
                      {plans[0]?.amount} / {plans[0]?.interval}
                    </span>
                  </h5>
                  {/* <button
                    className="btn btn-primary checkout_btn mt-3"
                    type="button"
                    onClick={handleBillingDetails}
                  >
                    Manage Billing Details
                  </button> */}
                </span>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;
