import React, { useRef } from "react";
import "../Settings/Settings.css";
import "./UserProfile.css";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import FirstSearch from "../../assets/images/first-search.png";
import FirstAccepted from "../../assets/images/first-accepted.png";
import FirstRejected from "../../assets/images/first-rejected.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserDetailsUpdateModal from "../Settings/UserDetailsUpdateModal";
import UserBadgesModal from "../Settings/UserBadgesModal";
import Blurry from "../../assets/images/Blurry_2-1.png";
import swal from "sweetalert";
import { BASE_URL } from "../Api/api";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const UserProfile = () => {
  const { user, userDetails, setLanguageSelected, changeLanguage } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [userSearchedCount, setUserSearchedCount] = useState();
  const [userAcceptedCount, setUserAcceptedCount] = useState();
  const [userRejectedCount, setUserRejectedCount] = useState();
  const [searchCount, setSearchCount] = useState();
  const [acceptedCount, setAcceptedCount] = useState();
  const [rejectedCount, setRejectedCount] = useState();
  const [badgeType, setBadgeType] = useState();
  const [openUserUpdateModal, setOpenUserUpdateModal] = useState(false);
  const [openUserBadgeModal, setOpenUserBadgeModal] = useState(false);

  const { wordFromURL } = useParams();
  const location = useLocation();
  const inputRef = useRef();

  const [searchingArr, setSearchingArr] = useState([]);
  const [searchingWords, setsearchingWords] = useState([]);
  const [searchingphrases, setsearchingphrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingWordDup, setSearchingWordDup] = useState("");
  const isMobile = window.innerWidth <= 425 ? true : false; //425 is the mobile max-width
  const emptySearchField = () => toast("Please enter a word");
  const [isPageRender, setisPageRender] = useState(true);
  let {
    searchedWords,
    userSearchCountPost,
    options,
    loadWords,
    submittedRhymesStatus,
    pending,
    rejecteds,
    accepteds,
    setPending,
    setRejecteds,
    setAccepteds,
  } = useContext(AuthContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    { id: "Accepted", label: "Accepted", minWidth: 170 },
    { id: "Rejected", label: "Rejected", minWidth: 170 },
    {
      id: "Pending",
      label: "Pending",
      minWidth: 170,
    },
  ];
  function createData(Accepted, Rejected, Pending) {
    return { Accepted, Rejected, Pending };
  }

  const rows =
    accepteds.length > 0
      ? accepteds.map((accepted, index) =>
          createData(
            accepted?.word,
            rejecteds[index]?.word,
            pending[index]?.word
          )
        )
      : rejecteds.length > 0
      ? rejecteds.map((rejected, index) =>
          createData(
            accepteds[index]?.word,
            rejected?.word,
            pending[index]?.word
          )
        )
      : pending.map((pending, index) =>
          createData(
            accepteds[index]?.word,
            rejecteds[index]?.word,
            pending?.word
          )
        );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    submittedRhymesStatus({ user: user.username });
    console.log(pending, rejecteds, accepteds);
  }, [setPending, setRejecteds, setAccepteds]);

  const selectText = () => {
    if (isMobile === false) {
      var inputText = document.getElementById("afterSearchInput");
      inputText.focus();
      inputText.select();
    }
  };

  const searchSubmitHandler = (e) => {
    // e.preventDefault();
    selectText();
    setSuggestions([]);
    if (searchingWordDup === "") {
      emptySearchField();
    } else {
      navigate(`/search/${searchingWordDup}`, { state: { isStumble: false } });
      selectText();
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex + 1;
          if (nextIndex >= suggestions.length) {
            nextIndex = suggestions.length - 1;
          }
          if (nextIndex > 7) {
            nextIndex = 7;
          }
          return nextIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          let nextIndex = prevIndex - 1;
          if (nextIndex < 0) {
            nextIndex = 0;
          }
          return nextIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        setsearchingWords([]);
        setsearchingphrases([]);
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < suggestions.length
        ) {
          onSuggestHandler(suggestions[activeSuggestionIndex]);
        } else if (searchingWordDup) {
          onSuggestHandler(searchingWordDup);
        }
        // clear the suggestions when Enter is pressed
        setSuggestions([]);
      }
    }
  };

  const onSuggestHandler = (text) => {
    setActiveSuggestionIndex(-1);
    setSearchingWordDup(text);
    setSuggestions([]);
    setsearchingWords([]);
    setsearchingphrases([]);
    navigate(`/search/${text}`);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = options.filter((opt) => {
        const regex = new RegExp(`^${text}`, "i");
        return opt.match(regex);
      });
      matches.sort((a, b) => a.length - b.length);
    }
    setSuggestions(matches);
    setSearchingWordDup(text);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.id === "customCloseButton") {
        swal.close();
      }
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const regex = new RegExp(/\s+/);

  const searchGet = async (toggleState) => {
    selectText();
    setSuggestions([]);
    setisPageRender(false);
    setsearchingWords([]);
    setsearchingphrases([]);
    setLoading(true);
    if (isMobile === true) {
      inputRef.current.blur();
    }
    if (toggleState === false) {
      let response = await fetch(`${BASE_URL}/get-clean-words/${wordFromURL}`);
      let result = await response.json();
      if (response.ok) {
        setSearchingArr(result);
        // setSlantWords(result.slant_words);
        let username = "AnonymousUser";
        if (user) {
          username = user.username;
          userSearchCountPost(user.username);
        }
        if (location.state) {
          if (location.state.isStumble !== true) {
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          searchedWords({ word: wordFromURL, user: username });
        }
      } else {
        console.log("something went wrong");
      }
      for (let i of result["words"]) {
        const splitArr = i
          .split(regex)
          .filter((word) => !/\p{Emoji}/u.test(word));
        if (splitArr.length > 1) {
          setsearchingphrases((prev) => [...prev, i].sort());
        } else {
          if (i.split("-").length > 1) {
            setsearchingphrases((prev) => [...prev, i].sort());
          } else {
            if (wordFromURL[0].toUpperCase() + wordFromURL.substr(1) !== i) {
              if (wordFromURL !== i) {
                setsearchingWords((prev) => [...prev, i].sort());
              }
            }
          }
        }
      }
      setLoading(false);
    } else {
      let response = await fetch(
        `${BASE_URL}/search-rhyming-words/${wordFromURL}`
      );
      let result = await response.json();
      if (response.ok) {
        setSearchingArr(result);
        // setSlantWords(result.slant_words);
        let username = "AnonymousUser";
        if (user) {
          username = user.username;
          userSearchCountPost(user.username);
        }
        if (location.state) {
          if (location.state.isStumble !== true) {
            setSuggestions([]);
            searchedWords({ word: wordFromURL, user: username });
          }
        } else {
          searchedWords({ word: wordFromURL, user: username });
        }
      } else {
        console.log("something went wrong");
      }
      if (wordFromURL === "Orange" || wordFromURL === "orange") {
        console.log(searchingArr);
        setsearchingphrases(result.words);
      } else {
        for (let i of result["words"]) {
          const splitArr = i
            .split(regex)
            .filter((word) => !/\p{Emoji}/u.test(word));
          if (splitArr.length > 1) {
            setsearchingphrases((prev) => [...prev, i].sort());
          } else {
            if (i.split("-").length > 1) {
              setsearchingphrases((prev) => [...prev, i].sort());
            } else {
              if (
                wordFromURL[0].toUpperCase() + wordFromURL.substring(1) !== i &&
                !(i in searchingWords)
              ) {
                if (wordFromURL !== i) {
                  setsearchingWords((prev) => [...prev, i].sort());
                }
              }
            }
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <div className="settings-body">
      <div className="search-field">
        <Form
          onSubmit={searchSubmitHandler}
          action="#"
          className="d-flex justify-cotent"
        >
          <input
            type="text"
            id="afterSearchInput"
            placeholder="Rhyming Word"
            name="searchWord"
            className="rhymingWord-Aftersearch searchInput"
            style={{ paddingLeft: "20px" }}
            ref={inputRef}
            autoFocus
            value={searchingWordDup}
            enterKeyHint="Go"
            autoComplete="off"
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
              }, 200);
            }}
            onFocus={() => {
              if (searchingWordDup) {
                // restore suggestions if there's a search term
                onChangeHandler(searchingWordDup);
              }
            }}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <button
            type="submit"
            className="submit-button-afterSearch"
            onClick={searchGet}
          >
            Find Rhymes
          </button>
          <input type="submit" style={{ display: "none" }} />
        </Form>

        <div className="afterSearch-suggestionsBox">
          {suggestions &&
            suggestions.slice(0, 8).map((suggestion, i) => (
              <div
                key={i}
                onClick={() => onSuggestHandler(suggestion)}
                className={`AfterSearch-suggestions justify-content-md-center dropdown ${
                  i === activeSuggestionIndex ? "hover" : ""
                }`}
              >
                {suggestion}
              </div>
            ))}
        </div>
      </div>
      <div className="profile-main">
        <div className="profile-user-details">
          <div className="profile-user">
            <div className="profile-user-titles">
              <div className="py-1">Username:</div>
              <div className="py-1"> Email Address:</div>
            </div>
            <div className="profile-user-username-email">
              <div className="py-1">{user.username}</div>
              <div className="py-1">{user.email} </div>
            </div>
          </div>
          <button
            className="profile-user-update-button"
            onClick={() => {
              setOpenUserUpdateModal(true);
            }}
          >
            Edit Details
          </button>
        </div>
        <div className="profile-password-change">
          <div className="profile-password">
            <div>Password:</div>
            <div>**********</div>
          </div>
          {/* <button className="profile-password-change-button">
            Change Password
          </button> */}
        </div>
        <div className="words_status">
          {accepteds.length > 0 ||
          rejecteds.length > 0 ||
          pending.length > 0 ? (
            <>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </>
          ) : (
            <>Submit some rhymes now!</>
          )}
        </div>

        <UserDetailsUpdateModal
          open={openUserUpdateModal}
          setOpen={setOpenUserUpdateModal}
          username={user.username}
          email={user.email}
        />

        <UserBadgesModal
          open={openUserBadgeModal}
          setOpen={setOpenUserBadgeModal}
          modalType={badgeType}
        />
      </div>
    </div>
  );
};

export default UserProfile;
