import "./RapModal.css";
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "styled-components";
import AuthContext from "../../context/AuthContext";
import GeneratedRapModal from "../CustomModal/GeneratedRapModal";
import HarryPotter from "../../assets/images/harryPotter.png";
import SnoopDogg from "../../assets/images/snoopDogg.png";
import Shakerpeare from "../../assets/images/Shakespeare.png";
import Cleopatra from "../../assets/images/Cleopatra.png";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../Loader/Loader";

const isMobileMedium = window.innerWidth <= 375; //425 is the mobile max-width
const isMobileLarge = window.innerWidth <= 425 && window.innerWidth >= 375; //425 is the mobile max-width
const isMobile = window.innerWidth <= 425;
// .MuiGrid-item
const StyLedGridItem = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  innerWidth: "inherit",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "28px",
  boxShadow: 24,
  p: 4,
  ...(isMobile && {
    // Mobile-specific styles go here:
    top: "50%",
    right: "-35%",
    p: 1,
  }),
};

export default function RapModal({
  open,
  setOpen,
  removeHandler,
  clearClickedArray,
  words,
}) {
  const [rapClicked, setRapClicked] = useState(false);
  const [gptLoading, setGptLoading] = useState(false);
  const [countryClicked, setCountryClicked] = useState(false);
  const [popClicked, setPopClicked] = useState(false);
  const [balladClicked, setBalladClicked] = useState(false);
  const [isLines, setIsLines] = useState(false);
  const [rapLines, setRapLines] = useState("");
  const [songType, setSongType] = useState("Rap");
  const [anyWord, setAnyWord] = useState("");
  const [song_lines, setSongLines] = useState([]);
  const [openGeneratedRapModal, setOpenGeneratedRapModal] = useState(false);

  const legends = [
    "None",
    "Harry Potter",
    "Snoop Dogg",
    "Shakespeare",
    "Cleopatra",
  ];
  const genres = ["Rap", "Pop", "Country", "Ballad", "Punk"];
  const {
    rapGeneration,
    userDetails,
    setIsWriteSongBtnClicked,
    IsRapSuccessFullyGenerated,
  } = useContext(AuthContext);
  const handleClose = () => {
    console.log("Handle close called");
    setOpen(false);
    setGptLoading(false);
  };

  const [genre, setGenre] = useState("Rap");
  const [legend, setLegend] = useState("None");

  const handleGenreChange = (event) => {
    setGenre(event.target.value);
  };

  const handleLegendChange = (event) => {
    setLegend(event.target.value);
  };

  const noWords = () =>
    toast("Select rhymes to generate a song", {
      toastId: "centered-toast",
      className: "Toastify__toast--centered",
      autoClose: 1000,
      onClose: () => handleClose(),
    });

  const writeAccess = () => {
    if (userDetails?.data?.is_superuser === true) {
      return true;
    }
    let isAccess = false;
    if (userDetails?.data?.write_song_uses > 0) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data.is_email_verified === false) {
      return false;
    }
    return isAccess;
  };

  const SongTypeHandler = (value) => {
    let songType = value;
    setSongType(songType);
    if (songType === "Rap") {
      setRapClicked(true);
      setBalladClicked(false);
      setPopClicked(false);
      setCountryClicked(false);
    } else if (songType === "Country Song") {
      setRapClicked(false);
      setBalladClicked(false);
      setPopClicked(false);
      setCountryClicked(true);
    } else if (songType === "Pop Song") {
      setRapClicked(false);
      setBalladClicked(false);
      setPopClicked(true);
      setCountryClicked(false);
    } else {
      setRapClicked(false);
      setBalladClicked(true);
      setPopClicked(false);
      setCountryClicked(false);
    }
  };

  const anyWordHandler = (e) => {
    e.preventDefault();
    words.push(anyWord);
    setAnyWord("");
    setIsLines(false);
    setSongLines([]);
  };

  const rapGenerationHandler = () => {
    // setOpen(false);
    setGptLoading(true);
    rapGeneration(words, genre, anyWord);
    setIsWriteSongBtnClicked(true);
  };

  useEffect(() => {
    console.log(IsRapSuccessFullyGenerated);
    if (IsRapSuccessFullyGenerated) {
      console.log("rap is generated");
      setOpen(false);
      // setOpenGeneratedRapModal(true);
    } else if (IsRapSuccessFullyGenerated === false) {
      console.log("song not generated");
    }
  }, [IsRapSuccessFullyGenerated, setOpen]);

  return (
    <div>
      {words.length > 0 ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box sx={style}>
              {gptLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <div>
                    <button
                      onClick={handleClose}
                      className="close-button-gptModal"
                    >
                      x
                    </button>
                    <h4 className="modal_heading">Write Your Song</h4>
                    <>
                      <Box
                        width="100%"
                        display={"flex"}
                        alignItems="center"
                        marginTop={isMobile ? "5px" : "20px"}
                      >
                        <FormControl
                          fullWidth
                          sx={{ alignItems: "center", alignContent: "center" }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={
                              isMobileMedium
                                ? { marginLeft: "0px" }
                                : isMobileLarge
                                ? { marginLeft: "20px" }
                                : { marginLeft: "60px" }
                            }
                          >
                            Genre
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={genre}
                            label="Genre"
                            onChange={handleGenreChange}
                            sx={
                              isMobile
                                ? { width: "300px", borderRadius: "18px" }
                                : { width: "450px", borderRadius: "18px" }
                            }
                          >
                            {genres.map((curElem) => (
                              <MenuItem value={curElem}>{curElem}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      {/* <Box
                        width="100%"
                        display={"flex"}
                        marginTop={isMobile ? "5px" : "20px"}
                      >
                        <FormControl
                          fullWidth
                          sx={{ alignItems: "center", alignContent: "center" }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={
                              isMobileMedium
                                ? { marginLeft: "0px" }
                                : isMobileLarge
                                ? { marginLeft: "20px" }
                                : { marginLeft: "60px" }
                            }
                          >
                            Legend
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={legend}
                            label="Legend"
                            onChange={handleLegendChange}
                            sx={
                              isMobile
                                ? { width: "300px", borderRadius: "18px" }
                                : { width: "450px", borderRadius: "18px" }
                            }
                          >
                            {legends.map((curElem) => (
                              <MenuItem value={curElem}>{curElem}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box> */}
                    </>

                    <div className="wordPopDiv">
                      <div className="gptModal-grid">
                        <Grid container spacing={2}>
                          {words.map((data, index) => (
                            <StyLedGridItem item xs={4}>
                              <>
                                {/* <Button
                              variant="outlined"
                              endIcon={<DeleteIcon />}
                              sx={{
                                borderColor: "black",
                                color: "black",
                                borderRadius: "18px",
                              }}
                            >
                              {data}
                            </Button> */}
                                <button className="rapModal_selected_words">
                                  <div>{data}</div>
                                  <div>
                                    <span
                                      onClick={removeHandler}
                                      data-index={index}
                                    >
                                      x
                                    </span>
                                  </div>
                                </button>
                              </>
                            </StyLedGridItem>
                          ))}
                        </Grid>
                      </div>
                    </div>
                    <form
                      onSubmit={anyWordHandler}
                      className="d-flex justify-content-center"
                    >
                      <input
                        placeholder="Wanna add anything else?"
                        type="text"
                        className="username_input_pop"
                        value={anyWord}
                        style={{ paddingLeft: "20px" }}
                        onChange={(e) => setAnyWord(e.target.value)}
                      />
                      <button className="add-moreWord-button">Add</button>
                    </form>
                    <div className="modal-ending-buttons">
                      {/* <button
                className="clear-button-gptModal"
                onClick={clearClickedArray}
              >
                Clear All
              </button> */}
                      <button
                        className="submit-button-song-pop"
                        onClick={() => rapGenerationHandler()}
                      >
                        Write Song{" "}
                        {userDetails?.data?.is_subscribe === false ? (
                          <>
                            <i className="fa fa-lock"></i>
                          </>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </>
        </Modal>
      ) : (
        <>{open === true ? noWords() : ""}</>
      )}
      <GeneratedRapModal
        open={openGeneratedRapModal}
        setOpen={setOpenGeneratedRapModal}
        wordsArray={words}
      />
    </div>
  );
}
