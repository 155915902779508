import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy_main">
      <h3>Songs.World Privacy Policy</h3>
      Effective Date: December 21, 2023.
      <h5> 1. Introduction</h5>
      <p>
        Songs.World is committed to protecting the privacy and security of our
        users' information. This Privacy Policy outlines how we collect, use,
        and protect the information you provide when using our services.
      </p>
      <h5>2. COPPA Compliance</h5>
      <p>
        Songs.World is committed to protecting the privacy of children. We will
        never knowingly solicit, nor will we accept, personally identifiable
        information from users known to be under 13 years of age in a manner not
        permitted by the Children's Online Privacy Protection Act (COPPA) or
        other applicable laws. If we learn that we have inadvertently collected
        personal information from a child under 13, we will delete that
        information as quickly as possible.
      </p>
      <h5>3. Information Collection</h5>
      <p>
        When you use Songs.World, we may collect information you provide, such
        as your name, email address, and any lyrics or content you create. We
        also collect technical information like IP addresses, browser types, and
        page URLs for session management and usage statistics.
      </p>
      <h5>4. Cookies</h5>{" "}
      <p>
        We use cookies to enhance your experience, understand user activity, and
        improve the quality of our service. Cookies store a session
        identification string; actual user session data is stored temporarily in
        our system.
      </p>
      <h5>5. Use of Information</h5>
      <p>
        The information collected is used to provide and improve our services,
        maintain user sessions, compile traffic statistics, and enhance user
        experience. We may also use your information to communicate with you
        about our services and updates.
      </p>
      <h5>6. Copyrights</h5>{" "}
      <p>
        Users retain 100% of the copyrights of any lyrics or content generated
        on Songs.World.
      </p>
      <h5>7. Information Sharing</h5>
      <p>
        Songs.World will not disclose, sell, or trade your personal information
        to third parties unless required by law or if the information is
        necessary to address unlawful or harmful activities.
      </p>
      <h5>8. Data Security</h5>
      <p>
        We implement various security measures to protect your information from
        unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h5>9. Third-Party Services</h5>
      <p>
        Our service may include links to third-party websites or services. We
        are not responsible for the privacy practices of these entities and
        encourage you to review their privacy policies.
      </p>
      <h5>10. Your Rights and Choices</h5>
      <p>
        You have the right to access, update, or delete your personal
        information. Please contact us if you wish to exercise these rights.
      </p>
      <h5>11. Changes to Privacy Policy</h5>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new policy on this page.
      </p>
      <h5>12. Contact Us</h5>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at songsdotworld@gmail.com. This policy is intended to provide a basic
        framework for Songs.World's privacy practices and may need to be
        adjusted or expanded based on the specific functionalities and
        requirements of the service. It's advisable to have a legal professional
        review and finalize the policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
