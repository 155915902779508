import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const GeneratedRapTyping = ({
  initialText,
  typingDelay = 30,
  onTextChange,
}) => {
  const [typingText, setTypingText] = useState(""); // Text for the typing effect
  const [editableText, setEditableText] = useState(""); // Text for the TextField
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    if (typingIndex < initialText.length) {
      const timer = setTimeout(() => {
        const nextChar = initialText.charAt(typingIndex);
        setTypingText((prev) => prev + nextChar);
        setTypingIndex(typingIndex + 1);

        // Update the editable text only if the user has not started editing
        if (editableText.length < typingIndex + 1) {
          setEditableText((prev) => prev + nextChar);
        }
      }, typingDelay);

      return () => clearTimeout(timer);
    }
  }, [typingIndex, initialText, typingDelay, editableText.length]);

  const handleTextChange = (event) => {
    setEditableText(event.target.value);
    onTextChange(event.target.value);
  };

  return (
    <div>
      <TextField
        autoComplete="off"
        multiline
        onChange={handleTextChange}
        value={editableText}
        variant="outlined"
        fullWidth
        className="editable_song_textfield"
        sx={{
          "& .MuiInputBase-root": { border: "none", boxShadow: "none" },
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiInputBase-inputMultiline": {
            textAlign: "center",
            fontSize: "1.2rem",
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </div>
  );
};

export default GeneratedRapTyping;
