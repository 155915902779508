import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import AuthContext from "../../context/AuthContext";

const ForgotPass = () => {
//   const { forgotPassword } = useContext(AuthContext);

  
  const forgotPassword = async (e) => {
    e.preventDefault()
    let item = {email:e.target.username.value}
    let response = await fetch("https://api.rhymes.world/auth/password_reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let data = await response.json();
    if (response.ok) {
      alert("An email has been sent to your Gmail.");
    } else {
      alert("Please try again later");
    }
  };
  return (
    <div>
      <Form onSubmit={forgotPassword} className="d-flex flex-column">
        <label htmlFor="email">Email Address</label>
        <input
          type="text"
          name="username"
          placeholder="    Enter your email address"
          className="login-input"
          style={{ paddingLeft: "20px" }}
        />

        <button className="login-button" type="submit">
          Change Password
        </button>
      </Form>
    </div>
  );
};

export default ForgotPass;
