import React, { useState, useContext, useEffect } from "react";
import "./Signup.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Form, FloatingLabel } from "react-bootstrap";
import Icon from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import AuthContext from "../../context/AuthContext";
import { BASE_URL } from "../Api/api";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const location = useLocation();

  const { loginTheUser, loginUser } = useContext(AuthContext);

  const handlePassToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  let Signup = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setEmailError("Please enter your email");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    let item = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    let response = await fetch('https://api.rhymes.world/auth/register', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    let result = await response.json();
    localStorage.setItem("user-info", JSON.stringify(result));
    if (response.status === 200) {
      let loginItem = {
        username: email,
        password: password,
      };
      if (location.state !== null) {
        let wordSubmitItem = {
          inputArr: location.state.words,
          Word_of_the_day: location.state.wordOfTheDay,
        };
        loginTheUser({ loginItem: loginItem, wordSubmitItem: wordSubmitItem });
      } else {
        loginTheUser({ loginItem: loginItem });
      }
    } else if (response.status === 500) {
      alert("Email already used!");
    } else {
      alert("Something went wrong!");
    }
  };

  const loginHandller = () => {
    if (location.state !== null) {
      navigate("/login", {
        state: {
          inputArr: location.state.words,
          Word_of_the_day: location.state.wordOfTheDay,
        },
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="signup-main">
        <div className="signup-card">
          <div id="s-text-1">Sign Up</div>
          <Form className="signup-form" onSubmit={Signup}>
            {/* Email ID */}
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="    Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
              className="signup-input"
              value={email}
              style={{ paddingLeft: "20px" }}
            />
            {emailError && <div className="error">{emailError}</div>}

            {/*Create Password */}
            <label htmlFor="" style={{ marginLeft: "-2rem" }}>
              Password
            </label>
            <span>
              <input
                type={type}
                name="password"
                placeholder="    Enter strong password (minimum 6 characters)"
                onChange={(e) => setPassword(e.target.value)}
                className="signup-input"
                value={password}
                style={{ paddingLeft: "20px" }}
              />
              <Icon
                onClick={handlePassToggle}
                icon={icon}
                size={20}
                className="password-icon-signup"
              />
            </span>
            <button className="signup-button" type="submit">
              Let's Rhyme
            </button>
            {/* <div className="s-statement">
              By clicking 'Continue', you agree to the <strong>Terms </strong>
              and <br /> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
              acknowledge the <strong>Privacy Policy</strong>
            </div> */}
            <div id="s-text-5">
              <span>Already have an account?&nbsp;</span>
              <span>
                <button
                  onClick={loginHandller}
                  style={{ border: "none", background: "transparent" }}
                >
                  <strong>Log in</strong>
                </button>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
