import Joyride, { STATUS } from "react-joyride";
import { useTour } from "../../context/TourContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const tourSteps = [
  {
    target: ".rhymingWord-search",
    content: "Search for a rhyme here!",
    disableBeacon: true,
  },
  {
    target: ".home_words_text",
    content: "Or click on any phrase.",
  },
  {
    target: ".words-and-phrases",
    content: "Select some rhymes to generate a song.",
  },
];

const NullComponent = () => null;

const AppWithJoyride = () => {
  const { run, stepIndex, setRun, setStepIndex } = useTour();

  const navigate = useNavigate();

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    } else if (type === "step:after") {
      // Check the action and update the stepIndex accordingly
      if (action === "next") {
        setStepIndex(index + 1);
        console.log("next button clicked", stepIndex);
        // Define the step indices where you want to navigate
        if (index === 1) {
          setRun(false);
          console.log("index is 2");
          // Add your navigation logic here
          // The path should be dependent on the step or some other condition
          let searchWord = "Orange";
          navigate(`/search/${searchWord}`);

          // For React Router v6, use navigate(path);
        } else {
          setStepIndex(index + 1);
        }
      } else if (action === "prev") {
        setStepIndex(index - 1);
      }
    }
  };

  const determinePathBasedOnStep = (stepIndex) => {
    // Logic to determine the path based on the step index
    // Example:
    if (stepIndex === 2) {
      let searchWord = "Orange";
      return `/search/${searchWord}`;
    }
    // Default to current path or any other fallback
    return window.location.pathname;
  };

  useEffect(() => {
    console.log("TourManager--", run);
  }, [run]);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      autoStart
      run={run}
      steps={tourSteps}
      stepIndex={stepIndex}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      beaconComponent={NullComponent} // Disable the beacon by rendering a null component
    />
  );
};

export default AppWithJoyride;
