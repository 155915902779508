import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../Api/api";

const VideoEditing = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadFileAndGetURL = async (file) => {
    const formData = new FormData();
    console.log(file);
    console.log(formData);
    try {
      const response = await axios.post(`${BASE_URL}/edit`, {
        timeline: {
          background: "#000000",
          tracks: [
            {
              clips: [
                {
                  asset: {
                    type: "video",
                    src: file, // Use the URL from the file upload
                    trim: 2,
                    volume: 0.5,
                  },
                  start: 0,
                  length: 4,
                  transition: {
                    in: "fade",
                    out: "fade",
                  },
                  filter: "boost",
                  effect: "slideRight",
                },
              ],
            },
          ],
        },
        output: {
          format: "mp4",
          resolution: "hd",
        },
      });
      return response.data.url; // Assuming the response contains the URL
    } catch (error) {
      console.error("Error uploading file", error);
      return null;
    }
  };

  const sendJsonPayload = async (videoUrl) => {
    console.log(videoUrl);
    const data = {
      timeline: {
        background: "#000000",
        tracks: [
          {
            clips: [
              {
                asset: {
                  type: "video",
                  src: videoUrl, // Use the URL from the file upload
                  trim: 2,
                  volume: 0.5,
                },
                start: 0,
                length: 4,
                transition: {
                  in: "fade",
                  out: "fade",
                },
                filter: "boost",
                effect: "slideRight",
              },
            ],
          },
        ],
      },
      output: {
        format: "mp4",
        resolution: "hd",
      },
    };

    try {
      await axios.post(`${BASE_URL}/edit`, data);
      console.log("JSON data successfully sent");
    } catch (error) {
      console.error("Error sending JSON data", error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    setUploading(true);

    const videoUrl = await uploadFileAndGetURL(selectedFile);
    if (videoUrl) {
      await sendJsonPayload(videoUrl);
    } else {
      alert("File upload failed!");
    }

    setUploading(false);
  };

  return (
    <div>
      <input type="file" accept="video/*" onChange={handleFileChange} />
      {selectedFile && (
        <div>
          <video width="320" height="240" controls>
            <source src={URL.createObjectURL(selectedFile)} type="video/mp4" />
          </video>
        </div>
      )}
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload Video"}
      </button>
    </div>
  );
};

export default VideoEditing;
