import React, { useState, useContext, useEffect } from "react";
import "./EditSong.css";
import { BASE_URL } from "../Api/api";
import AuthContext from "../../context/AuthContext";
// import swal from "sweetalert";
import { useParams, useNavigate } from "react-router-dom";
import RegenrateModal from "../CustomModal/RegenrateModal";
import { toast } from "react-toastify";
import MusicPlayer from "../MusicPlayer/MusicPlayer";

function EditSong() {
  let { authTokens } = useContext(AuthContext);
  const [clickedArray, setClickedArray] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [rap, setRap] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [songLineArray, setSongLineArray] = useState([]);
  const notify = () => toast("Song saved", { autoClose: 2000 });

  useEffect(() => {
    let getSongData = async () => {
      let response = await fetch(`${BASE_URL}/edit-song/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authTokens.access,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        console.log(data.song);
        setTitle(data.title);
        setRap(data.song.split(","));
        setClickedArray(data.words.split(","));
        setSongLineArray(data.song.split("\r\n"));
      }
    };
    getSongData();
  }, []);
  const submitSong = async (e) => {
    const items = { song: rap, title: title };
    console.log(items);
    let response = await fetch(`${BASE_URL}/edit-song/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    let data = await response.json();

    if (response.status === 200) {
      notify();
      navigate("/songs/");
    }
  };

  useEffect(() => {
    console.log(songLineArray);
    console.log(rap);
  }, [songLineArray]);

  const [modalShow, setModalShow] = useState(false);
  const clearClickedArray = () => {
    setClickedArray([]);
  };
  const [isRemoveClicked, setisRemoveClicked] = useState(false);
  const removeHandler = (e) => {
    e.preventDefault();
    setisRemoveClicked(!isRemoveClicked);
    let req_index = e.target.getAttribute("data-index");
    console.log("InputArray = ", clickedArray);
    console.log("req_index = ", req_index);

    let new_arr = clickedArray;
    new_arr.splice(req_index, 1);
    setClickedArray((prev) => (prev = new_arr));
    console.log("After set splice", clickedArray);
  };

  const changesHandler = (e) => {
    setChangesMade(true);
    setRap(e.target.value);
  };

  const titleChangesHandler = (e) => {
    console.log("changes in title has been made")
    setChangesMade(true);
    setTitle(e.target.value);
  };
  return (
    <>
      <div className="editSong-main">
        <div className="editSong-card">
          <div className="editSong-form">
            <input
              type="text"
              label="Title"
              className="editSong-titleBox"
              autoCapitalize="sentences"
              onChange={(e) => titleChangesHandler(e)}
              placeholder="title"
              name="title"
              value={title}
              style={{ padding: "10px" }}
            />
            <textarea
              name="song"
              className="signup-input signup-textarea"
              value={rap}
              onChange={(e) => changesHandler(e)}
              required
            ></textarea>
            <MusicPlayer />
            <div className="btn_row">
              <button
                className="update-song-button"
                onClick={() => {
                  changesMade ? submitSong() : navigate("/songs/");
                }}
              >
                {changesMade ? "Save" : "Back"}
              </button>
              <button
                className="regenerateSong-button"
                onClick={() => setModalShow(true)}
              >
                Re-Write
              </button>
            </div>
          </div>
        </div>
      </div>
      <RegenrateModal
        show={modalShow}
        setShow={setModalShow}
        words={clickedArray}
        clearClickedArray={clearClickedArray}
        removeHandler={removeHandler}
        setClickedArray={setClickedArray}
        songArray={songLineArray}
        setSongArray={setSongLineArray}
        id={id}
        title={title}
        setRap={setRap}
      />
    </>
  );
}

export default EditSong;
