import React, { useContext, useEffect, useRef, useState } from "react";
import "./TalkingCharacter.css";
import { useLocation, useNavigate } from "react-router-dom";
import SnoopDogg from "../../assets/images/snoopDogg.png";
import Shakespeare from "../../assets/images/Shakespeare.png";
import HarryPotter from "../../assets/images/harryPotter.png";
import Cleopatra from "../../assets/images/Cleopatra.png";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import recordIcon from "../../assets/icons/recorder-icon.svg";
import recordIcon2 from "../../assets/icons/recorder-icon-2.svg";
import { Button, IconButton } from "@mui/material";
import MusicPlayer from "../MusicPlayer/MusicPlayer";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
import { BASE_URL, ELEVENLABS_APIKEY } from "../Api/api";
import ThinkingDots from "./ThinkingDots";
import { ToastContainer, toast } from "react-toastify";
import Icon from "react-icons-kit";
import AuthContext from "../../context/AuthContext";

const TalkingCharacter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [audio, setAudio] = useState();
  const [responseMessage, setResponseMessage] = useState([]);
  const [characterThinking, setCharacterThinking] = useState(false);
  const audioRefs = useRef({});
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const character = location.state.character;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const { saveChatsGet, savedChats, user,REACT_APP_API_BASE_URL_SPANISH,languageSelected } = useContext(AuthContext);

  const toggleListen = async () => {
    const noWords = () => toast("Speak something for the character to respond");
    if (listening) {
      SpeechRecognition.stopListening();
      setCharacterThinking(true);
      console.log("Stopped Listening:", transcript); // log the transcript when stopping
      if (transcript === "") {
        noWords();
        setCharacterThinking(false);
      } else {
        const characterResponse = await fetchGPTResponse(character, transcript);
        const audioURL = await convertTextToSpeech(characterResponse);
        setAudio((prevAudio) => audioURL);
        setCharacterThinking(false);
        resetTranscript();
        console.log("After reset:", transcript); // log the transcript after reset
      }
    } else {
      console.log("Starting to listen"); // log when starting to listen
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  const fetchGPTResponse = async (character, message) => {
    console.log(message);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({ character, message }),
    };

    const response = await fetch(
      `${ languageSelected === "English" ? BASE_URL : REACT_APP_API_BASE_URL_SPANISH}/chatbot-response`,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      setResponseMessage(data.final_lines);
      console.log(data.final_lines);
      return data.final_lines;
    } else {
      return [];
    }
  };

  const convertTextToSpeech = async (message, index) => {
    console.log("TEXT TO SPEECH CALLED");
    let text = String(message);
    let VOICE_ID = "";
    if (character === "Shakespeare") {
      VOICE_ID = "zCOmI6LywbhYYfytSCIi";
    } else if (character === "Snoop Dogg") {
      VOICE_ID = "w3s4QaCyhb7p48OoD399";
    } else if (character === "Harry Potter") {
      VOICE_ID = "ErXwobaYiN019PkySvjV";
    } else {
      VOICE_ID = "XB0fDUnXU5powFXDhCwa";
    }
    const API_URL = `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`;

    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": ELEVENLABS_APIKEY,
    };

    const data = {
      text: text,
      model_id: "eleven_monolingual_v1",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5,
      },
    };

    try {
      const response = await axios.post(API_URL, data, {
        headers: headers,
        responseType: "blob",
      });

      // Convert the blob to an object URL (temporary URL that the browser can access)
      const objectURL = URL.createObjectURL(response.data);
      console.log(objectURL);

      if (audioRefs.current) {
        audioRefs.current.src = objectURL;
        // audioRefs.current.load(); // force the audio element to reload the new source
      }

      return objectURL;
    } catch (error) {
      if (error.response) {
        const reader = new FileReader();
        reader.onload = function () {
          console.error("Server responded with:", reader.result);
        };
        reader.readAsText(error.response.data);
      }
      console.error("Error converting text to speech:", error);
    }
  };

  const handlePlayPause = () => {
    if (audioRefs.current.paused) {
      audioRefs.current.play().catch((error) => {
        console.error("Playback failed:", error);
      });
      setAudioPlaying(true);
    } else {
      audioRefs.current.pause();
      setAudioPlaying(false);
    }
  };

  useEffect(() => {
    if (audio) {
      handlePlayPause();
    }
  }, [audio]);

  useEffect(() => {
    saveChatsGet({ user: user.username, character: character });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);
    console.log(savedChats);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAudioEnded = () => {
    setAudioPlaying(false);
  };

  return (
    <div className="tc-main">
      <div className="tc-body">
        <div>
          <button className="tc-swap-button" onClick={() => navigate("/chat")}>
            <SwapHorizontalCircleIcon
              sx={
                isMobile
                  ? {
                      color: "rgba(71, 212, 255, 1)",
                      height: "1.5rem",
                      width: "1.5rem",
                    }
                  : {
                      color: "rgba(71, 212, 255, 1)",
                      height: "2rem",
                      width: "2rem",
                    }
              }
            />
            Swap to Chat
          </button>
        </div>

        <img
          className="tc-character-img"
          src={
            location?.state?.character === "Snoop Dogg"
              ? SnoopDogg
              : location?.state?.character === "Harry Potter"
              ? HarryPotter
              : location?.state?.character === "Shakespeare"
              ? Shakespeare
              : Cleopatra
          }
          alt="The Character"
        />
        {listening === false && audio ? (
          <button
            onClick={() => handlePlayPause()}
            aria-label="Toggle audio playback"
            className="tc-audio-mic"
          >
            {audioRefs.current && audioPlaying === false ? (
              <>
                {console.log(audioPlaying)}
                <VolumeOffIcon />
              </>
            ) : (
              <VolumeUpIcon />
            )}
          </button>
        ) : (
          ""
        )}

        <p>{location.state.character}</p>
        {listening === false && characterThinking ? <ThinkingDots /> : ""}
        <button onClick={toggleListen} className="tc-recorder-button">
          {listening ? (
            <img
              src={recordIcon}
              className="recorder-listening"
              alt="recording"
              style={{ color: "red" }}
            />
          ) : (
            <img
              src={recordIcon2}
              className="recorder-not-listening"
              alt="recording"
              style={{ color: "red" }}
            />
          )}
        </button>
        {/* {transcript} */}
        {audio && (
          <>
            <audio
              ref={(el) => (audioRefs.current = el)}
              onEnded={handleAudioEnded}
              onLoadedMetadata={() => {
                console.log("Audio metadata loaded");
              }}
            >
              <source src={audio} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </>
        )}
        {listening ? <>Press When Finished</> : <>Press to Talk</>}
      </div>
      <MusicPlayer />
      <ToastContainer />
    </div>
  );
};

export default TalkingCharacter;
