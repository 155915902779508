import { Box, Modal, TextField } from "@mui/material";
import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { BASE_URL } from "../Api/api";
import swal from "sweetalert";
import GeneratedRapTyping from "./GeneratedRapTyping";

// import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const isMobile = window.innerWidth <= 425; //425 is the mobile max-width

const style = {
  position: "absolute",
  top: "51%",
  left: "50%",
  height: "765px",
  width: "760px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "28px",
  boxShadow: 24,
  p: 4,
  overflowY: "hidden",
  ...(isMobile && {
    // Mobile-specific styles go here:
    top: "0%",
    left: "0%",
    transform: "translate(0, 0)",
    borderRadius: "0px",
    height: "100vh",
    width: "100vw",
    overflowY: "auto",
  }),
};

const GeneratedRapModal = ({ open, setOpen, wordsArray }) => {
  const [isLines, setIsLines] = useState(false);
  const [song_lines, setSongLines] = useState([]);
  const [rapLines, setRapLines] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [startTyping, setStartTyping] = useState(true);
  const [editableRap, setEditableRap] = useState([]);
  const canvasRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("lightblue");
  const [textColor, setTextColor] = useState("black");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const songSaved = () =>
    toast("Song saved Successfully!", { autoClose: 1000 });
  const [showIcons, setShowIcons] = useState(false);

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  const {
    userDetails,
    rapGenerated,
    user,
    authTokens,
    setupTheUser,
    rapGeneration,
    setIsRapSuccessFullyGenerated,
    isRapSuccessFullyGenerated,
    credits,
  } = useContext(AuthContext);

  useEffect(() => {
    if (
      user &&
      (userDetails.data.is_superuser === false ||
        userDetails.data.is_subscribe === false)
    ) {
      if (credits > 0) {
        let genre = "Rap";
        let legend = "None";
        let anyWord = "";
        rapGeneration(wordsArray, genre, anyWord, legend);
      }
      console.log("Generating the song!", wordsArray);
    }
  }, []);

  const shareToWhatsApp = () => {
    console.log(editableRap);
    const url = `https://wa.me/?text=${encodeURIComponent(editableRap)}`;
    window.open(url, "_blank");
  };

  const shareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      editableRap
    )}`;
    window.open(url, "_blank");
  };

  const shareAsDirectMessageTwitter = () => {
    const url = `https://twitter.com/messages/compose?text=${encodeURIComponent(
      editableRap
    )}`;
    window.open(url, "_blank");
  };
  useEffect(() => {
    const loadSnapchatSDK = () => {
      const script = document.createElement("script");
      script.src = "https://sdk.snapkit.com/js/v1/create.js";
      script.async = true;
      script.id = "snapkit-creative-kit-sdk";
      document.body.appendChild(script);
    };

    if (!document.getElementById("snapkit-creative-kit-sdk")) {
      loadSnapchatSDK();
    }
  }, []);

  const shareToSnapchat = () => {
    console.log("sharing to snapchat");
    convertTextToImage(editableRap);
  };

  useEffect(() => {
    if (isRapSuccessFullyGenerated) {
      setIsRapSuccessFullyGenerated(false);
    }
  }, [isRapSuccessFullyGenerated]);

  useEffect(() => {
    if (open) {
      setEditableRap(rapGenerated);
      setIsEditable(false);
    }
  }, [open, rapGenerated]);

  const saveAccess = () => {
    if (userDetails?.data?.is_superuser === true) {
      return true;
    }
    let isAccess = false;
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.save_song_uses > 0
    ) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data.is_email_verified === false) {
      return false;
    }
    return isAccess;
  };

  const writeAccess = () => {
    if (userDetails?.data?.is_superuser === true) {
      return true;
    }
    let isAccess = false;
    if (userDetails?.data?.write_song_uses > 0) {
      isAccess = true;
    }
    if (
      userDetails?.data?.is_subscribe === true &&
      userDetails?.data?.is_unlimited === true
    ) {
      isAccess = true;
    }
    if (userDetails?.data.is_email_verified === false) {
      return false;
    }
    return isAccess;
  };

  const handleTextChange = (e) => {
    setEditableRap(e.target.value);
  };

  const regenerateSongLineHandler = (e) => {
    let value = Number(e.target.getAttribute("data-index")) + 1;
    if (song_lines.includes(value)) {
      let new_arr = song_lines.filter((val) => val !== value);
      if (new_arr.length > 0) {
        setIsLines(true);
      } else {
        setIsLines(false);
      }
      setSongLines(new_arr);
    } else {
      song_lines.push(value);
      setSongLines((prev) => (prev = song_lines));
      setIsLines(true);
    }
  };

  const showText = () => {
    setIsEditable(true);
  };

  let rapSaved = async (username, type) => {
    if (saveAccess() === false) {
      swal("Please use premium version to save your favourite song");
      return;
    }
    let rap_data =
      typeof editableRap === "string"
        ? editableRap.split("\n").join("\r\n")
        : editableRap.join("\r\n");
    let word_carray = wordsArray;
    let items = {
      user: username,
      song: rap_data,
      words: word_carray.join(","),
    };
    console.log(items);
    let response = await fetch(`${BASE_URL}/rap`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify(items),
    });
    let data = await response.json();
    console.log(data);
    if (response.status === 201) {
      if (type === "SAVE_ONLY") {
        setIsSaveClicked(true);
        songSaved();
        navigate("/songs");
      } else {
        songSaved();
        navigate("/songs");
      }
    }
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const handleEditableTextChange = (newText) => {
    setEditableRap(newText);
  };

  const convertTextToImage = (inputText) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions
      canvas.width = 500;
      canvas.height = 300;

      // Set background color
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Set text properties
      ctx.font = "20px Arial";
      ctx.fillStyle = textColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Draw text
      ctx.fillText(inputText, canvas.width / 2, canvas.height / 2);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style} fullScreen={isMobile}>
          <div>
            <div className="generatedSong-header">
              <button onClick={handleClose}>x</button>
              <h4 className="modal_heading">Here's Your Song 🎵</h4>
            </div>
            <div>
              <div id="song_text" className="display_block">
                <div className="editable_song">
                  {/* <TextField
                      autoComplete="off"
                      multiline
                      onChange={handleTextChange}
                      value={
                        Array.isArray(editableRap)
                          ? editableRap.join("\r\n\r\n") // Double newline for space after each line
                          : editableRap
                      }
                      variant="outlined"
                      fullWidth
                      className="editable_song_textfield"
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none", // Removes the border
                          boxShadow: "none", // Optional: Removes any shadow effect if present
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Specifically removes the border for outlined variant
                        },
                        "& .MuiInputBase-inputMultiline": {
                          textAlign: "center",
                          fontSize: "1.2rem",
                          border: "none", // Specifically targets the multiline input
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes border on hover for outlined variant
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes border when the input is focused
                        },
                      }}
                    /> */}
                  <GeneratedRapTyping
                    initialText={
                      Array.isArray(editableRap)
                        ? editableRap.join("\r\n\r\n")
                        : editableRap || ""
                    }
                    typingDelay={30}
                    onTextChange={handleEditableTextChange} // Pass the handler here
                  />
                </div>
              </div>
            </div>
            <div className="song_geneated_saveButton">
              <div class="song_generated_btn_wrap">
                <span>Share</span>
                <div class="song_generated_container">
                  <i class="fab fa-tiktok"></i>
                  <i class="fab fa-snapchat" onClick={shareToSnapchat}></i>
                  <div>
                    {/* Meta tags */}
                    <meta property="og:site_name" content="Snapchat" />
                    <meta
                      property="og:title"
                      content="The Fastest Way to Share a Moment!"
                    />
                    <meta
                      property="snapchat:sticker"
                      content="https://kit.snapchat.com/ckweb/test/image.png"
                    />
                    <meta
                      property="snapchat:app_id"
                      content="YOUR_APP_ID_HERE"
                    />
                    <meta
                      property="snapchat:publisher_id"
                      content="YOUR_PUBLISHER_ID_HERE"
                    />

                    {/* Snapchat Share Button */}
                    <div
                      className="snapchat-creative-kit-share"
                      data-theme="dark"
                      data-size="large"
                      data-text="false"
                      data-share-url="https://kit.snapchat.com/"
                    ></div>
                  </div>
                  {/* <canvas ref={canvasRef} /> */}
                  <i class="fab fa-instagram"></i>
                  <i
                    class="fab fa-twitter"
                    onClick={shareAsDirectMessageTwitter}
                  ></i>
                  <i class="fab fa-whatsapp" onClick={shareToWhatsApp}></i>
                </div>
              </div>
              {/* <button onClick={shareToWhatsApp}>Share to WhatsApp</button>
              <button onClick={shareToTwitter}>Share to Twitter</button>
              <button onClick={shareAsDirectMessageTwitter}>
                Share to Twitter Messages
              </button> */}
              <button
                className="submit-button-song-pop btn_mr"
                onClick={() => rapSaved(user.username, "SAVE_ONLY")}
              >
                Save{" "}
                {userDetails?.data?.is_subscribe === false ||
                userDetails?.data?.is_superuser === false ? (
                  <div>
                    <i className="fa fa-lock"></i>
                  </div>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GeneratedRapModal;
