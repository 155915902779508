import React, { useEffect, useState } from "react";
import Chatbox from "./Chatbox";
import "./ChatBot.css";
import { BASE_URL, ELEVENLABS_APIKEY } from "../Api/api";
import SnoopDogg from "../../assets/images/snoopDogg.png";
import Shakespeare from "../../assets/images/Shakespeare.png";
import HarryPotter from "../../assets/images/harryPotter.png";
import Cleopatra from "../../assets/images/Cleopatra.png";
import axios from "axios";
import { useRef } from "react";
import { Box, Button, Grid } from "@mui/material";
import MusicPlayer from "../MusicPlayer/MusicPlayer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ThinkingDots from "./ThinkingDots";
import Typewriter from "./Typewriter";

function ChatBot() {
  const [character, setCharacter] = useState("Snoop Dogg");
  const [messagesSnoop, setMessagesSnoop] = useState([]);
  const [messagesCleopatra, setMessagesCleopatra] = useState([]);
  const [messagesHarry, setMessagesHarry] = useState([]);
  const [messagesShakespeare, setMessagesShakespeare] = useState([]);
  const [responseMessage, setResponseMessage] = useState([]);
  const rapMessagesRef = useRef(null);
  const [playingAudioIndex, setPlayingAudioIndex] = useState(null);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const audioRefs = useRef({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const {
    saveChats,
    user,
    saveChatsGet,
    savedChats,
    REACT_APP_API_BASE_URL_SPANISH,
    languageSelected,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [startTypeWriting, setStartTypeWriting] = useState(false);
  const [HGreeting, setHGreeting] = useState(false);
  const [SnoopGreeting, setSnoopGreeting] = useState(false);
  const [ShakerpeareGreeting, setShakerpeareGreeting] = useState(false);
  const [CleopatraGreeting, setCleopatraGreeting] = useState(false);

  const d = new Date();
  var day = d.toLocaleString("en-CA", { day: "2-digit" });
  var month = d.toLocaleString("en-CA", { month: "2-digit" });
  var year = d.toLocaleString("en-CA", { year: "numeric" });

  const today_date = year + "-" + month + "-" + day;

  const convertTextToSpeech = async (message, index) => {
    let text = String(message);
    let VOICE_ID = "";
    if (character === "Shakespeare") {
      VOICE_ID = "zCOmI6LywbhYYfytSCIi";
    } else if (character === "Snoop Dogg") {
      VOICE_ID = "w3s4QaCyhb7p48OoD399";
    } else if (character === "Harry Potter") {
      VOICE_ID = "ErXwobaYiN019PkySvjV";
    } else {
      VOICE_ID = "XB0fDUnXU5powFXDhCwa";
    }
    const API_URL = `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`;

    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": ELEVENLABS_APIKEY,
    };

    const data = {
      text: text,
      model_id: "eleven_monolingual_v1",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5,
      },
    };

    try {
      const response = await axios.post(API_URL, data, {
        headers: headers,
        responseType: "blob",
      });

      // Convert the blob to an object URL (temporary URL that the browser can access)
      const objectURL = URL.createObjectURL(response.data);
      return objectURL;
    } catch (error) {
      if (error.response) {
        const reader = new FileReader();
        reader.onload = function () {
          console.error("Server responded with:", reader.result);
        };
        reader.readAsText(error.response.data);
      }
      console.error("Error converting text to speech:", error);
    }
  };

  const handleSendMessage = async (message) => {
    let messageList = [];
    setIsChatLoading(true);
    if (message.split("\n")) {
      messageList = message.split("\n");
      if (character === "Snoop Dogg") {
        setMessagesSnoop((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else if (character === "Shakespeare") {
        setMessagesShakespeare((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else if (character === "Cleopatra") {
        setMessagesCleopatra((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else {
        setMessagesHarry((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      }
    } else {
      if (character === "Snoop Dogg") {
        setMessagesSnoop((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else if (character === "Shakespeare") {
        setMessagesShakespeare((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else if (character === "Cleopatra") {
        setMessagesCleopatra((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      } else {
        setMessagesHarry((prev) => [
          ...prev,
          { character: "You", message: messageList, audio: null },
        ]);
      }
    }

    const characterResponse = await fetchGPTResponse(character, message);
    // const characterAudio = await convertTextToSpeech(characterResponse);
    const characterAudio = null;
    if (character === "Snoop Dogg") {
      setMessagesSnoop((prev) => [
        ...prev,
        {
          character: character,
          message: characterResponse,
          audio: characterAudio,
        },
      ]);
    } else if (character === "Shakespeare") {
      setMessagesShakespeare((prev) => [
        ...prev,
        {
          character: character,
          message: characterResponse,
          audio: characterAudio,
        },
      ]);
    } else if (character === "Cleopatra") {
      setMessagesCleopatra((prev) => [
        ...prev,
        {
          character: character,
          message: characterResponse,
          audio: characterAudio,
        },
      ]);
    } else {
      setMessagesHarry((prev) => [
        ...prev,
        {
          character: character,
          message: characterResponse,
          audio: characterAudio,
        },
      ]);
    }

    let messagesArray = `${JSON.stringify([
      {
        message: message,
        response: characterResponse[0],
        date: new Date().toISOString(),
      },
    ])}`;
    setIsChatLoading(false);
    saveChats({
      user: user.username,
      character: character,
      messages: messagesArray,
    });
  };

  useEffect(() => {}, [
    messagesCleopatra,
    messagesHarry,
    messagesSnoop,
    messagesShakespeare,
  ]);

  const fetchGPTResponse = async (character, message) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({ character, message }),
    };

    const response = await fetch(
      `${
        languageSelected === "English"
          ? BASE_URL
          : REACT_APP_API_BASE_URL_SPANISH
      }/chatbot-response`,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      setResponseMessage(data.final_lines);
      setStartTypeWriting(true);
      return data.final_lines;
    } else {
      return [];
    }
  };

  const handlePlayPause = (index) => {
    // Check if there's any audio currently playing and pause it
    if (playingAudioIndex !== null && audioRefs.current[playingAudioIndex]) {
      audioRefs.current[playingAudioIndex].pause();
    }

    // If the clicked audio is not the one currently playing, play it
    if (playingAudioIndex !== index && audioRefs.current[index].paused) {
      audioRefs.current[index].play();
      setPlayingAudioIndex(index);
    } else {
      setPlayingAudioIndex(null);
    }
  };

  const handleAudioEnd = (index) => {
    if (playingAudioIndex === index) setPlayingAudioIndex(null);
  };

  useEffect(() => {
    const rapMessages = rapMessagesRef.current;
    rapMessages.scrollTop = rapMessages.scrollHeight;
  }, [messagesCleopatra, messagesHarry, messagesShakespeare, messagesSnoop]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let characterText = "";
    if (character === "Snoop Dogg") {
      characterText =
        "Yo, what's crackalackin', my homie? This here's the one and only Snoop D-O-Double Gizzle, straight outta Long Beach, California. Livin' that chill life, smokin' on that sticky icky, and droppin' phat beats. How you doin', my friend?";
    } else if (character === "Harry Potter") {
      characterText =
        "Greetings, kind friend. 'Tis I, Harry Potter. How might I be of assistance to thee on this fine day?";
    } else if (character === "Shakespeare") {
      characterText =
        "Hark! Good morrow to thee, fair interlocutor.What tidings bring thee hither to my humble abode? Speak, I pray thee, and reveal the purpose of thy visit,That I may offer counsel or repartee, shouldst thou so desire.";
    } else {
      characterText = "Hi, this is Cleopatra.";
    }

    const WelcomeTextSnoop = async () => {
      // const characterAudio = await convertTextToSpeech(characterText);
      const characterAudio = null;
      if (character === "Snoop Dogg") {
        setMessagesSnoop((prev) => [
          ...prev,
          {
            character: character,
            message: characterText,
            audio: characterAudio,
          },
        ]);
      } else if (character === "Shakespeare") {
        setMessagesShakespeare((prev) => [
          ...prev,
          {
            character: character,
            message: characterText,
            audio: characterAudio,
          },
        ]);
      } else if (character === "Cleopatra") {
        setMessagesCleopatra((prev) => [
          ...prev,
          {
            character: character,
            message: characterText,
            audio: characterAudio,
          },
        ]);
      } else {
        setMessagesHarry((prev) => [
          ...prev,
          {
            character: character,
            message: characterText,
            audio: characterAudio,
          },
        ]);
      }
    };
    if (character === "Harry Potter" && HGreeting !== true) {
      WelcomeTextSnoop();
      setHGreeting(true);
    } else if (character === "Snoop Dogg" && SnoopGreeting !== true) {
      WelcomeTextSnoop();
      setSnoopGreeting(true);
    } else if (character === "Shakespeare" && ShakerpeareGreeting !== true) {
      WelcomeTextSnoop();
      setShakerpeareGreeting(true);
    } else if (character === "Cleopatra" && CleopatraGreeting !== true) {
      WelcomeTextSnoop();
      setCleopatraGreeting(true);
    }

    setStartTypeWriting(false);
  }, [character]);

  useEffect(() => {
    saveChatsGet({ user: user.username, character: character });
  }, []);

  return (
    <div className="chatbot-main">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <ul>
            <li className="harry" onClick={() => setCharacter("Harry Potter")}>
              {isMobile ? "" : <>Harry Potter</>}
            </li>
            <li className="snoop" onClick={() => setCharacter("Snoop Dogg")}>
              {isMobile ? "" : <> Snoop Dogg</>}
            </li>
            <li
              className="shakespeare"
              onClick={() => setCharacter("Shakespeare")}
            >
              {isMobile ? "" : <> Shakespeare</>}
            </li>
            <li className="cleopatra" onClick={() => setCharacter("Cleopatra")}>
              {isMobile ? "" : <> Cleopatra</>}
            </li>
          </ul>
          {/* {savedChats?.chat_data?.map((elements, index) => (
            <>
              <ul>
                <li>History {index + 1}</li>
              </ul>
            </>
          ))} */}
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            minHeight: "calc(100vh - 76px)",
            height: "100%",
            borderLeft: "0.1px solid gray",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box flexGrow={1}>
            <h1 className="chatbot-heading">
              {character === "Snoop Dogg" ? (
                <>
                  <img
                    src={SnoopDogg}
                    alt="Snoop Dogg"
                    className="character-chatting"
                  />
                  <h1>Snoop Dogg </h1>
                </>
              ) : character === "Harry Potter" ? (
                <>
                  <img
                    src={HarryPotter}
                    alt="Harry Potter"
                    className="character-chatting"
                  />
                  <h1>Harry Potter </h1>
                </>
              ) : character === "Cleopatra" ? (
                <>
                  <img
                    src={Cleopatra}
                    alt="Cleopatra"
                    className="character-chatting"
                  />
                  <h1>Cleopatra </h1>
                </>
              ) : (
                <>
                  <img
                    src={Shakespeare}
                    alt="Shakespeare"
                    className="character-chatting"
                  />
                  <h1>Shakespeare </h1>
                </>
              )}
            </h1>

            <div>
              <div className="rap-messages" ref={rapMessagesRef}>
                {character === "Snoop Dogg" ? (
                  <>
                    {messagesSnoop.map(
                      ({ character, message, audio }, index) => (
                        <>
                          <div className="row">
                            {character !== "You" && (
                              <div className="character-chat">
                                <img
                                  src={SnoopDogg}
                                  alt="Snoop Dogg"
                                  className="character-icon"
                                />
                                <div className="character-message">
                                  <div className="chat">
                                    {message instanceof Object ? (
                                      <>
                                        {message.map((value, index) => (
                                          <div className="row" key={index}>
                                            <Typewriter
                                              text={value}
                                              startTyping={
                                                String(value) ===
                                                  String(
                                                    messagesSnoop[
                                                      messagesSnoop.length - 1
                                                    ]?.message
                                                  ) && startTypeWriting
                                              }
                                            />
                                          </div>
                                        ))}
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) =>
                                                (audioRefs.current[index] = el)
                                              }
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <Typewriter
                                            text={message}
                                            startTyping={
                                              message !==
                                                String(
                                                  messagesSnoop[0].message
                                                ) && startTypeWriting
                                            }
                                          />
                                        </div>
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) => {
                                                audioRefs.current[index] = el;
                                                if (el) {
                                                  el.volume = 0.8;
                                                }
                                              }}
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {/* <div className="audio"></div> */}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {character === "You" && (
                              <div className="user-chat">
                                {/* <div>{character}</div> */}
                                <div className="user-message">
                                  {message instanceof Object ? (
                                    message.map((value, index) => (
                                      <div className="row">{value}</div>
                                    ))
                                  ) : (
                                    <>{message}</>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    )}
                  </>
                ) : character === "Cleopatra" ? (
                  <>
                    {messagesCleopatra.map(
                      ({ character, message, audio }, index) => (
                        <>
                          <div className="row">
                            {character !== "You" && (
                              <div className="character-chat">
                                <img
                                  src={Cleopatra}
                                  alt="cleopatra"
                                  className="character-icon"
                                />

                                <div className="character-message">
                                  <div className="chat">
                                    {message instanceof Object ? (
                                      <>
                                        {message.map((value, index) => (
                                          <div className="row">
                                            <Typewriter
                                              text={value}
                                              startTyping={startTypeWriting}
                                            />
                                          </div>
                                        ))}
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) =>
                                                (audioRefs.current[index] = el)
                                              }
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <Typewriter
                                            text={message}
                                            startTyping={startTypeWriting}
                                          />
                                        </div>
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) => {
                                                audioRefs.current[index] = el;
                                                if (el) {
                                                  el.volume = 0.8;
                                                }
                                              }}
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {/* <div className="audio"></div> */}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {character === "You" && (
                              <div className="user-chat">
                                {/* <div>{character}</div> */}
                                <div className="user-message">
                                  {message instanceof Object ? (
                                    message.map((value, index) => (
                                      <div className="row">{value}</div>
                                    ))
                                  ) : (
                                    <>{message}</>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    )}
                  </>
                ) : character === "Shakespeare" ? (
                  <>
                    {messagesShakespeare.map(
                      ({ character, message, audio }, index) => (
                        <>
                          <div className="row">
                            {character !== "You" && (
                              <div className="character-chat">
                                <img
                                  src={Shakespeare}
                                  alt="Shakespeare"
                                  className="character-icon"
                                />

                                <div className="character-message">
                                  <div className="chat">
                                    {message instanceof Object ? (
                                      <>
                                        {message.map((value, index) => (
                                          <div className="row">
                                            <Typewriter
                                              text={value}
                                              startTyping={startTypeWriting}
                                            />
                                          </div>
                                        ))}
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) =>
                                                (audioRefs.current[index] = el)
                                              }
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <Typewriter
                                            text={message}
                                            startTyping={startTypeWriting}
                                          />
                                        </div>
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) => {
                                                audioRefs.current[index] = el;
                                                if (el) {
                                                  el.volume = 0.8;
                                                }
                                              }}
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {/* <div className="audio"></div> */}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {character === "You" && (
                              <div className="user-chat">
                                {/* <div>{character}</div> */}
                                <div className="user-message">
                                  {message instanceof Object ? (
                                    message.map((value, index) => (
                                      <div className="row">{value}</div>
                                    ))
                                  ) : (
                                    <>{message}</>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {messagesHarry.map(
                      ({ character, message, audio }, index) => (
                        <>
                          <div className="row">
                            {character !== "You" && (
                              <div className="character-chat">
                                <img
                                  src={HarryPotter}
                                  alt="Harry Potter"
                                  className="character-icon"
                                />

                                <div className="character-message">
                                  <div className="chat">
                                    {message instanceof Object ? (
                                      <>
                                        {message.map((value, index) => (
                                          <div className="row">
                                            <Typewriter
                                              text={value}
                                              startTyping={startTypeWriting}
                                            />
                                          </div>
                                        ))}
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) =>
                                                (audioRefs.current[index] = el)
                                              }
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <Typewriter
                                            text={message}
                                            startTyping={startTypeWriting}
                                          />
                                        </div>
                                        {audio && (
                                          <>
                                            <audio
                                              ref={(el) => {
                                                audioRefs.current[index] = el;
                                                if (el) {
                                                  el.volume = 0.8;
                                                }
                                              }}
                                              onEnded={() =>
                                                handleAudioEnd(index)
                                              }
                                            >
                                              <source
                                                src={audio}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio>

                                            <button
                                              onClick={() =>
                                                handlePlayPause(index)
                                              }
                                              aria-label="Toggle audio playback"
                                            >
                                              {playingAudioIndex === index ? (
                                                <VolumeUpIcon />
                                              ) : (
                                                <VolumeOffIcon />
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {/* <div className="audio"></div> */}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {character === "You" && (
                              <div className="user-chat">
                                {/* <div>{character}</div> */}
                                <div className="user-message">
                                  {message instanceof Object ? (
                                    message.map((value, index) => (
                                      <div className="row">{value}</div>
                                    ))
                                  ) : (
                                    <>{message}</>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </Box>
          <div className="chatBox-loading-dots">
            {isChatLoading ? <ThinkingDots /> : ""}
          </div>
          <div className="chatBox">
            <Chatbox onSendMessage={handleSendMessage} character={character} />
            <button
              className="chatBot-swap-button"
              onClick={() =>
                navigate("/talking-character", {
                  state: { character: character },
                })
              }
            >
              <SwapHorizontalCircleIcon
                sx={{
                  color: "rgba(71, 212, 255, 1)",
                  height: "2rem",
                  width: "2rem",
                }}
              />
              Swap to Talk
            </button>
            {/* <MusicPlayer /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ChatBot;
