import React, { useEffect, useState, useRef } from "react";
import "./Typewriter.css";

function Typewriter({ text, startTyping }) {
  const [typedText, setTypedText] = useState("");
  const indexRef = useRef(0);
  const timeoutRef = useRef();

  const typeNextChar = () => {
    setTypedText((currentText) => {
      if (indexRef.current < text.length) {
        const nextChar = text.charAt(indexRef.current);
        indexRef.current += 1;
        timeoutRef.current = setTimeout(typeNextChar, 50);
        return currentText + nextChar;
      }
      // Optionally reset indexRef.current here if you want to re-type the same text later
      // indexRef.current = 0;
      return currentText;
    });
  };

  useEffect(() => {
    // Only start typing if startTyping is true
    if (startTyping) {
      console.log(text);
      indexRef.current = 0; // Reset index if you want to retype the text from the start
      setTypedText(""); // Clear the current text if you want to start fresh
      timeoutRef.current = setTimeout(typeNextChar, 50);
    }

    // Clear the timeout if the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [startTyping]); // Dependency array now includes startTyping

  // Render the component with typedText
  return (
    <div className="typewriter">
      {startTyping ? (
        <>
          {typedText.split("").map((char, index) => (
            <span key={index} style={{ width: "auto" }}>
              {char}
            </span>
          ))}
        </>
      ) : (
        text
      )}
    </div>
  );
}

export default Typewriter;
